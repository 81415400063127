import React, { useState, useEffect } from 'react';
import {Row, Col, CardBody, ListGroup, ListGroupItem, Button, Collapse, Badge, Input, InputGroup, InputGroupAddon, InputGroupText, Modal} from "reactstrap";
import axios from "axios";
import AddBoard from 'views/board/section/write'
import Filesaver from 'file-saver';
import styled from "styled-components";
import Select from 'react-select';
import "assets/css/quillEditer.css";
import DOMPurify from 'dompurify';
const Content = styled.div`
& .contents-img strong  {
    font-weight: bold;
}
`
function ListItem(props) {
    const [value, setValue] = useState(props.value);
    const [open ,setOpen] = useState(false);
    const [user,setUser]= useState(props.user);
    const [boardAttachFile, setBoardAttachFile] = useState([]);

    useEffect(() => {
        setValue(props.value);
    },[props.value])
    useEffect(() => {
        setUser(props.user)
    },[props.user]);
    useEffect(() => {
        if(value.board_id===props.num) {
            setOpen(true)
        }else {
            setOpen(false);
        }
    },[props.num])
    useEffect(() => {
        if(open) {
            axios.post("/api/updateBoardHits",{board_id:value.board_id}).then(
                res=>{
                    if(res.data === 1) {
                        setValue(prevState => {
                            return {...prevState, hit_count:String(Number(prevState.hit_count)+1)}
                        });
                    }
                }
            )
        }
    },[open])
    
    const onButtonClick = () => {
        if(value.board_id===props.num) {
            props.openNum("")
        }else {
            props.openNum(value.board_id);
        }
    }
    const fileDownload = (value) => {
    
        axios.post("/api/boardFIleDownLoad",{fileName:value.file_name,filePath:value.file_path},{responseType:"arraybuffer"}).then(
            res => {
            Filesaver.saveAs(new Blob([res.data]),value.real_file_name)
            });
    }

    const onSearchFileDown=()=>{
        if( value.board_id) {
            axios.post("/api/getBoardAttach",{board_id:value.board_id}).then(
                res=>{
                    setBoardAttachFile(res.data);
                }
            );
        }
    }

    useEffect(()=> {
        if(open) {
            onSearchFileDown();
        }
    },[open]);

    return (<>
                <Row onClick={() => onButtonClick()} style = {{cursor:'pointer',}}>
                    <Col xl="1" lg="1" md="1" sm="1" xs="1">
                        <span>{value.num}{value.new_notice==='Y'&&<Badge pill color="warning">N</Badge>}</span>
                    </Col>
                    <Col xl="8" lg="8" md="8" sm="8" xs="8">
                        <span style={{
                            color: value.now_top_flag=='Y'?  '#51cbce': ''
                            ,fontWeight: value.now_top_flag=='Y'? '700': ''
                            ,overflowWrap:'break-word'
                        }}>{value.title}
                            {value.attach_files && <i className="fa fa-file-text pl-3"/>}
                        </span>
                    </Col>
                    <Col xl="2" lg="2" md="2" sm="2" xs="2">
                        <span>{value.insert_date}</span>
                    </Col>
                    <Col xl="1" lg="1" md="1" sm="1" xs="1">
                        <Button onClick={() => onButtonClick() } close><i className={open===true?"fa fa-minus":"fa fa-plus"} color="black"/></Button>
                    </Col>
                </Row>
                <Collapse isOpen={open}>
                    <Row style={{borderTop:'1px solid rgba(0,0,0,.125)'  ,marginTop:'0.75rem' ,paddingTop:'0.75rem' }}>
                    <Col xl="1" lg="1" md="1" sm="1" xs="1"></Col>
                        <Col xl="10" lg="10" md="10" sm="10" xs="10" >
                            <CardBody className="pr-0 pl-0">
                                <Row style={{alignItems:'baseline'}}>
                                    <Col xl="4" lg="4" md="4" sm="4" xs="4" style={{display:'flex',}}>
                                        <div style={{fontWeight:'bold', marginRight:'15px'}}>작성자 : </div>
                                        <span>{value.author_name}</span>
                                    </Col>
                                    <Col xl="4" lg="4" md="4" sm="4" xs="4" style={{display:'flex',}}>
                                        <div style={{fontWeight:'bold', marginRight:'15px'}}>조회수 : </div>
                                        <span>{value.hit_count}</span>
                                    </Col>
                                    <Col xl="4" lg="4" md="4" sm="4" xs="4" style={{textAlignLast:'end' , padding:'0'}}>
                                        {user.userData&&(value.user_no===user.userData.user_no||user.userData&&(user.userData.klnet_id === "KLTEST01"))
                                        ?<>
                                            <Button onClick={()=> props.rowClick(value)} size="sm" color="primary" outline style={{marginRight:'5px' }}>수정</Button>
                                            <Button onClick={()=> props.ConfirmOn(value)} size="sm" color="default" outline>삭제</Button>
                                        </>
                                        :null}
                                    </Col>
                                </Row>
                                <Row style={{overflowWrap:"anywhere" ,padding: '1.25rem'}}>
                                    <Content>
                                        {/* <div className="contents-img" dangerouslySetInnerHTML={{__html:(value.content)&&((value.content).replaceAll(/ |\t/gi,'&nbsp;'))}}/> */}
                                        <div className="contents-img ql-editor" dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(String(value?.content))}}/>
                                    </Content>
                                </Row>
                                {boardAttachFile &&  <>
                                <Row style={{borderTop:'solid 1px'}}>
                                    {boardAttachFile.map((element,idx) => {
                                    return(
                                        <Button color="default" outline size="sm"  style={{margin:'1.25rem'}}
                                        key={idx} onClick={()=> fileDownload(element)}>
                                            {element.real_file_name}
                                        </Button>
                                    )
                                    })}
                                </Row>
                                </> }
                            </CardBody>
                        </Col>
                    </Row>
                </Collapse>
            </>
    )
}

export default function BoardList(props) { 
    const gubunList = [{value:'0',label:'구분'}
    ,{value:'1',label:'제목+내용'}
    ,{value:'2',label:'작성자'} ];
    const [data, setData] = useState([]);
    const [num, setNum] = useState(0);
    const [gubun, setGubun] = useState(gubunList[0]);
    const [keyword,setKeyWord] = useState("");
    const [openWritePage, setOpenWritePage] = useState(false);
    const [user, setUser] = useState("");
    const [rowData,setRowData] = useState(null);
    const [ConfirmOpen,setConFirmOpen] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const [writeButtonView, setWriteButtonView] = useState(false);
    useEffect(() => {
        props.fncClickMenu('게시판');
        onSearch();
    },[]);

    useEffect(() => {
        if(props.location.state) {
            setNum(props.location.state.param.board_id);
        }
    },[props.location]);
    useEffect(()=> {
      setUser(props)
    },[props]);

    useEffect(()=> {        
        if(user.userData) {
            if(user) {
                axios.post("/com/writeAuthority",{lineCode:'WDFC'}).then(
                    res=> {
                        if(res.statusText ==="OK") {
                            if(res.data.length > 0) {
                                res.data.forEach(element => {
                                    if(element.KLNET_ID === user.userData.klnet_id) {
                                        setWriteButtonView(true);
                                    }
                                });
                            }else {
                                setWriteButtonView(false);
                            }
                        }else {
                            setWriteButtonView(false);
                        }
                    }
                )
            }else {
                setWriteButtonView(false);
            }
        }else {
            setWriteButtonView(false);
        }
    },[user.userData]);
    
    useEffect(()=> {
        onSearch();
        if(openWritePage) {
            setRowData(null);
        }
    },[openWritePage]);


    const onSearch = () => {
        axios.post("/api/notice",{service:'WDFC', gubun:gubun, keyword:keyword}).then(
            res=> {
                if(res.statusText ==="OK") {
                    if(res.data.length > 0) {
                        setData(res.data);
                    }else {
                        setData([]);
                    }
                }else {
                    setData([]);
                }
            }
        )
    };

    const onWrite = () => {
        if(props.userData) {
            setOpenWritePage(true);
        }
    };

    const onRowClick = (data) => {
        setRowData(data);
        setOpenWritePage(true);
    };

    const onConfirmOpen = (data) => {
        setDeleteData(data);
        setConFirmOpen(true);
    };

    const onConfirm = () => {
        axios.post("/com/deleteBoardWithFile",{param:deleteData}).then(
            res=> {
                if(res.statusText ==="OK") {
                    if(res.data==="success"){

                        props.onAlert('success','삭제가 완료 되었습니다.');
                        onSearch();
                    }
                }
            }
        )
        setConFirmOpen(false);
    }

    return(<>
        <Col className="ml-auto mr-auto mt-3" xl="9" lg="9" md="9" sm="9" xs="9">
            <Row>
                <Col>
                    <CardBody style={{marginTop:'3%',textAlignLast:'center'}}><h3>NOTICE<i className="fa fa-bullhorn" aria-hidden="true"/></h3></CardBody>
                </Col>
            </Row>
            {!openWritePage?(<>
            <Row>
                <Col className="mb-3"  xl="2" lg="2" md="2" sm="4" xs="4">
                    <Select
                        className="customSelect "
                        name="gubun"
                        value={gubun}
                        defaultValue = {gubunList[0]}
                        onChange={(value)=> setGubun(value)}
                        options={gubunList}
                    />
                </Col>
                <Col className="mb-3"  xl="5" lg="5" md="5" sm="8" xs="8">
                        <InputGroup style={{padding:0}}>
                            <Input value={keyword} onChange={(e) => {setKeyWord(e.target.value)}} type="text"/>
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <Button close onClick = {() => onSearch()}><i className="fa fa-search"/></Button>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                </Col>
                <Col className="mb-3"    md={{offset:2,size:3}} sm="12" xs="12">
                    {writeButtonView &&
                    <Button style={{float:'right', color:'black'}} color="secondary" onClick={()=> onWrite()} outline><i className="fa fa-pencil-square-o"/>글쓰기</Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <ListGroup>
                        <ListGroupItem>
                            <Row>
                                <Col xl="1" lg="1" md="1" sm="1" xs="1">
                                    <span>순번</span>
                                </Col>
                                    
                                <Col xl="8" lg="8" md="8" sm="8" xs="8">
                                    <span>제목</span>
                                </Col>
                                <Col xl="2" lg="2" md="2" sm="2" xs="2">
                                    <span>작성일</span>
                                </Col>
                                <Col xl="1" lg="1" md="1" sm="1" xs="1">
                                    
                                </Col>
                            </Row>
                        </ListGroupItem>
                        {data.length > 0 &&data.map((value,index) => { return(
                        <ListGroupItem key={index} action>
                            <ListItem value={value} openNum={(e) => setNum(e)} num={num} user={user} rowClick={(data)=> onRowClick(data)} ConfirmOn={(data)=> onConfirmOpen(data)} />
                        </ListGroupItem>
                        )})}
                    </ListGroup>
                </Col>
            </Row>
            {writeButtonView &&
            <Row>
                <Col className="ml-auto mt-1">
                    <Button style={{float:'right', color:'black'}} color="secondary" onClick={()=> onWrite()} outline><i className="fa fa-pencil-square-o"/>글쓰기</Button>
                </Col>
            </Row>}  
        </>
    ):(
        <AddBoard goToList={() => setOpenWritePage(false)} onAlert={props.onAlert} rowData={rowData} userData={user}  {...props}/>
    )}
    </Col>
    <Modal
        size="sm"
        isOpen={ConfirmOpen}
        toggle={() => setConFirmOpen(false)}
    >
        <div className="modal-header no-border-header">
            <button
                className="close"
                type="button"
                onClick={() => setConFirmOpen(false)}
            >×</button>
        </div>
        <div className="modal-body text-center pl-0 pr-0">
            <h5>해당 공지를 삭제 하시겠습니까?</h5>
        </div>
        <div className="modal-footer">
            <div className="left-side">
                <Button className="btn-link" color="danger" type="button" onClick={()=> {onConfirm()}}>Yes</Button>
            </div>
            <div className="divider" />
            <div className="right-side">
                <Button
                className="btn-link"
                color="default"
                type="button"
                onClick={() => setConFirmOpen(false)}
                >
                No
                </Button>
            </div>
        </div>
    </Modal>
    </>
    )
}