import axios from "axios";
import _ from 'lodash';

//공통
export const ynList = [{value:'Y', label:'Y'},{value:'N', label:'N'}];
export const getLabel =  (option, obj, key  ,directI) => {
    // console.log(obj, obj[key],key,directI, !_.isEmpty(obj) ,option)
    let label ;
    if(obj.constructor ==Object && !_.isEmpty(obj)){
            const findIdx = option.findIndex(x=>x.value == obj[key]);    
            label= findIdx >-1 ? option[findIdx].label : false;
    }
    return label ||false;
}   

//BookingCard
export const consolYnList = [{value:null ,label:'N (default)'},{value:'C',label:'Y'}]
export const paymentList=[{value: 'P', label: 'PREPAID'},{value: 'C', label: 'COLLECT'}]
//Cntr
export const specialType = [{value : 'OG' , label : '(OOG) Out of Gauge'},{value : 'IG' , label : 'In Gauge'}];
export const stowageCell = [{value : '01' , label : '[01] On Deck'}
                            ,{value : '02' , label : '[02] Under Deck'}
                            ,{value : '03' , label : '[03] Hot Deleivery'}
                            ,{value : '04' , label : '[04] Top Stowage'}
                            ,{value : '05' , label : '[05] Avoid Boiler'}
                            ,{value : '06' , label : '[06] Bundle'}
                            ,{value : '07' , label : '[07] Damaged'}];
export const CommodityItem = [{value : '209' , label : '건축/설비'}
                            ,{value : '211' , label : '고지(폐지)'}
                            ,{value : '208' , label : '기계류'}
                            ,{value : '220' , label : '기타'}];
export const ventList = [ {label:'Full close',value:'FC'},
                            {label:'Not close',value:'NC'},
                            {label:'Full open',value:'FO'},
                            {label:'10% open',value:'10O'},
                            {label:'20% open',value:'20O'},
                            {label:'30% open',value:'30O'},
                            {label:'40% open',value:'40O'},
                            {label:'50% open',value:'50O'},
                            {label:'60% open',value:'60O'},
                            {label:'70% open',value:'70O'},
                            {label:'80% open',value:'80O'},
                            {label:'90% open',value:'90O'},
                        ]

export const selectLineCodeCntrSztp = (params,lineCode) => {
    axios.post("/shipper/selectLineCodeCntrSztp",{ params,lineCode:lineCode }).then(res => {
        console.log(res.data)
       return res.data;
    });
}

//special
export const tmp_unit = [ {label:'F',value:'F'},
                            {label:'C',value:'C'}]

//{[{key:'1', value:'Great danger'},{key:'2', value:'Medium danger'},{key:'3', value:'Minor danger'}]}
export const special_p_group = [{label:'III',value:'01'},
                        {label:'II',value:'02'},
                        {label:'I',value:'03'},
                        {label:'-',value:'04'},
                        ]
export const dangerSearchOption =[{label:'위험물 코드',value:'undg_code'},
                                    {label:'IMDG 코드',value:'imdg_code'},
                                    {label:'위험물 TYPE',value:'code_class'},
                                    {label:'위험물 영문명',value:'undg_desc'},
                                    {label:'위험물 한글명',value:'undg_kr_desc'}
                                ]
export const emsList = (code) => {
        const list =  [ {label : 'A', value: 'A'}, 
                        {label : 'B', value: 'B'}, 
                        {label : 'C', value: 'C'}, 
                        {label : 'D', value: 'D'}, 
                        {label : 'E', value: 'E'}, 
                        {label : 'F', value: 'F'}, 
                        {label : 'G', value: 'G'}, 
                        {label : 'H', value: 'H'}, 
                        {label : 'I', value: 'I'}, 
                        {label : 'J', value: 'J'}, 
                        {label : 'K', value: 'K'}, 
                        {label : 'L', value: 'L'}, 
                        {label : 'M', value: 'M'}, 
                        {label : 'N', value: 'N'}, 
                        {label : 'O', value: 'O'}, 
                        {label : 'P', value: 'P'}, 
                        {label : 'Q', value: 'Q'}, 
                        {label : 'R', value: 'R'}, 
                        {label : 'S', value: 'S'}, 
                        {label : 'T', value: 'T'}, 
                        {label : 'U', value: 'U'}, 
                        {label : 'V', value: 'V'}, 
                        {label : 'W', value: 'W'}, 
                        {label : 'X', value: 'X'}, 
                        {label : 'Y', value: 'Y'}, 
                        {label : 'Z', value: 'Z'}
                        ]
    return code =='F'?  list.slice(0,10) : list;
}
export const full_residue = [ {label:'full',value:'01'},
                            {label:'residue',value:'02'}]

export const vgmList = [{value:'SM1',label:'방법1'},{value:'SM2',label:'방법2'}]