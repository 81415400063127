import React,{useEffect,useState} from "react";
import {Col, Row, Button, Input, Form, FormGroup, Label } from "reactstrap";
import axios from "axios";
import Modal from "reactstrap/lib/Modal";
// import 'assets/css/paper-kit.css'
// import 'assets/css/bootstrap.min.css'
import styled from "styled-components";

import "assets/css/quillEditer.css";
import DOMPurify from 'dompurify';

const Content = styled.div`
& .contents-img strong  {
    font-weight: bold;
}
`

export default function NoticeModal(props) {

    const [popupOpen, setPopupOpen] = useState(false);
    const [checkYn, setCheckYn] = useState(null);
    const {className,noticeData,noticeIndex} = props;

    useEffect(()=> {
        if(noticeData) {
            setPopupOpen(true);
        }
    },[noticeData])

    /* 스토리지 제어 함수 정의 */
    var handleStorage = {
        // 스토리지에 데이터 쓰기(이름, 만료일)
        setStorage: function (name, exp) {
        // 만료 시간 구하기(exp를 ms단위로 변경)
        var date = new Date();
        date = date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
    
        // 로컬 스토리지에 저장하기
        // (값을 따로 저장하지 않고 만료 시간을 저장)
        localStorage.setItem(name, date)
        }
    };

    const checkboxClick = (board_id) => {
        if(!checkYn){
            handleStorage.setStorage(board_id,1);
            console.log(checkYn);
            setPopupOpen(false);
        }
        setCheckYn(!checkYn);
    }
    return (
        <>
         <Modal id={noticeIndex} style={{minWidth:'800px'}}className={"modal-dialog"+noticeIndex} isOpen={popupOpen} toggle={() => setPopupOpen(false)}>
                    <>
                    <div className="modal-header no-border-header">
                        <button
                            className="close"
                            type="button"
                            onClick={() => setPopupOpen(false)}>×</button>
                            <h5 className="modal-title" id="myModalLabel">{noticeData.title}</h5>
                    </div>
                    <div className="modal-body">
                        <div className="instruction">
                            <Row>
                                <Col md="12">
                                    <Content>
                                        <div  className="contents-img ql-editor"id="contents" style={{overflow:'auto'}} 
                                        dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(String(noticeData?.content))}}/>
                                        {/* dangerouslySetInnerHTML={{__html:noticeData.content}}/> */}
                                    </Content>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    {/* <div className="instruction">
                        <Row> */}
                            <Col md="12" className="mb-3" style={{textAlign: 'right'}}>
                                <span className="pr-5" >공지일 : {noticeData.insert_date}</span>
                            </Col>
                        {/* </Row>
                    </div> */}
                    <div className="modal-footer">
                            <Col md="10">
                                <Form>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input className="mt-2" type="checkbox" checked={checkYn} onChange={()=>checkboxClick(noticeData.board_id)}  style={ {opacity:'100',position:'static',visibility:'visible'}}/>
                                            오늘 하루 보지 않기
                                        </Label>
                                    </FormGroup>
                                </Form>          
                            </Col>
                            <Col md="2">
                                <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    onClick={() => setPopupOpen(false)}>닫기
                                </Button>
                            </Col>
                    </div>
                    </>
              </Modal>
        </>
    );
}


