



import React from "react";

// reactstrap components

// core components
import WeidongNavbar from "components/Navbars/WeidongNavbar.js";
import WeidongHeader from "components/Headers/WeidongHeader.js";
import FooterWeidong from "components/Footers/FooterWeidong.js";
//import AlertModal from 'components/Modals/Alert.js';
// sections for this page
import BlList from "views/bl/section/BlList.js";


export default function App(props) {
  
  return (
    <>
      {/* <WeidongNavbar {...props} /> */}
      <WeidongHeader />
      <BlList  {...props}/>
      <FooterWeidong />
    </>
  );
}
