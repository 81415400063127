import React, { useState } from 'react';
import Select from "react-select";
import {Label,Input,InputGroup,InputGroupAddon,InputGroupText} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from 'moment'

export {
    CustomInput,
    CustomSelect,
    CustomDatePicker,
    CustomDatePickerInput,
    CustomDateTimePicker
}

function CustomInput (props) {
    const {title,placeholder,name,id,labelProps,inputProps,value,onChange} = props;
    return (
        <>
            {title !== undefined? (
            <Label {...labelProps}>{title}</Label> 
            ):null}
            <Input type="text" name={name} id={id} placeholder={placeholder} {...inputProps} onChange={onChange} value={value}/>   
        </>
    );
}

function CustomSelect (props) {
    const {title,placeholder,name,optionData,labelProps,onChange,value} = props;
    return (
        <>
            {title !== undefined? (
            <Label {...labelProps}>{title}</Label>
            ):null}
            <Select
            className="react-select react-select-primary"
            classNamePrefix="react-select"
            name={name}
            value={value}
            onChange={onChange}
            options={optionData}
            placeholder={placeholder}
          />

        </>
    );
}

function CustomDatePickerInput (props) {
    const {title,dateFormat,timeFormat,value,id,labelProps,inputProps,onChange,readOnly} = props;
    const dateTimeDiv = React.createRef();
    const cy=(props.hasOwnProperty('inputProps')?inputProps.hasOwnProperty('style')?inputProps.style:{}:{});
    const result =(props.hasOwnProperty('inputProps')?inputProps.hasOwnProperty('disabled')?inputProps.disabled:false:false);
    const [validCheck,setValidCheck] = useState(false)
    const validMomentBlur = (value,id)=>{
        var checkType =typeof(value); 
        // console.log(value,id,checkType)
	    const now =moment(new Date()).format('YYYYMMDD'); 
        if( checkType === 'string' ) { 
            let date = value.replace(/[^0-9]/g, ''); 
            let dateTime = moment(date,'YYYYMMDD'); 
            let result =moment(date,'YYYYMMDD').isValid();
            // console.log(result,date.length)
            if((result && date.length == 8)){ 
                setValidCheck(false)
                onChange(dateTime,id);
            }else{
                setValidCheck(true)
                onChange(value,id);
                props.onAlert('danger','YYYYMMDD 형식으로 입력해주세요. ex)'+now);
            }
        }else if(moment.isMoment(value)){
            let dateTime = moment(value,'YYYYMMDD');
            if( dateTime.isValid() ) { 
                setValidCheck(false)
                onChange(dateTime,id);
            }else{
                setValidCheck(true)
                props.onAlert('danger','YYYYMMDD 형식으로 입력해주세요. ex)'+now);
            }
        }else{setValidCheck(false); return false}
    }
    return (
        <>
            {title !== undefined? (
            <Label {...labelProps}>{title}</Label>
            ):null}
            <InputGroup className="date" id={id}>
                <ReactDatetime
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    value={value}
                	onChange={onChange}
                    closeOnSelect={true}
                    onBlur={e=> { validMomentBlur(e,id)}}
                    inputProps = {{
                        ref:dateTimeDiv,style:cy,
                        placeholder:"날짜를 입력하세요. (예: 20230101)",
                        disabled:result,
                        readOnly: readOnly,
                        onFocus : e=> {//validMomentFocus(value,id)
                            if(moment.isMoment(value)){
                                // console.log('F', value)
                                return onChange(value.format('YYYYMMDD'),id)
                            }else{
                                let val = e.currentTarget.value;
                                val = val.replace(/[^0-9]/g, ''); 
                                if(val&&val.length>7){
                                    validMomentBlur(e.currentTarget.value,id)
                                }else{
                                    return onChange(val,id)
                                }
                            }
                        },
                        onChange:e=>{{
                            let val = e.currentTarget.value;
                            val = val.replace(/[^0-9]/g, ''); 
                            if(val&&val.length>7){
                                validMomentBlur(e.currentTarget.value,id)
                            }else{
                                return onChange(val,id)
                            }
                        }}
                        // onBlur : e=> {
                        //     if(value !== e.currentTarget.value) 
                        //     validMomentBlur(e.currentTarget.value,id)
                        // }
                        //     if(moment.isMoment(value)){
                        //     console.log('BLur', value)
                        //     return onChange(value.format('YYYY-MM-DD'),id)
                        // }else{
                        //     return onChange(value,id)
                        // }}
                    }}
                    {...inputProps}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText onClick={()=>dateTimeDiv.current.click()}>
                        <span className="glyphicon glyphicon-calendar" >
                            <i className="fa fa-calendar" />
                        </span>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </>
    );
}

function CustomDatePicker (props) {
    const {title,dateFormat,timeFormat,value,id,labelProps,inputProps,onChange,readOnly} = props;
    const dateTimeDiv = React.createRef();
    const cy=(props.hasOwnProperty('inputProps')?inputProps.hasOwnProperty('style')?inputProps.style:{}:{});
    const result =(props.hasOwnProperty('inputProps')?inputProps.hasOwnProperty('disabled')?inputProps.disabled:false:false);

    const validMomentBlur = (value,id)=>{
        var checkType =typeof(value); 
	    const now =moment(new Date()).format('YYYYMMDD'); 
        if( checkType === 'string' ) { 
            let date = value.replace(/[^0-9]/g, ''); 
            let dateTime = moment(date,'YYYYMMDD'); 
            let result =moment(date,'YYYYMMDD',true).isValid();
            if((result && date.length == 8)){ 
                onChange(dateTime,id);
            }else{
                onChange(value,id);
                props.onAlert('danger','YYYYMMDD 형식으로 입력해주세요. ex)'+now);
            }
        }else if(moment.isMoment(value)){
            let dateTime = moment(value,'YYYYMMDD',true);
            if( dateTime.isValid() ) { 
                onChange(dateTime,id);
            }else{
                props.onAlert('danger','YYYYMMDD 형식으로 입력해주세요. ex)'+now);
            }
        }else{return false}
    }
    
    return (
        <>
            {title !== undefined? (
            <Label {...labelProps}>{title}</Label>
            ):null}
            <InputGroup className="date" id={id}>
                <ReactDatetime
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    value={value}
                	onChange={onChange}
                    closeOnSelect={true}
                    inputProps = {{ref:dateTimeDiv,
                        style:cy,
                        placeholder:"날짜를 입력하세요. (예: 20230101)",
                        disabled:result,
                        readOnly: readOnly,
                        onChange:e=>{{
                            let val = e.currentTarget.value;
                            val = val.replace(/[^0-9]/g, ''); 
                            if(val&&val.length>7){
                                validMomentBlur(e.currentTarget.value,id)
                            }else{
                                return onChange(val,id)
                            }
                        }},
                        onBlur : e=> {{
                            props.onBlur&&props.onBlur(e)
                        }}
                    }}
                    {...inputProps}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText onClick={()=>dateTimeDiv.current.click()}>
                        <span className="glyphicon glyphicon-calendar" >
                            <i className="fa fa-calendar" />
                        </span>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </>
    );
}

function CustomDateTimePicker (props) {
    const {title,dateFormat,timeFormat,value,id,labelProps,inputProps,onChange} = props;
    const dateTimeDiv = React.createRef();
    const cy=(props.hasOwnProperty('inputProps')?inputProps.hasOwnProperty('style')?inputProps.style:{}:{});
    return (
        <>
            {title !== undefined? (
            <Label {...labelProps}>{title}</Label>
            ):null}
            <InputGroup className="date" id={id}>
                <ReactDatetime
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    value={value}
                	onChange={onChange}
                    closeOnSelect={true}
                    inputProps = {{ref:dateTimeDiv,style:cy,placeholder:"날짜를 입력하세요. (예: 202301011200)"}}
                    {...inputProps}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText onClick={()=>dateTimeDiv.current.click()}>
                        <span className="glyphicon glyphicon-calendar" >
                            <i className="fa fa-calendar" />
                        </span>
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </>
    );
}
