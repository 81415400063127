import React, {useContext, useEffect,useCallback, Fragment} from "react";
// import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import axios from "axios";
// import {Spinner} from "reactstrap";
//import { Alert, Container,Fade } from "reactstrap";

import { getMenuList } from 'menu_data';
import AlertMessage from "components/Alert/AlertMessage.js";
import WeidongIndex from "views/Weidong_Index.js";

import * as valid from 'components/common/validation.js';
import AlertNotiMessage from 'components/Modals/Alert.js';
import Certify from 'components/User/Certify.js';
import LoginErrorPage from 'components/User/LoginErrorMenu.js';
import FindId from 'components/User/FindId.js';
import FindPw from 'components/User/FindPw.js';
import Axios from 'axios'
//import {Spinner} from "reactstrap";
import WeidongNavbar from "components/Navbars/WeidongNavbar.js";
import useIsMount from 'components/common/useIsMount.js';

import {observer,useObserver} from 'mobx-react-lite';
import { userStore, timerStore } from 'store/UserStore.js';

const App = (props) => {  
	const routeList =getMenuList();
	const isMount = useIsMount();
	// const [open,setOpen] =  React.useState(false);
	const [login, setLogin] = React.useState(false);
	const [register, setRegister] = React.useState(false);
	// const [isAuth,setIsAuth] =  React.useState(false);
	const [openAlert, setOpenAlert] = React.useState(false);
	// const [alertSuccess, setAlertSuccess] = React.useState(true);
	const [status, setStatus] = React.useState("");
	const [message, setMessage] = React.useState("");	 
	const [openNotiMessage, setOpenNotiMessage] = React.useState("");
	const [clickMenu, setClickMenu] = React.useState("");

	const setUserData =  (data)=>{
		userStore.user =data
	}
	const userData =useObserver( ()=>{
		return  userStore.user
	})
	const isLogOut = useCallback(()=>{
		// console.log('logout')
		userStore.logout();
		onAlert( 'success','로그아웃 되었습니다.');
	},[])

	useEffect(() => {
		if(userStore.isAuth==401){//로그인 되어있는상태만 로그아웃됨
			onAlert('error',valid.NO_PERMISSION_MSG);
			setTimeout(()=>{
				userStore.logout();
			},2000);
		}
		if(userStore.isAuth==419){//로그인 되어있는상태만 로그아웃됨
			onAlert('error',valid.TOKEN_EXPIRED_MSG);
			setTimeout(()=>{
				userStore.logout();
			},2000);
		}
	}, [userStore.isAuth]);

	useEffect(()=> {
		let routes =window.location.pathname;
		Axios.post('/api/menuAccessLog',{
			user:userData?userData.user_no:'GUEST'
		,path:window.location.pathname
		// ,name:getActiveRoute(routes)
		,name:window.location.pathname=='/'||window.location.pathname=='/weidongIndex'||window.location.pathname=='/svc/weidongIndex'
				? '메인 화면'
				:getActiveRoute(routes)
		,systemName :'WEIDONG'
		})
	},[window.location.pathname])
	
	useEffect(() => {
		axios.interceptors.response.use(function (response) {
			// console.log('=============>response',response);
			  return response;
		  }, function (error) {
			// const originalRequest = error.config;
				if(error&&error.response){
					if(error.response.status === 403) {	
						// onAlert("error",valid.NOTLOGIN_MSG+" [code:"+error.response.status+"]");
						if(userStore.user)userStore.user =null;
					} else if(error.response.status === 401) {
						onAlert('error',valid.NOTLOGIN_MSG);//230629추가
						setLogin(true)
						if(userStore.user)userStore.user =null;
					} else if(error.response.status === 419) {
						onAlert("error",valid.TOKEN_EXPIRED_MSG+" [code:"+error.response.status+"]");
						setTimeout(()=>{
                            if(userStore.user)userStore.logout();
                        },2000);
					} else if(error.response.status === 500) {
						// onAlert("error",valid.ERR_MSG+" [code:"+error.response.status+"]");
					} else if(error.response.status === 400) {
						// onAlert("error",valid.ERR_MSG+" [code:"+error.response.status+"]");
					} else {
						// onAlert("error",valid.ERR_MSG+" [code:"+error.response.status+"]");
						// console.log("error:",error);
					}
				}
				return Promise.reject(error);
			}
		);		
	},[]);

	const onAlert = (status,message)=> {	
		setStatus(status);
		setMessage(message);
		setOpenAlert(true);
	}
	
	const NoMatch = (arg) => {
		// console.log(arg);
		return (
			<h3>Not Found Page</h3>
		);
	}
	const onNotiAlert = (status,message)=> {
		setOpenNotiMessage(true);
		setStatus(status);
		setMessage(message);
		setTimeout( function(){
			setOpenNotiMessage(false);
		}, 2000)
	}
	const fncOpenMessage =(open)=> {
		setOpenNotiMessage(open);
	}

	const fncClickMenu =( menu )=> {
		if( menu ) {
			setClickMenu(menu)
		}
	}

	
	const getActiveRoute = routes => {
		// console.log(routes)
		let activeRoute = "-";
		for (let i = 0; i < routeList.length; i++) {
			if (routes.indexOf(routeList[i].path) !== -1) {
			  return routeList[i].name;
			}
		}
		return activeRoute;
	};
  	return (
		<>
			<BrowserRouter>
		    	<Switch>
					<Route path="/return_certify" 
					render={(props) => <Certify userStore={userStore}  validation={valid}
					userData={userData}  setUserData={setUserData}
					logOut={isLogOut} onAlert={onAlert} 
					login={login} setLogin={(e)=>(setLogin(e))} 
					register={register} setRegister={(e)=>setRegister(e)} 
					onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu} 
					{...props} />} /> 
					<Route path="/svc/account" 
					render={(props) => <LoginErrorPage userStore={userStore}  validation={valid}
					userData={userData}  setUserData={setUserData}
					logOut={isLogOut} onAlert={onAlert} 
					login={login} setLogin={(e)=>(setLogin(e))} 
					register={register} setRegister={(e)=>setRegister(e)} 
					onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu} 
					{...props} />} /> 
					<Route path="/svc/findid" 
					render={(props) => <FindId userStore={userStore}  validation={valid}
					userData={userData}  setUserData={setUserData}
					logOut={isLogOut} onAlert={onAlert} 
					login={login} setLogin={(e)=>(setLogin(e))} 
					register={register} setRegister={(e)=>setRegister(e)} 
					onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu} 
					{...props} />} /> 
					<Route path="/svc/findpw" 
					render={(props) => <FindPw userStore={userStore}  validation={valid}
					userData={userData}  setUserData={setUserData}
					logOut={isLogOut} onAlert={onAlert} 
					login={login} setLogin={(e)=>(setLogin(e))} 
					register={register} setRegister={(e)=>setRegister(e)} 
					onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu} 
					{...props} />} /> 
				<Fragment>
					<WeidongNavbar  userStore={userStore} userData={userData} isLogOut={isLogOut} setUserData={setUserData} validation={valid} onAlert={onAlert} login={login} setLogin={(e)=>(setLogin(e))} register={register} setRegister={(e)=>setRegister(e)}  fncClickMenu={fncClickMenu} onNotiAlert={onNotiAlert}  
					{...props}/>
					<Route
						exact path="/"
						render={(props) => <WeidongIndex userStore={userStore} userData={userData} logOut={isLogOut} setUserData={setUserData} validation={valid} onAlert={onAlert} login={login} setLogin={(e)=>(setLogin(e))} register={register} setRegister={(e)=>setRegister(e)}  fncClickMenu={fncClickMenu} onNotiAlert={onNotiAlert}  clickMenu={clickMenu}
						{...props} />}
					/> 
					<Route
						exact path="/weidongIndex"
						render={(props) => <WeidongIndex  userStore={userStore} userData={userData} logOut={isLogOut} setUserData={setUserData} validation={valid} onAlert={onAlert} login={login} setLogin={(e)=>(setLogin(e))} register={register} setRegister={(e)=>setRegister(e)}  onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu}
						{...props} />}
					/>
					<Route
						exact path="/svc/weidongIndex"
						render={(props) => <WeidongIndex  userStore={userStore} userData={userData} logOut={isLogOut} setUserData={setUserData} validation={valid} onAlert={onAlert} login={login} setLogin={(e)=>(setLogin(e))} register={register} setRegister={(e)=>setRegister(e)}  onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu}
						{...props} />}
					/>
						{!userStore.isAuth&&!userStore.loading ? routeList.map((route,ind) =>(
							<Route key={ind}
						path={route.path}
						//component={prop.component}
						render={(props) =><route.component 
							userStore={userStore}  validation={valid}
							userData={userData}  setUserData={setUserData}
							logOut={isLogOut} onAlert={onAlert} 
							login={login} setLogin={(e)=>(setLogin(e))} 
							register={register} setRegister={(e)=>setRegister(e)} 
							onNotiAlert={onNotiAlert} fncClickMenu={fncClickMenu} clickMenu={clickMenu} 
							{...props}/>
							}
						/> 
						)):null}
					
				</Fragment>
				<Route path="*" component={NoMatch} />
				<Redirect from="/" to="/weidongIndex" />
		    </Switch>
		  </BrowserRouter>
		  	
		  <AlertNotiMessage 
			open={openNotiMessage}
			close={()=>setOpenNotiMessage(!openNotiMessage)}
			status ={status ==="success"?status:"danger"}
			message = {message}
			fncOpenMessage={fncOpenMessage} 
			/> 
		  <AlertMessage 
	        message={message}
	        isOpen={openAlert}
	        isClose={()=>{setOpenAlert(false);}}
	        // fontColor={font}   //선택사항
	        alertColor={status ==="success"?status:"danger"} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
	        timeOut={2000} //선택사항
	        >
		  </AlertMessage>
		  
		 
		</>
  )
}


export default observer(App);