/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup,Label,Input,Button,Collapse,FormText,FormFeedback} from "reactstrap";
import Select from "react-select";
//import ReactDatetime from "react-datetime";
import {Calendar,momentLocalizer} from 'react-big-calendar';
import Moment from 'moment';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'assets/css/react-big-calendar.css';

import axios from 'axios';
import * as validation from 'components/common/validation.js';
import InputValid from "components/CustomInput/InputValid.js";

export default function Schedule(props) {
	
	const {loadData,bookmark,getPort,getVsl,outLinePortList,inLinePortList} = props;
	const [schedule, setSchedule] = useState({});
	//const [defaultSelect, setDefaultSelect] = React.useState(null);
	const [coll ,setColl] = useState(false);
	const [calendarData,setCalendarData] = useState([]);
	const [calDate,setCalDate] = React.useState(new Date());
	const [startPort,setStartPort] = useState("");
	const [endPort,setEndPort] = useState("");
	const [selectButton, setSelectButton] = useState("");
	const [routePort,setRoutePort] = useState([]);
	const [localizer, setLocalizer] = useState(momentLocalizer(Moment));
	const [searchFdp, setSearchFdp] = useState(loadData.sch_fdp?loadData.sch_fdp:"");
	const [fdpPortCodeList,setFdpPortCodeList] = useState([])
	// let startPort;
	// let endPort;
	useEffect(()=> {
		setSchedule(loadData);
	},[loadData]);

	useEffect(()=>{
		axios.post("/shipper/getLineRoute",{ line_code:schedule.line_code}).then(res => {
			setRoutePort(res.data);
		});
		
	},[schedule.line_code]);
	useEffect(()=> {
		if( !schedule.sch_fdp ) {
			selectFdpCodePortList({
				prot_code: null
			});
		} else {
			selectFdpCodePortList({
				port_code:schedule.sch_fdp
			});
		}
	},[schedule.sch_fdp]);
	useEffect(()=>{
        if( searchFdp ) {
            selectFdpCodePortList({
                port_code: searchFdp
            });
        }
    },[searchFdp]);
  	const selectedEvent = (event,e) => {
		let list = {
			...schedule,
			sch_vessel_name:event.sch_vessel_name,
			sch_vessel_voyage:event.sch_vessel_voyage,
			sch_vessel_code:event.sch_vessel_code,
			vsl_type:event.vsl_type,
			sch_pol:event.sch_pol,
			sch_pol_name:event.sch_pol_name,
			sch_pod:event.sch_pod,
			sch_pod_name:event.sch_pod_name,
			sch_eta:event.sch_eta,
			sch_srd:event.sch_etd
		};
		setSchedule(list);
		props.propsData(list);
		setColl(false);
	};

	const onHandleStartDate = (start,end) => {
		setStartPort(start);
		setEndPort(end);
		if(start && end) {
			setSelectButton(start+end);
		} else {
			setSelectButton("ALL");
		}
		let vDate = calDate;
		vDate.setDate('01');
		calendarSql(start,end,vDate,'5 week','Y');
		// axios.post("/shipper/getWdSchCal",{ startport:start,endport:end,eta:Moment(new Date()).format('YYYYMMDD'),week:'5 week', line_code:schedule.line_code}).then(res => {
		// 	setColl(true);
		// 	if(res.data && res.data.length > 0) {
		// 		setCalendarData(res.data); //console.log("rs:",res.data)
		// 	} else {
		// 		setCalendarData([]);
		// 		props.onAlert("error","조회된 (스케줄) 정보가 없습니다.");
		// 	}
		// });
	}
	const onHandleReturnVal = (event,name) => {
		let list = {...schedule, [name]:(event.target.value.toUpperCase())||null};
		setSchedule(list); 
	}
  
	const onPropsReturn = () => {
		props.propsData(schedule);
	}
	const onChangeVal = ( value, key ) => {

		// e.preventDefault();
		if( 'sch_pol' === key ) {
			outLinePortList.map((element, key ) => {
				if( value == element.port_code ) {
					setSchedule({...schedule, ['sch_pol']:value.toUpperCase() , ['sch_pol_name']:element.port_name.toUpperCase()});
					props.propsData({...schedule, ['sch_pol']:value.toUpperCase() , ['sch_pol_name']:element.port_name.toUpperCase()});
				}
			});
		}
		if( 'sch_por' === key ) {
			outLinePortList.map((element, key ) => {
				if( value == element.port_code ) {
					setSchedule({...schedule, ['sch_por']:value.toUpperCase() , ['sch_por_name']:element.port_name.toUpperCase()});
					props.propsData({...schedule, ['sch_por']:value.toUpperCase() , ['sch_por_name']:element.port_name.toUpperCase()});
				}
			});
		}
		if( 'sch_pod' === key ) {
			inLinePortList.map((element, key ) => {
				if( value == element.port_code ) {
					setSchedule({...schedule, ['sch_pod']:value.toUpperCase() , ['sch_pod_name']:element.port_name.toUpperCase()});
					props.propsData({...schedule, ['sch_pod']:value.toUpperCase() , ['sch_pod_name']:element.port_name.toUpperCase()});
				}
			});
		}
		if( 'sch_pld' === key ) {
			inLinePortList.map((element, key ) => {
				if( value == element.port_code ) {
					setSchedule({...schedule, ['sch_pld']:value.toUpperCase() , ['sch_pld_name']:element.port_name.toUpperCase()});
					props.propsData({...schedule, ['sch_pld']:value.toUpperCase() , ['sch_pld_name']:element.port_name.toUpperCase()});
				}
			});
		}
		if( 'sch_fdp' === key ) {
		
			fdpPortCodeList.map((element, key ) => {
				if( value == element.port_code ) {

					
					setSchedule({...schedule, ['sch_fdp']:value , ['sch_fdp_name']:element.port_name});
					props.propsData({...schedule, ['sch_fdp']:value , ['sch_fdp_name']:element.port_name});
				}
			});
		}
	}
	const fncOnKeyDown=(e)=>{
        const inputValue = e.replace(/\W/g,'');
        // console.log(e.keyCode, e, searchFdp)
        setSearchFdp( inputValue )
    }
	const selectFdpCodePortList = (params)=>{
        axios.post("/shipper/selectFdpCodePortList",{ params },{}).then(res=>{
            setFdpPortCodeList(res.data);
        });
    }
	const calendarSql = (start,end,etd,week,init) =>{
		axios.post("/api/getWdSchCal",{ startport:start,endport:end
			,eta:Moment(etd).format('YYYYMMDD'),week:week, line_code:'WDFC'},{})								
		.then(res => { setColl(true);
			if(res.data && res.data.length > 0) {
						  setCalendarData(res.data);
						} else { 
						  if(init){
							setCalendarData([]);
						  }
						  props.onAlert("error","조회된 (스케줄) 정보가 없습니다.");
						}
					  });
	  }; 
  	const CustomToolbar = (toolbar) => {
		const goToBack=()=> {
			let mDate = toolbar.date;
            let newDate;     
            newDate = new Date(mDate.getFullYear(),mDate.getMonth()-1,1)
            toolbar.onNavigate('Back', newDate);
			setCalDate(newDate);
			calendarSql(startPort,endPort,newDate,'5 week');
			// var vDate = new Date();
			// vDate.setMonth(toolbar.date.getMonth() -1);
			// vDate.setDate('01');
			// toolbar.date.setMonth(toolbar.date.getMonth() -1);
			// toolbar.onNavigate('Back');
			// axios.post("/shipper/getWdSchCal",{ startport:startPort,endport:endPort,eta:Moment(toolbar.date).format('YYYYMMDD'),week:'5 week', line_code:schedule.line_code}).then(res => {
			// 	setColl(true);
			// 	if(res.data && res.data.length > 0) {
			// 		setCalendarData(res.data); //console.log("rs:",res.data)
			// 	} else {
			// 		// setCalendarData([]);
			// 		props.onAlert("error","조회된 (스케줄) 정보가 없습니다.");
			// 	}
			// });	
		}
		const goToNext=()=> {
			let mDate = toolbar.date;
            let newDate; 
            newDate = new Date(mDate.getFullYear(),mDate.getMonth()+1,1);
            toolbar.onNavigate('Next',newDate);
			setCalDate(newDate);
            calendarSql(startPort,endPort,newDate,'5 week');
			// var vDate = new Date();
			// vDate.setMonth(toolbar.date.getMonth() +1);
			// vDate.setDate('01');
			// toolbar.date.setMonth(toolbar.date.getMonth() +1);
			// toolbar.onNavigate('Next');
			// axios.post("/shipper/getWdSchCal",{ startport:startPort,endport:endPort,eta:Moment(toolbar.date).format('YYYYMMDD'),week:'5 week',line_code:schedule.line_code}).then(res => {
			// 	setColl(true);
			// 	if(res.data && res.data.length > 0) {
			// 		setCalendarData(res.data); //console.log("rs:",res.data)
			// 	} else {
			// 		//  setCalendarData([]);
			// 		props.onAlert("error","조회된 (스케줄) 정보가 없습니다.");
			// 	}
			// });
		}
		const goToCurrent=()=> {
			const now = new Date();
			// if(now.getMonth() !== toolbar.date.getMonth() ) {
				// var vDate = now;
				// vDate.setDate('01');
				toolbar.date.setYear(now.getFullYear());
                toolbar.date.setMonth(now.getMonth());
                toolbar.onNavigate('ToDay');
				setCalDate(toolbar.date);
				calendarSql(startPort,endPort,now,'5 week');
				// axios.post("/shipper/getWdSchCal",{ startport:startPort,endport:endPort,eta:Moment(toolbar.date).format('YYYYMMDD'),week:'5 week', line_code:schedule.line_code}).then(res => {
				// 	setColl(true);
				// 	if(res.data && res.data.length > 0) {
				// 		setCalendarData(res.data); //console.log("rs:",res.data)
				// 	} else {
				// 		//setCalendarData([]);
				// 		props.onAlert("error","조회된 (스케줄) 정보가 없습니다.");
				// 	}
				// });
			// }
		}
	
		const label =()=>{
			const date = Moment(toolbar.date);
			return (
				<h2><b>{date.format('YYYY')+" / "+date.format('MM')}</b></h2>	  
			);
		};
	
		return (
			<div style={{width:'87%',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',marginBottom:'10px',fontSize:'16px'}}>
				<div>
					<Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToCurrent}>Today</Button>
					<Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToBack}>Back</Button>
					<Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToNext}>Next</Button>
				</div>
				<label style={{textAlign:'center',padding:'0 10px',flexGrow:'1'}}>{label()}</label>
			</div>
		);
	}
	  
  	return (
    	<>
    		{bookmark?
			<Row>
	    		<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">BookMark Name</Label>
						<Input type="text" name="schedule_bookmark_name" id="schedule_bookmark_name"
							invalid={!schedule.schedule_bookmark_name?true:false}
							value={schedule.schedule_bookmark_name?schedule.schedule_bookmark_name:""} 
							onChange = {(event)=>onHandleReturnVal(event,'schedule_bookmark_name')} 
							onBlur={onPropsReturn}/>
						<FormFeedback>{validation.REQ_MSG}</FormFeedback> 
					</FormGroup>
	    		</Col>
	 		</Row>:
			<Row>
				<Col className="col-12 mb-2">
					<div className="text-center">
						<Button className="mt-1 mr-1 p-0" color={selectButton==="ALL"?"info":"default"} type="button" onClick={()=>onHandleStartDate('','')}>전체{' '}</Button>
						{(routePort.length > 0) &&
							routePort.map((data,key)=> {
								return(
									<Button 
										key={"sch_"+key} 
										className="mt-1 mr-1 p-0"
										color={selectButton===data.start_port_code+data.end_port_code?"info":"default"} 
										type="button" 
										onClick={()=>onHandleStartDate(data.start_port_code,data.end_port_code)}>{data.start_port_kr_name}->{data.end_port_kr_name+' '}
									</Button>
								)
							})
						}
					</div>
				</Col>
				<Col className="col-12 mb-2">
					<Collapse isOpen={coll}>
						<Calendar
							selectable
							popup
							localizer={localizer}
							events={calendarData}
							startAccessor="start"
							endAccessor="end"
							style={{ height: '400px' }}
							defaultDate={new Date()} 
							showAllEvents={true}
							views={["month"]}
							components={{toolbar:CustomToolbar}}
							onSelectEvent={(event,e) => selectedEvent(event,e)}
							eventPropGetter={(event)=>{
								if(event && event.vsl_type === '41') {
									return {className:"bg-warning",style:{fontSize:'0.85em',paddingTop:'0',paddingBottom:'0'}}
								} else {
									return {className:"bg-info",style:{fontSize:'0.85em',paddingTop:'0',paddingBottom:'0'}}
								}
							}}
						/>
					</Collapse>
				</Col>
			</Row>}
    		<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">VESSEL</Label>
						<Select
							className="customSelect react-select-primary"
							name="vsl_name"
							value={{
								value:schedule.sch_vessel_name?schedule.sch_vessel_name:'',
								label:schedule.sch_vessel_name?schedule.sch_vessel_name:'선택'
							}}
							onChange={(value)=>{setSchedule({...schedule,'sch_vessel_name':value.value,'vsl_type':value.vsl_type});}}
							options={getVsl}
							styles={{
								control: provided => ({...provided,border:!bookmark&&!schedule.sch_vessel_name?'1px solid red':'',minHeight:'40px',height:'40px'}),
								indicatorsContainer: provided => ({...provided,height:'40px'})
							}}
							onBlur={onPropsReturn}
						/>
						<InputValid 
							type="hidden"
							name="sch_vessel_name"
							id="sch_vessel_name"
							maxLength="35"
							value={schedule.sch_vessel_name?schedule.sch_vessel_name:''}
							// onChange={(e)=>onHandleReturnVal(e, 'sch_fdp_name')}
							onBlur={onPropsReturn}
							validtype="text"
							required={true} 
							feedid="schedule"
						/>
						{(!bookmark&&!schedule.sch_vessel_name) && <FormText className="text-danger">필수</FormText>} 


					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Col className="col-12 p-0">
							<Label className="mb-0">PLACE OF RECEIPT</Label>
							<Row>
								<Col xl="5" className="col-5 pr-1">
									<Select
										className="react-select react-select-primary"
										name="sch_por"
										value={{value:schedule.sch_por?schedule.sch_por:'',
												label:schedule.sch_por?(outLinePortList.findIndex(x=>x.value===schedule.sch_por)>=0)?outLinePortList[outLinePortList.findIndex(x=>x.value===schedule.sch_por)].label:'선택':'선택'
										}}
										onChange = {(value)=>onChangeVal(value.value,'sch_por')}
										onBlur={onPropsReturn}
										options={outLinePortList}
										styles={{
											indicatorContainer: provided => ({...provided,color:''})
										}}
									/>
								</Col>
								<Col xl="7" className="col-7 pl-1">
									<InputValid 
										type="text"
										name="sch_por_name"
										id="sch_por_name"
										maxLength="35"
										value={schedule.sch_por_name?schedule.sch_por_name:''}
										onChange={(e)=>onHandleReturnVal(e, 'sch_por_name')}
										onBlur={onPropsReturn}
										validtype="text"
										required={false} 
										feedid="schedule"
									/>
								</Col>
							</Row>
						</Col>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Col className="col-12 p-0">
							<Label className="mb-0">POL</Label>		 
							<Row>
								<Col xl="5" className="col-5 pr-1">
									<Select
										className="react-select react-select-primary"
										name="sch_pol"
										value={{value:schedule.sch_pol?schedule.sch_pol:'',
										label:schedule.sch_pol?
											(outLinePortList.findIndex(x=>x.value===schedule.sch_pol)>=0)?
													outLinePortList[outLinePortList.findIndex(x=>x.value===schedule.sch_pol)].label:
												'선택':'선택'
										}}
										onChange = {(value)=>onChangeVal(value.value,'sch_pol')}
										onBlur={onPropsReturn}
										options={outLinePortList}
										styles={{
											indicatorContainer: provided => ({...provided,color:''})
										}}
									/>
								</Col>
								<Col xl="7" className="col-7 pl-1">
									<InputValid 
										type="text"
										name="sch_pol_name"
										id="sch_pol_name"
										maxLength="35"
										value={schedule.sch_pol_name?schedule.sch_pol_name:''}
										onChange={(e)=>onHandleReturnVal(e, 'sch_pol_name')}
										onBlur={onPropsReturn}
										validtype="text"
										required={false} 
										feedid="schedule"
									/>
								</Col>
								{(!bookmark && schedule.sch_srd) &&
								<Col xl="2" className="col-2 pl-1">
									<Label className="mt-2">{schedule.sch_srd?Moment(schedule.sch_srd).format('MM-DD'):''}</Label>
								</Col>}
							</Row>
						</Col>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Col className="col-12 p-0">
							<Label className="mb-0">Place of Delivery</Label>
							<Row>
								<Col xl="5" className="col-5 pr-1">
									<Select
										className="react-select react-select-primary"
										name="sch_pld"
										value={{value:schedule.sch_pld?schedule.sch_pld:'',
										label:schedule.sch_pld?
											(inLinePortList.findIndex(x=>x.value===schedule.sch_pld)>=0)?
											inLinePortList[inLinePortList.findIndex(x=>x.value===schedule.sch_pld)].label:
												'선택':'선택'
										}}
										onChange = {(value)=>onChangeVal(value.value,'sch_pld')}
										onBlur={onPropsReturn}
										options={inLinePortList}
										styles={{
											indicatorContainer: provided => ({...provided,color:''})
										}}
									/>
								</Col>
								<Col xl="7" className="col-7 pl-1">
									<InputValid 
										type="text"
										name="sch_pld_name"
										id="sch_pld_name"
										maxLength="35"
										value={schedule.sch_pld_name?schedule.sch_pld_name:''}
										onChange={(e)=>onHandleReturnVal(e, 'sch_pld_name')}
										onBlur={onPropsReturn}
										validtype="text"
										required={false} 
										feedid="schedule"
									/>
								</Col>
							</Row>
						</Col>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Col className="col-12 p-0">
							<Label className="mb-0">POD</Label>
							<Row>
								<Col xl="5" className="col-5 pr-1">
									<Select
										className="react-select react-select-primary"
										id="sch_pod"
										name="sch_pod"
										value={{value:schedule.sch_pod?schedule.sch_pod:'',
										label:schedule.sch_pod?
											(inLinePortList.findIndex(x=>x.value===schedule.sch_pod)>=0)?
											inLinePortList[inLinePortList.findIndex(x=>x.value===schedule.sch_pod)].label:
												'선택':'선택'
										}}
										onChange = {(value)=>onChangeVal(value.value,'sch_pod')}
										onBlur={onPropsReturn}
										options={inLinePortList}
										styles={{
											indicatorContainer: provided => ({...provided,color:''})
										}}
									/>


								</Col>
								<Col xl="7" className="col-7 pl-1">
									<InputValid 
										type="text"
										name="sch_pod_name"
										id="sch_pod_name"
										maxLength="35"
										value={schedule.sch_pod_name?schedule.sch_pod_name:''}
										onChange={(e)=>onHandleReturnVal(e, 'sch_pod_name')}
										onBlur={onPropsReturn}
										validtype="text"
										required={false} 
										feedid="schedule"
									/>
								</Col>
								{(!bookmark && schedule.sch_eta) &&
								<Col xl="2" className="col-2 pl-1">
									<Label className="mt-2">{schedule.sch_eta?Moment(schedule.sch_eta).format('MM-DD'):''}</Label>
								</Col>}
							</Row>
						</Col>
					</FormGroup>
				</Col>
    		</Row>
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">Place of B/L Issue</Label>
						<InputValid 
							type="text"
							name="sch_bl_issue_name"
							id="sch_bl_issue_name"
							maxLength="35"
							value={schedule.sch_bl_issue_name?schedule.sch_bl_issue_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'sch_bl_issue_name')}
							onBlur={onPropsReturn}
							validtype="text"
							required={false} 
							feedid="schedule"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Col className="col-12 p-0">
							<Label className="mb-0">Final Destination</Label>
							<Row>
								<Col xl="5" className="col-5 pr-1">
									<Select
										cachedOptions
										defaultOptions
										className="react-select react-select-primary"
										//className="react-select react-select-primary"
										name="sch_fdp"
										id="select_sch_fdp"
										value={{
											value:schedule.sch_fdp?schedule.sch_fdp:'',
											label:schedule.sch_fdp?(fdpPortCodeList.findIndex(x=>x.value===schedule.sch_fdp)>=0)?fdpPortCodeList[fdpPortCodeList.findIndex(x=>x.value===schedule.sch_fdp)].port_code:'선택':'선택'
										}}
										onChange={(value)=>onChangeVal(value.value,'sch_fdp')}
										options={fdpPortCodeList}
										onInputChange={fncOnKeyDown}
									/>
								</Col>
								<Col xl="7" className="col-7 pl-1">
									<InputValid 
										type="text"
										name="sch_fdp_name"
										id="sch_fdp_name"
										maxLength="35"
										value={schedule.sch_fdp_name?schedule.sch_fdp_name:''}
										onChange={(e)=>onHandleReturnVal(e, 'sch_fdp_name')}
										onBlur={onPropsReturn}
										validtype="text"
										required={false} 
										feedid="schedule"
									/>
								</Col>
							</Row>
						</Col>
					</FormGroup>
				</Col>
			</Row>
		</>
    );
}