import React,{useEffect,useState} from "react";
import { Row,Col,Card, CardBody, Button, Collapse, UncontrolledTooltip, Table} from "reactstrap";
import CardTitle from "reactstrap/lib/CardTitle";
import FooterWeidong from "components/Footers/FooterWeidong.js";
import axios from "axios";
import { Link } from "react-router-dom";

export default function FindId(props) {
    const{onAlert} = props;

    document.documentElement.classList.remove("nav-open");
    // function that is being called on scroll of the page
    const checkScroll = () => {
      // it takes all the elements that have the .add-animation class on them
      const componentPosition = document.getElementsByClassName("add-animation");
      const scrollPosition = window.pageYOffset;
      for (var i = 0; i < componentPosition.length; i++) {
        var rec =
          componentPosition[i].getBoundingClientRect().top + window.scrollY + 100;
        // when the element with the .add-animation is in the scroll view,
        // the .animated class gets added to it, so it creates a nice fade in animation
        if (scrollPosition + window.innerHeight >= rec) {
          componentPosition[i].classList.add("animated");
          // when the element with the .add-animation is not in the scroll view,
          // the .animated class gets removed from it, so it creates a nice fade out animation
        } else if (scrollPosition + window.innerHeight * 0.8 < rec) {
          componentPosition[i].classList.remove("animated");
        }
      }
    };

    const [phoneAuth, setPhoneAuth] = useState(false); //휴대폰인증을 통한 아이디찾기 창 열기
    const [memberInfoAuth, setMemberInfoAuth] = useState(false); // 본인 정보를 통한 아이디찾기 창 열기
    const [certifyStatus, setCertifyStatus] = useState(false); //본인인증 완료 여부
    const [idList, setIdList] = useState([]); // 유저의 ID목록

    useEffect(() => {
        document.body.classList.add("sr-request-page");
        window.addEventListener("scroll", checkScroll);
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        
        return function cleanup() {
            document.body.classList.remove("sr-request-page");
            window.removeEventListener("scroll", checkScroll);
        };
    });

    useEffect(()=> {
        if(!memberInfoAuth){
            rollback()
        }
    },[memberInfoAuth]);

    useEffect(()=> {
        if(!phoneAuth){
            authRollback()
        }
    },[phoneAuth]);

    const Serti =() => {
        //테스트 
        // certifyFindInfo('이름','01023332230',"phone");
        
        // 반영시 주석해제
        return axios ({
                url:'/auth/sertify_weidong',
                method:'POST',
                data: {}
            }).then(res=>{ 
                var form = document.form2;
                window.open("", "auth_popup", "width=430,height=640,scrollbar=yes");	
                form.target = "auth_popup";
                form.tc.value="kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd";
                form.action = "https://safe.ok-name.co.kr/CommonSvl";
                form.method = "post";
                form.cp_cd.value=res.data.CP_CD;
                form.mdl_tkn.value=res.data.MDL_TKN;
                form.submit();
            }).catch(err => {
                onAlert('error',(String(err)));
            }); 
    }

    window.event_popup = function() {
        if(document.kcbResultForm1.RSLT_CD.value === "B000"){
            certifyFindInfo(document.kcbResultForm1.RSLT_NAME.value,document.kcbResultForm1.TEL_NO.value,'phone');
        } else {
            setCertifyStatus(false);
            onAlert('error',"document.kcbResultForm1.RSLT_MSG.value \n error: 사용자 본인 인증에 실패 하였습니다. 다시 시도해주세요.");
        }
    }

    const certifyFindInfo = (userName, phoneNum, findFlag, pId) => {
		let param = {userName:userName, phoneNum:phoneNum, gubun:findFlag,};
      
		axios ({
			url:"/api/myAccoutFind",
			method:'POST',
			data: param
		}).then(res=>{
			let resArry = res.data;
            setCertifyStatus(true);
            if(Array.isArray(resArry)&&resArry.length>0){
                onAlert('success',"본인 인증이 완료되었습니다.");
                setIdList(resArry);
            }else{
                setIdList([]);
                return false;
            }
		}).catch(err => {
            setCertifyStatus(false);
			if(err.response !== undefined) {
                setIdList([]);
                if(err.response.status === 500) {
                    onAlert('error',"[ERROR]"+err.response.data);
                } else if(err.response.status === 404) {
                    onAlert('error',"입력된 회원정보가 존재하지 않습니다. 회원가입 후 이용해주세요.");
                }else{
                    onAlert('error',"처리 중 오류가 발생하였습니다. 관리자에게 문의하세요.");
                }
			}
		});
	}  

    const rollback = () => {
        setIdList([]);
    }
    const authRollback = () => {
        setCertifyStatus(false);
        setIdList([]);
        window.kcbResultForm1.RSLT_CD.value = null;
        window.kcbResultForm1.RSLT_MSG.value = null;
        window.kcbResultForm1.TEL_NO.value = null;
        window.kcbResultForm1.RSLT_NAME.value = null;
        window.kcbResultForm1.RSLT_BIRTHDAY.value = null;
        window.kcbResultForm1.RSLT_SEX_CD.value = null;

    }

    return(
        <div className="section section-white">
            <form name="form2">
                <input type="hidden" name="tc" />	
                <input type="hidden" name="cp_cd" />	
                <input type="hidden" name="mdl_tkn" />	
                <input type="hidden" name="target_id"/>	
            </form>
            <form name="kcbResultForm1" method="post">
                <input type="hidden" name="RSLT_CD"/>
                <input type="hidden" name="RSLT_MSG"/>
                <input type="hidden" name="TEL_NO"/>
                <input type="hidden" name="RSLT_NAME"/>
                <input type="hidden" name="RSLT_BIRTHDAY" />
                <input type="hidden" name="RSLT_SEX_CD" />
            </form>  	 
            <div className="bg-white page-header page-header-xss">
            </div>
            <Col className="ml-auto mr-auto mt-3" xl="6" lg="7" md="8" sm="9" xs="10">
                <Row>
                    <Col>
                        <Card className="card-raised card-form-horizontal no-transition mb-4" id="card">
                            <CardTitle>
                                <Row>
                                    <Col className="ml-4">
                                        <Link to={{pathname:"/svc/account"}}>
                                            <i id="back" className="fa fa-angle-left fa-2x"/>
                                            <UncontrolledTooltip placement="bottom" target="back">
                                                뒤로가기
                                            </UncontrolledTooltip>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <span style={{fontSize:'20px',fontWeight:'bold'}}>아래 항목으로 아이디를 찾으실 수 있습니다.</span>
                                    </Col>
                                </Row>
                            </CardTitle>
                            <CardBody className="pt-5">
                                <Col>
                                    <Row>
                                        <Col className="text-left pb-3">
                                            <Button block color="primary" onClick={()=> {setPhoneAuth(!phoneAuth); setMemberInfoAuth(false)}}>내 이름으로 가입된 휴대폰 인증</Button>
                                            <Collapse isOpen={phoneAuth}>
                                                {certifyStatus===true?(
                                                        <>
                                                            {idList.length > 0 ?(
                                                                <Col>
                                                                    <Row>
                                                                        <Col className="text-center pt-3">
                                                                            <span>해당 정보로 가입된 계정이 총 {idList.length}건이 있습니다. </span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="text-center pt-3">
                                                                            <Table responsive size="sm" >
                                                                                <thead >
                                                                                    <tr >
                                                                                        <th  style={{fontSize: '14px' ,fontWeight: 'bold'}}>아이디</th>
                                                                                        <th  style={{fontSize: '14px' ,fontWeight: 'bold'}}>가입일자</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>{idList.map((value,index)=>{
                                                                                        return(
                                                                                            <tr key={index}>
                                                                                                <td>{value.USER_ID} </td>
                                                                                                <td>{value.INSERT_DATE}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </tbody>
                                                                            </Table>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            ):(
                                                                <Col>
                                                                    <Row>
                                                                        <Col className="text-center pt-3">
                                                                            <span>해당 정보로 가입된 계정이 존재하지 않습니다.</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="text-center">
                                                                            <Button onClick={()=> authRollback()}>돌아가기</Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            )}   
                                                        </>):(
                                                        <>
                                                            <Col>
                                                                <Row>
                                                                    <Col className="text-center pt-3">
                                                                        <span>회원 가입 인증시 사용된 휴대폰 번호로 아이디를 찾으실 수 있습니다.</span>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="text-center">
                                                                        <Button className="mt-5" onClick={() => Serti()}>본인인증</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </>
                                                       )
                                                    } 
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <div style={{position:'fixed',bottom:'0',width:'100%'}}>
                <FooterWeidong {...props}/>
            </div>
        </div>
    )
}












