// import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
// import Sections from "views/Sections.js";
// import Presentation from "views/Presentation.js";
// import AboutUs from "views/examples/AboutUs.js";
// import AddProduct from "views/examples/AddProduct.js";
// import BlogPost from "views/examples/BlogPost.js";
// import BlogPosts from "views/examples/BlogPosts.js";
// import ContactUs from "views/examples/ContactUs.js";
// import Discover from "views/examples/Discover.js";
// import Ecommerce from "views/examples/Ecommerce.js";
// import Error404 from "views/examples/Error404.js";
// import Error422 from "views/examples/Error422.js";
// import Error500 from "views/errorPage/Error500.js";
// import LandingPage from "views/examples/LandingPage.js";
// import LoginPage from "views/examples/LoginPage.js";
// import ProductPage from "views/examples/ProductPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// import RegisterPage from "views/examples/RegisterPage.js";
// import SearchWithSidebar from "views/examples/SearchWithSidebar.js";
// import Settings from "views/examples/Settings.js";
import BoardMain from "views/board/index"
// import TwitterRedesign from "views/examples/TwitterRedesign.js";
// import Bookings from "views/bookings/bookingReq/index.js";
import BookingList from "views/booking/indexList.js";
// weidong booking
import Booking from "views/booking/index.js";
// import Bookings2 from "views/bookings/bookingReq/index2.js";
// import Bookings3 from "views/bookings3/index.js";
import Dashboard from "views/dashboard/index.js";
// import Map from "views/Map/VworldWeidongMapView.js";
import Map from "views/Map/MapView.js";
import Schedule from "views/schedule/index.js";
import SRNew from "views/sr/request/index.js";
import SRList from "views/sr/srlist/index.js";
import Confirm from "views/confirm/index.js";
import ConfirmList from "views/confirm/indexList.js";
import Bl from "views/bl/index.js";
import BlList from "views/bl/indexList.js";
import CompSectionSetting from "views/user/CompIndex.js";
import UserSetting from "views/user/UserSettingIndex.js";
import UserMessage from "views/user/UserMessageIndex.js";
import PwChange from 'components/User/PwChange.js';
import Sitemap from 'views/sitemap/weidongSitemap.js';
import InvoiceList from "views/invoice/InvoiceIndex";

let routeList = [
	// {name : '메인화면' , path:'/'						,component:WeidongIndex	}
	// ,{name : '메인화면' , path:'/weidongIndex'			,component:WeidongIndex	}
	// ,{name : '메인화면' , path:'/svc/weidongIndex'		,component:WeidongIndex	}
	// {name : 'CERTIFY' , path:'/return_certify'			,component:Certify	}
	{name : 'DASHBOARD' , path:'/svc/dashboard'		,component:Dashboard	}
	,{name : 'SCHEDULE' , path:'/svc/schedule'			,component:Schedule	}
	,{name : 'SR' , path:'/svc/srlist'					,component:SRList	}
	,{name : 'SR' , path:'/svc/srWdfc'					,component:SRNew	}
	,{name : 'LOCATION' , path:'/svc/vslocation'		,component:Map	}
	,{name : 'REQUEST' , path:'/svc/bookingWdfc'		,component:Booking}
	,{name : 'REQUEST' , path:'/svc/bookinglist'		,component:BookingList}
	,{name : 'CONFIRM' , path:'/svc/confirm'			,component:Confirm	}
	,{name : 'CONFIRM' , path:'/svc/confirmList'		,component:ConfirmList	}
	,{name : 'BL' , path:'/svc/bl'						,component:Bl	}
	,{name : 'BL' , path:'/svc/bllist'					,component:BlList	}
	,{name : 'PROFILE' , path:'/svc/usersetting'		,component:UserSetting	}
	,{name : 'USER_MESSAGE' , path:'/svc/usermessage'	,component:UserMessage	}
	,{name : 'SETTING' , path:'/svc/setting'			,component:CompSectionSetting	}
	,{name : 'NOTICE' , path:'/svc/notice'				,component:BoardMain	}
	,{name : 'PASSWORD' , path:'/svc/password'			,component:PwChange	}
	// ,{name : 'ACCOUNT' , path:'/svc/account'			,component:LoginErrorPage}
	// ,{name : 'FINDID' , path:'/svc/findid'				,component:FindId	}
	// ,{name : 'FINDPW' , path:'/svc/findpw'				,component:FindPw	}
	,{name : 'SITEMAP' , path:'/svc/sitemap'			,component:Sitemap	}
	,{name : 'INVOICE' , path:'/svc/invoiceList'		,component:InvoiceList	}
]

export function getMenuList(){
	return routeList
}
export function getMenuName(path){
	let menu = routeList.find(x=> x.path ==path);
	let menuName = menu? menu.name :'';
	return menuName;
}

