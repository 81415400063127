import React from "react";
// import Map           from "components/Map/Minimap.js"
import SeaMap from "components/Map/SeavantageMap";
import WeidongNavbar from "components/Navbars/WeidongNavbar.js";
import WeidongHeader from "components/Headers/WeidongHeader.js";
import FooterWeidong from "components/Footers/FooterWeidong.js";

export default function MapView(props) {
  return (
    <>

        {/* <WeidongNavbar {...props}/> */}
        <WeidongHeader />
        <SeaMap {...props}/>
        <FooterWeidong />
        
    </>
  );
}
