import React,{useState, useEffect} from "react";
import { Link ,useLocation, useHistory} from "react-router-dom";
// nodejs library that concatenates strings
// import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// import axios from "axios";
import queryString from 'query-string';
import { getMenuName } from '../../menu_data';
// reactstrap components
import {
  // Button,
  Collapse,
  DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  // NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
  // Row,Col
} from "reactstrap";
// core components
import WeidingAuth from "components/Navbars/WeidongAuthNavbar.js";
import LoginModal from "components/Modals/Login.js";
import RegisterModal from "components/Modals/Register.js";
import {observer} from 'mobx-react-lite';
const queryStr = queryString.parse(window.location.search);


const WeidongNavbar = observer((props) => {
  const history = useHistory();
  const location = useLocation();
  const {pathname} = location;
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [bodyClick, setBodyClick] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  // const [login, setLogin] = useState(false);
  // const [register, setRegister] = useState(false);
  const [clickMenu, setClickMenu] = useState("");
  const [overMenu, setOverMenu] = useState("");
  const [overBottom, setOverBottom] = useState("");
  const [clickBottom, setClickBottom] = useState("");
  const [errCode,setErrCode] = useState(queryStr&&queryStr.code? queryStr.code: null);
  const {userData, setUserData, login, setLogin, register, setRegister }=props;
  
  useEffect(() => {
    if(pathname){
        const menuName = getMenuName(pathname) ||'';
        if(menuName){
          setClickMenu(`NAV_${menuName}`);
          setClickBottom("4px solid #0098e1");
        }else{
          setClickMenu("");
        }
    }
  }, [window.location.pathname]);

  // 약한 파란색
  useEffect(() => {
    if( overMenu ) {
      setOverBottom("4px solid #9dcbe0");
    }
  },[overMenu]);
  // 클릭했을땐 진한 파란색
  useEffect(() => {
    if( clickMenu ) {
      setClickBottom("4px solid #0098e1");
    }
  },[clickMenu]);
  
  // useEffect(() => {
	//   if(props.clickMenu){
  //     setClickMenu(props.clickMenu)
  //   }
  // },[props.clickMenu]);

  
  useEffect(() => {    
	  if(errCode){setLogin(true)}
  },[errCode]);

  useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 140 || document.body.scrollTop > 140) {
        setNavbarColor("");
      } else if (document.documentElement.scrollTop < 100 || document.body.scrollTop < 100 ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    // window.addEventListener("click", fncOnClick);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
      // window.removeEventListener("click", fncOnClick);
    };
  });

  // const fncOnClick =(e)=>{
  //   let str = e.target.id;
  //   if(str.includes('NAV_')) {
  //         // 연관된 아이디만 적용
  //         setClickMenu(e.target.id);
  //         props.fncClickMenu(str.replace('NAV_',''));
  //   }
  // }
  
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null} 
      <Navbar
        className={"fixed-top pt-0"}
        expand="lg"
        id="navbar-main"
        style={{borderBottom:'1px solid #0098e1',backgroundImage:
        "url(" + require("assets/img/bg_header_wrap.gif") + ")"}}
      >
        <Container>
          <div  className="container padding_custom" style={{top:'0',position:'absolute'}} >
            <WeidingAuth  
            // openLogin={()=>setLogin(!login)}
            openLogin={(e)=>setLogin(e)}
                          openRegister={()=>setRegister(!register)}
                          userData={userData}
                          setUserData={setUserData}
                          {...props}
                          />
          </div>
          <div className="navbar-translate" style={{marginTop:'30px'}} >
            <NavbarBrand className="p-0 m-0" id="navbar-brand" to="/svc/weidongIndex" tag={Link} >
              <img alt="logo" src={require("assets/img/logo.gif")}/>
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              WEIDONG FERRY
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen} style={{paddingTop:'40px'}}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar  className="mb-0"
                style={{borderBottom:
                  "NAV_DASHBOARD"===overMenu?overBottom:"NAV_DASHBOARD"===clickMenu?clickBottom:'4px solid transparent'
                  }}>
                <DropdownToggle nav to="/svc/dashboard" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onMouseOver={()=>setOverMenu("NAV_DASHBOARD")}
                  onMouseOut={()=>setOverMenu("")}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}// onClick={(e)=>fncOnClick(e)}
                  id='NAV_DASHBOARD'
                  >
                    DASHBOARD
                </DropdownToggle>
              </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar 
                style={{borderBottom:
                  "NAV_SCHEDULE"===overMenu?overBottom:"NAV_SCHEDULE"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/schedule" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}
                  onMouseOver={()=>setOverMenu("NAV_SCHEDULE")}
                  onMouseOut={()=>setOverMenu("")}
                  id='NAV_SCHEDULE'
                  >
                  SCHEDULE
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar
                style={{borderBottom:
                  "NAV_REQUEST"===overMenu?overBottom:"NAV_REQUEST"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/bookinglist" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}
                  onMouseOver={()=>setOverMenu("NAV_REQUEST")}
                  onMouseOut={()=>setOverMenu("")}
                  id="NAV_REQUEST"
                  >
                REQUEST
                </DropdownToggle>
              </UncontrolledDropdown> 
              <UncontrolledDropdown nav inNavbar
                style={{borderBottom:
                  "NAV_CONFIRM"===overMenu?overBottom:"NAV_CONFIRM"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/confirmList" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}
                  onMouseOver={()=>setOverMenu("NAV_CONFIRM")}
                  onMouseOut={()=>setOverMenu("")}
                  id='NAV_CONFIRM'
                  >
                CONFIRM
                </DropdownToggle>
              </UncontrolledDropdown> 
              <UncontrolledDropdown nav inNavbar
                style={{borderBottom:
                  "NAV_SR"===overMenu?overBottom:"NAV_SR"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/srlist" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}// onClick={(e)=>fncOnClick(e)}
                  onMouseOver={()=>setOverMenu("NAV_SR")}
                  onMouseOut={()=>setOverMenu("")}
                  id='NAV_SR'
                  >
                SR
                </DropdownToggle>
              </UncontrolledDropdown>   
              <UncontrolledDropdown nav inNavbar
                style={{borderBottom:
                  "NAV_BL"===overMenu?overBottom:"NAV_BL"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/bllist" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}// onClick={(e)=>fncOnClick(e)}
                  onMouseOver={()=>setOverMenu("NAV_BL")}
                  onMouseOut={()=>{setOverMenu("")}}
                  id='NAV_BL'
                  >
                BL
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar
                style={{borderBottom:
                  "NAV_INVOICE"===overMenu?overBottom:"NAV_INVOICE"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/invoiceList" tag={Link}
                  style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}// onClick={(e)=>fncOnClick(e)}
                  onMouseOver={()=>setOverMenu("NAV_INVOICE")}
                  onMouseOut={()=>{setOverMenu("")}}
                  id='NAV_INVOICE'
                  >
                INVOICE
                </DropdownToggle>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar
                style={{borderBottom:
                  "NAV_LOCATION"===overMenu?overBottom:"NAV_LOCATION"===clickMenu?clickBottom:'4px solid transparent'
                }}>
                <DropdownToggle nav to="/svc/vslocation" tag={Link} style={{fontSize:'15px',color:'#444444'}}
                  onClick={(e)=>document.documentElement.classList.remove("nav-open")}// onClick={(e)=>fncOnClick(e)}
                  onMouseOver={()=>setOverMenu("NAV_LOCATION")}
                  onMouseOut={()=>setOverMenu("")}
                  id='NAV_LOCATION'
                  >
                  LOCATION
                </DropdownToggle>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/*login modal*/}
      {/* <LoginModal 
          openLogin={login}
          setOpenRegister={(e)=>{setLogin(false);setRegister(true)}}
          setOpenLogin={(e)=>{setLogin(e);if(props.errcode){props.history.push("/");props.clearCode();}}}
          //onSetIsAuthenticated ={(state)=>props.onSetIsAuthenticated(state)}
          //onLogin={(data)=>isLogin(data)} 
          {...props}
        /> */}
      {/*login modal*/}
      {/* <RegisterModal 
          openRegister={register}
          setOpenRegister={(e)=>setRegister(e)}
          openLogin={() => setLogin(true)}
      /> */}
      <LoginModal 
					openLogin={login}
					setOpenRegister={(e)=>{setLogin(false);setRegister(true)}}
					setOpenLogin={(e)=>{setLogin(e);
            if(errCode){
              history.push("/");
              setErrCode("")}}}
					//  validation={valid} onAlert={onAlert} onNotiAlert={onNotiAlert} 
					{...props}
						/>
				<RegisterModal 
					openRegister={register}
					setOpenRegister={(e)=>setRegister(e)}
					openLogin={() => setLogin(true)}
					// validation={valid} onAlert={onAlert} onNotiAlert={onNotiAlert} 
				/>
    </>
  );
}
)

export default WeidongNavbar;
