import {makeAutoObservable, observable, computed, action, autorun, configure, flow} from "mobx"
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
configure({enforceActions:'never'});

let timer;
function countdown(e) {
    let expTime = e.exp;
    
    
    let currentTime =  Math.floor(Date.now()/1000) ;
    
    let leftTime = expTime - currentTime ;
    
    //  if (leftTime < 1) {                 
    //     userStore.logout();
    //     clearInterval(timer);
    //     return
    // }

    let hours = Math.floor(leftTime / 3600 % 24)
    let minutes = Math.floor(leftTime / 60 % 60)
    let seconds = Math.floor(leftTime % 60)
   
    if (expTime) {
        timerStore.hoursSpan = (hours < 10? hours < 1? '00': `0${hours}`: hours)
        timerStore.minutesSpan = (minutes < 10? minutes < 1? '00': `0${minutes}`: minutes)
        timerStore.secondsSpan = (seconds < 10? seconds < 1? '00': `0${seconds}`: seconds)
    }
    
    timerStore.timer = ` ${hours?`${timerStore.hoursSpan}:`:''}${timerStore.minutesSpan}:${timerStore.secondsSpan} `
    timerStore.timerKo = ` ${minutes>0?`${timerStore.minutesSpan}분`:''} ${timerStore.secondsSpan}초 `
    timerStore.leftTime =leftTime
}

class UserStore {
    accessToken = window.localStorage.getItem('LocalUser')?JSON.parse(window.localStorage.getItem('LocalUser')).accessToken : null;
    user =  window.localStorage.getItem('LocalUser')?JSON.parse(window.localStorage.getItem('LocalUser')) :null

    loading = false;
    admin= window.localStorage.getItem('admin')?JSON.parse(window.localStorage.getItem('admin')) :false
    constructor() {
        makeAutoObservable(this, {
            accessToken: observable,
            user: observable,
            isAuth:observable,
            loading:observable,
            admin:observable,
            logout: action,
            sessionIn:action,
            sessionOut:action,
            verifyUser:action,
            getUser:computed,
        },{autoBind:true})
    }

    logout() {
        if(!userStore.admin){
            let userData = userStore.user|| window.localStorage.getItem('LocalUser');
            if(userData){
            axios
                .post("/auth/logout",{user:userData})
                .then(res => {
                    resetUser();
                    window.location.replace('/');
                    // console.log('홈으로', TimerStore.leftTime)
                    if(userStore.loading){userStore.loading=false}
                })
                .catch(err => {
                    resetUser();
                });
            }
        }else{
            userStore.sessionOut()
        }
    }
    
    verifyUser =async()=> {
        try{
            userStore.loading=true;
            const res  = await  axios.get(`/auth/verify?timestamp=${new Date().getTime()}`)
                if (res.data) {
                    const result = res.data.user;
                    if(result){
                        if(!userStore.accessToken||!_.isEqual(userStore.user,result)){
                            userStore.user = result;
                            userStore.accessToken= res.data.user.accessToken;
                            window.localStorage.setItem('LocalUser',JSON.stringify(result))
                            timerStore.getTimer(userStore.getUser);
                        }else if(userStore.accessToken&&_.isEqual(userStore.user,result)){
                            timerStore.getTimer(userStore.getUser);
                        }else{
                            userStore.logout();
                        }
                    }
                    // const result = res.data.user;
                    // if(userStore.accessToken==null||userStore.accessToken !== res.data.user.accessToken){
                    // userStore.user = res.data.user;
                    // userStore.accessToken= res.data.user.accessToken;
                    // window.localStorage.setItem('LocalUser',JSON.stringify(res.data.user));
                    // timerStore.getTimer(userStore.getUser);
                    // }else if(userStore.accessToken!==null&&userStore.accessToken == res.data.user.accessToken){                        
                    //     timerStore.getTimer(userStore.getUser)
                    // }else{
                    //     userStore.logout();
                    // }
                } else {
                    userStore.logout()
                    userStore.user=null;
                    userStore.loading=false;
                }
        }catch(e){
            console.log('error verify >>',e.response);
            try{
                if (e.response&&e.response.status === 419&& userStore.user){
                    // userStore.logout();
                    userStore.loading=false;
                    userStore.isAuth=419;
                    // resetUser();
                }
                if (e.response&&e.response.status === 401&& userStore.user){
                    userStore.loading=true;
                    userStore.isAuth=401;
                }
            }catch(e){
                console.log('error verify>>',e);
            };
        }finally{
            userStore.loading=false;
        }
    }

    sessionIn(){
        userStore.admin= userStore.user
        window.localStorage.setItem('admin',window.localStorage.getItem('LocalUser'))
        window.location.href = "/";
    }

    sessionOut(){
        // console.log('out')
        axios
            .post("/auth/logout",{sessionOut:'admin'})
            .then(res => {
                userStore.user = userStore.admin;
                userStore.admin=false;
                window.localStorage.removeItem('LocalUser');
                // window.localStorage.removeItem('autoRenew');
                window.localStorage.removeItem('admin');
                clearInterval(timer);
                window.location.href = "/";
            })
            .catch(err => {
                console.log(err);
            });
    }
    *sessionExtension(){
        // console.log('sessionExtension ')
        try{
            const res  = yield  axios.get(`/auth/sessionExtension?timestamp=${new Date().getTime()}`)
            if (res.data) {
                // console.log(res.data,this.accessToken)
                if(this.accessToken !== res.data.accessToken){
                    let newU = {...userStore.getUser, accessToken: res.data.accessToken , exp: res.data.exp};
                    window.localStorage.setItem('LocalUser',JSON.stringify(newU))
                    timerStore.getTimer(newU)
                }
                return true;
            }else {
                // console.log('sessionExtension fail>>')
                return false;
            }
        }catch(e){
            console.log(e);
            return false;
        }
    }
    get getUser() {
        return this.user
    }
    set setUser(user) {
        this.user = user;
        // console.log('>>setUser',user)
        // window.localStorage.setItem('LocalUser', JSON.stringify(this.user));
    }
    get getLoading(){
        return this.loading
    }
    set setLoading(e){
        this.loading = e ;
        return this.loading
    }
}

const resetUser = ()=>{
    userStore.user = null;
    window.localStorage.removeItem('LocalUser');
    window.localStorage.removeItem('admin')
    clearInterval(timer);
}

class TimerStore {
    user = userStore.getUser
    hoursSpan= '--'
    minutesSpan= '--'
    secondsSpan= '--'
    // autoRenew= JSON.parse(window.localStorage.getItem('autoRenew')) || true
    timer= ' -- : -- '
    timerKo= ''
    leftTime= 3600

    constructor() {
        makeAutoObservable(this, {
            user: observable,
            hoursSpan: observable,
            minutesSpan: observable,
            secondsSpan: observable,
            // autoRenew: observable,
            timer:observable,
            leftTime:observable,
            getTimer:action,
            // getAutoRenew:computed
            getleftTime:computed
        })
    }

    // get getAutoRenew() {
    //     return this.autoRenew;
    // }
    // set setAutoRenew(e) {
    //     //    console.log('setAutoLogin',e )
    //     window.localStorage.setItem('autoRenew', JSON.stringify(e))
    //     this.autoRenew = e
    // }
    get getleftTime() {
        return this.leftTime;
    }

    getTimer(e){
        clearInterval(timer);
        if (e) {
            timer = setInterval(action(countdown), 1000, e)
        } else {
            clearInterval(timer);
        }
    }
}

const userStore =  new UserStore();
const timerStore =  new TimerStore();
autorun(() => {
     userStore.verifyUser()
})

export {userStore, timerStore};
