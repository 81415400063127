import React,{useEffect,useState} from "react";
import Moment from 'moment';
import axios from "axios";
import {
   Row, Col, Container
  } from "reactstrap";
// import Notice from "components/Notice/NoticeDetail"
import { Link } from "react-router-dom";
import NoticeModal from "components/Notice/NoticeModal.js";

export default function NoticeNew(props){
  const {site} = props;
  const [noticeList, setNoticeList] = useState([]);
  const [noticePopupArray, setNoticePopupArray] = useState([]);

  // useEffect(()=> {
  //     if(site) {
  //         getMainNotice(site.line_code);  
  //     }
  // },[site])
  useEffect(()=> {
    getMainNotice();  
  },[])

  const getMainNotice = () => {
      axios.post("/api/notice",{service:'WDFC',  url:'main'}).then(res=> {
        if(res.statusText ==="OK") {
          let result = res.data;
          if(result.length > 0) {
              //팝업은 전체 대상으로 담고
              let noticePopupList =[];
              for(var i=0; i < result.length; i++) {
                  if(result[i].pop_yn === 'Y' && !handleStorage.getStorage(result[i].board_id)){
                      noticePopupList.push(result[i]);
                  }
              }
              setNoticePopupArray(noticePopupList);
              //메인에 보여줄건 4개로 제한
              result = result.filter((el, ind, arr)=> ind <4)
              console.log(result)
              setNoticeList(result);
          }else {
              setNoticeList([])
          }
        }else {
            setNoticeList([])
        }
      })
  }

  var handleStorage = {
      // 스토리지 읽어오기
      getStorage: function (name) {
      var now = new Date();
      now = now.setTime(now.getTime());
      // 현재 시각과 스토리지에 저장된 시각을 각각 비교하여
      // 시간이 남아 있으면 true, 아니면 false 리턴
      return parseInt(localStorage.getItem(name)) > now
      }
  };
  
  return (
    <>
       <div style ={{margin:'2% auto',width:'100%'}}>
        <Container style ={{minHeight:'80px'}}>
          <Col md={{offset:2, size:8}} sm='12'>
            <Row style={{marginBottom:'8px'}}>
              <Col sm="2" className ="text-primary"> <h4>NOTICE</h4>
              </Col>
              <Col sm="10" style={{textAlignLast:'end'}}>
                <Link to={{pathname:'/svc/notice'}}>
                  <i id="noti" style={{cursor:'pointer', color:'#51bcda'}} className="fa fa-plus-square-o fa-2x" aria-hidden="true"/>
                </Link>
              </Col>
            </Row>
            {noticeList.length > 0 ? noticeList.map((value,index) => {
                    return(
                      <Row key={index} style={{marginBottom:'2px'
                      ,color: value.now_top_flag=='Y'? '#4183c4': ''
                      ,fontWeight: value.now_top_flag=='Y'? '700': ''}} >
                            <Col md="9" sm="7" className ='notice-row' > 
                              <Link    className ='notice-row' 
                                  to={{
                                  pathname : `/svc/notice`,
                                  state : {param : value}}}>
                                <div style={{width:'100%'
                                ,color: value.now_top_flag=='Y'? '#4183c4': ''
                                ,fontWeight: value.now_top_flag=='Y'? '700': ''
                              }}>{value.title}
                            </div>
                              </Link>
                            </Col>
                            <Col md="3" sm="5" className ="text-right">{Moment(value.insert_date).format('YYYY-MM-DD')}</Col>
                      </Row>
                    )
                })
            :(
              <Row className="text-muted" style={{justifyContent:'center'}}>
                  최근 게시물이 존재하지 않습니다.
              </Row>
            )}
              {noticePopupArray && noticePopupArray.length>0 &&
                noticePopupArray.map((data,index) =>
                <NoticeModal key ={index} noticeIndex={index} noticeData={data}/>
              )}
          </Col>
        </Container>
      </div>
    </>
  );
}


