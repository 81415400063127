import React, { useState, useEffect } from 'react';
import {Row, Col, FormGroup,Label,Input, Card, CardTitle, CardSubtitle, CardBody, CardFooter, Button, Spinner} from "reactstrap";
import Select from 'react-select'
import Moment, { invalid } from 'moment';
import axios from "axios";
import Filesaver from 'file-saver';
import {CustomDatePicker,CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput';
// import { Link } from "react-router-dom";
import * as validation from 'components/common/validation.js';
import TranstkReg from 'components/Modals/TranstkReg.js';
import TranstkDownload from 'components/Modals/TranstkDownload.js';
import {useHistory} from 'react-router-dom';
// import { DATE_MSG } from 'components/common/validation';
export default function InvoiceList (props) {
    // let history = useHistory();
    const [data, setData] = useState([]);
    const [totCnt, setTotCnt] = useState(0);
    const [modalMsg, setModalMsg] = useState(null);
    const [lineCodeList ,setLineCodeList] = useState([]);
    const [lineCode, setLineCode] = useState('');
    // 전송일
    const [condReqStdDate, setCondReqStdDate] = useState(Moment(new Date()));
    const [condReqEndDate, setCondReqEndDate] = useState(Moment(new Date()));
    // 발급일
    const [condAppStdDate, setCondAppStdDate] = useState();
    const [condAppEndDate, setCondAppEndStdDate] = useState();
    // INVOICE NO
    const [condInvoiceNo, setCondInvoiceNo] = useState("");
    // 선사담당자
    const [condAppUser, setCondAppUser] = useState("");
    // M-B/L
    const [condBlNo, setCondBlNo] = useState("");
    // type
    const [condInvoiceReqType, setCondInvoiceReqType] = useState("");
    // 상태
    const [condStatus, setCondStatus] = useState("");

    //이체증첨부여부
    const [transtkAddYN, setTranstkAddYN] = useState('N');
    //modal
    const [transtk, setTranstk] = useState(false);
    const [transtkDownload, setTranstkDownload] = useState(false);
    const [modalInvoiceSid, setModalInvoiceSid] = useState('');
    const [loading,setLoading] = useState(false);

    const {userData}=props;
    const reqTypeList = [
        {value:'DEP', label:'DEM'},
        {value:'SUR', label:'SUR'},
        {value:'CFS', label:'CFS'},
        {value:'VGM', label:'VGM'},
        {value:'CUS', label:'CUSTOMS'},
        {value:'CER', label:'CERTI'},
        {value:'CN', label:'CN'},
        {value:'BL', label:'B/L'},
        {value:'SUM', label:'SUM'},
        {value:'OTH', label:'OTHER'},
        {value:'DO', label:'D/O'},
    ];
    const statusList = [
        {value:'1', label:'전송'},
        {value:'3', label:'발급'},
        {value:'4', label:'취소'},
        {value:'5', label:'이체'},
        {value:'6', label:'입금'},
        {value:'B', label:'열람'},
        {value:'F', label:'외상거래'},
        {value:'E', label:'보류'},
    ];
    
    useEffect(()=> {
        if(!props.userData){
            props.onAlert("error",validation.NOTLOGIN_MSG);
            setLoading(false);
            return 
        }
        if(lineCode) {
            selectLineCodeList();
            setLoading(true);
            //이체증첨부여부
            axios.post("/shipper/taxSendCheck",{lineCode:lineCode})
            .then(res=>{
                if(res.data&&res.data.length>0){
                    setTranstkAddYN(res.data[0].TRANSTK_ADD_YN);
                }
            }).catch(err => {
                props.onAlert("error","오류가 발생했습니다. 잠시후 다시 시도해주세요.");
                return false;
            }).finally(()=>{
                setLoading(false);
            });
        }
    },[lineCode]);

    useEffect(()=> {
        if(props.site){
            setLineCode(props.site.line_code);
        }else {
            setLineCode('WDFC');
        }
    },[props.site]);

    useEffect(() => {
        if(lineCode) {
            onSubmit();
        }
    },[lineCodeList])

    const onSubmit = () => {
        if(userData){
            setLoading(true);
        	//전송시작일,끝일 유효성 체크
            const stdDateCheck=condReqStdDate?Moment(condReqStdDate).format('YYYY-MM-DD'):'';
            const endDateCheck=condReqStdDate?Moment(condReqEndDate).format('YYYY-MM-DD'):'';
            if(condReqStdDate?stdDateCheck==='Invalid date':true){
                props.onAlert('danger','전송일을 입력해주세요.');
                return setLoading(false);
            }
            if(condReqEndDate?endDateCheck==='Invalid date':true){
                props.onAlert('danger','전송일을 입력해주세요.');
                return setLoading(false);
            }
            if(Moment(condReqEndDate).diff(Moment(condReqStdDate),'days')>30){
                props.onAlert('danger','전송일을 30일 이내로 입력해주세요.');
                return setLoading(false);
            }
            
            if(Moment(condReqStdDate).isAfter(condReqEndDate)){
                props.onAlert("danger",validation.DATE_MSG);
                return setLoading(false);
            }
            axios.post("/shipper/selectOubPdfInvoiceList",{
                userNo: userData?userData:'',
                condReqStdDate: condReqStdDate?Moment(condReqStdDate).format('YYYYMMDD'):null,
                condReqEndDate: condReqEndDate?Moment(condReqEndDate).format('YYYYMMDD'):null,
                condAppStdDate: condAppStdDate?Moment(condAppStdDate).format('YYYYMMDD'):null,
                condAppEndDate: condAppEndDate?Moment(condAppEndDate).format('YYYYMMDD'):null,
                condInvoiceNo: condInvoiceNo,
                condAppUser: condAppUser,
                condBlNo: condBlNo,
                condInvoiceReqType: condInvoiceReqType,
                condStatus: condStatus,
                // condCarrierCode: lineCode,
                condUserType: 'H',
                condCarrierCode: lineCode?lineCodeList.find(f=>f.line_code === lineCode).plism_line_code:null,
            }).then(res => {
                // if(res.statusText==="OK") {
                    if(res.data.length > 0) {
                        setTotCnt(res.data[0].tot_cnt);
                        setData(res.data);
                        props.onAlert("success",validation.OK_CHECK_MSG);
                    }else {
                        props.onAlert("danger",validation.FAIL_CHECK_MSG);
                        setData([]);
                        setTotCnt(0);
                    }
                // }
            }).catch(err => {
                props.onAlert("error","오류가 발생했습니다. 잠시후 다시 시도해주세요.");
                // return false;
            }).finally(()=>{
                setLoading(false);
            });
        }
    }
  
    const selectLineCodeList = () => {
        if(props.userData){
            axios.post(
                "/shipper/selectLineCodeList"
                ,{
                    params:{
                        lineCode:lineCode
                    }
                }
            ).then(res=>{
                setLineCodeList(res.data);
            });
        }
    }

    const successSummit = () => {
        if(lineCode) {
            onSubmit();
        }
    }

    
    function TableList (props) {
        
        const setTranstkModal = (INVOICE_SID) => {
            props.setTranstk()
            props.setModalInvoiceSid(INVOICE_SID);
        }
        const setTranstkDownloadModal = () => {
            //console.log(propsData.INVOICE_SID,propsData.TRANSTK_FILE,propsData.TRANSTK_FILE2,propsData.TRANSTK_FILE3);
            // let fileList=[];
            // if(propsData.TRANSTK_FILE)fileList.push(propsData.TRANSTK_FILE);
            // if(propsData.TRANSTK_FILE2)fileList.push(propsData.TRANSTK_FILE2);
            // if(propsData.TRANSTK_FILE3)fileList.push(propsData.TRANSTK_FILE3);
            // for(let i=1;i<=fileList.length;i++){
            //     axios.post("/shipper/downloadbyURL",{type : 'transtkDownload' , file : propsData.INVOICE_SID, seq:i},{responseType:'blob',})
            //     .then(res => {
            //         let blob = new Blob([res.data], {type:'application/pdf'})
            //         Filesaver.saveAs(blob,fileList[i]);
            //     }).catch(err => {
            //         console.log(err)
            //         props.onAlert("danger",'다운로드에 실패하였습니다. 관리자 문의바랍니다.'+i);
            //     })
            // }

            //팝업에서 다운로드
            props.setTranstkDownload()
            props.setModalInvoiceSid(propsData.INVOICE_SID);
        }
        const {propsData,indexs} = props;
        return (
        <div style={{width:"3970px"}}>
            <Row  className="table_tb table_tb--hover border-bottom pb-3">
                <Row className='table_tb--rows' >
                    <div className="text-center" style={{ width:'120px'}}>{propsData.STATUS_NM}</div>
                    <div className="text-center" style={{ width:'120px'}}>{propsData.INVOICE_TYPE_NM}</div>
                    <div className="text-center" style={{ width:'120px'}}>{propsData.CARRIER_CODE}</div>
                    <div className="text-center" style={{ width:'200px'}}>
                        
                        {( propsData.INVOICE_ADD_PATH && propsData.INVOICE_ADD_FILE)?
                            <Button
                            className="btn-link"
                            color="primary"
                            type="button"
                            onClick={()=>onDownLoad('invoice',propsData.INVOICE_SID,propsData.INVOICE_ADD_FILE)}
                            size="sm"
                            > {propsData.LINE_INVOICE_NO}
                            </Button>: propsData.LINE_INVOICE_NO}
                    </div>
                    <div className="text-center" style={{ width:'180px'}}>{propsData.APP_USER}</div>
                    <div className="text-center" style={{ width:'200px'}}>{propsData.MRN}</div>
                    <div className="text-center" style={{ width:'250px'}}>{propsData.BL_NO}</div>
                    <div className="text-center" style={{ width:'200px'}}>{propsData.SHIPPER_NAME}</div>
                    <div className="text-center" style={{ width:'200px'}}>{propsData.SHIPPER_REG_NO}</div>
                    <div className="text-center" style={{ width:'180px'}}>{propsData.SHIPPER_USER_NAME}</div>
                    <div className="text-center" style={{ width:'200px'}}>{propsData.SHIPPER_USER_TEL}</div>
                    <div className="text-center" style={{ width:'250px'}}>{propsData.VSL_VOY}</div>
                    <div className="text-center" style={{ width:'200px'}}>{propsData.POL_NAME}</div>
                    <div className="text-center" style={{ width:'200px'}}>{propsData.POD_NAME}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.INSERT_DATE}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.APP_DATE?(
                    														(propsData.APP_DATE.length===8)?Moment(propsData.APP_DATE).format('YYYY-MM-DD')
                                                                            :(propsData.APP_DATE.length===12)?Moment(propsData.APP_DATE.substr(0,8)).format('YYYY-MM-DD')+' '+Moment(propsData.APP_DATE.substr(8,12)).format('hh:mm')
                                                                            :propsData.APP_DATE):''}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.UPDATE_DATE}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.CHARGE_KRW?propsData.CHARGE_KRW.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):''}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.CHARGE_FOREIGN?propsData.CHARGE_FOREIGN.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):''}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.PAY_YN}</div>
                    <div className="text-center" style={{ width:'150px'}}>
                        {( userData.klnet_id&&userData.klnet_id==='KLTEST01'&&propsData.TRANSTK_ADD_YN==='N')? 
                            <Button
                            className="btn-link"
                            color="primary"
                            type="button"
                            onClick={()=>setTranstkModal(propsData.INVOICE_SID)}
                            size="sm"
                            > {propsData.TRANSTK_ADD_YN}
                            </Button>
                            :( userData.klnet_id&&userData.klnet_id==='KLTEST01'&&propsData.TRANSTK_ADD_YN==='Y')?
                            <Button
                            className="btn-link"
                            color="primary"
                            type="button"
                            onClick={()=>setTranstkDownloadModal()}
                            size="sm"
                            > {propsData.TRANSTK_ADD_YN}
                            </Button>
                            :( propsData.TRANSTK_ADD_YN==='N'&&transtkAddYN==='Y')? 
                            <Button
                            className="btn-link"
                            color="primary"
                            type="button"
                            onClick={()=>setTranstkModal(propsData.INVOICE_SID)}
                            size="sm"
                            > {propsData.TRANSTK_ADD_YN}
                            </Button>
                            :(propsData.TRANSTK_ADD_YN==='Y')?
                            <Button
                            className="btn-link"
                            color="primary"
                            type="button"
                            onClick={()=>setTranstkDownloadModal()}
                            size="sm"
                            > {propsData.TRANSTK_ADD_YN}
                            </Button>
                            :propsData.TRANSTK_ADD_YN}
                    
                    </div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.REQ_REMARK}</div>
                    <div className="text-center" style={{ width:'150px'}}>{propsData.LINE_INV_REMARK}</div>
                </Row>
            </Row>
        </div>
        )
    }
  
  const onDownLoad =(type, sid,fileName)=> {
    // console.log('download', type, sid,fileName);
    axios.post("/shipper/downloadbyURL",{type : type , file : sid,},{responseType:'blob',})
    .then(res => {
        let blob = new Blob([res.data], {type:'application/pdf'})
        Filesaver.saveAs(blob,fileName);
    }).catch(err => {
        console.log(err)
        props.onAlert("danger",'다운로드에 실패하였습니다. 관리자 문의바랍니다.');
    })
    
}
  
    return (
        <>
        {modalMsg}
            {/* var colWidths = ['xs', 'sm', 'md', 'lg', 'xl']; */}
            <Col className="ml-auto mr-auto mt-4" sm="11">               
                <Card className="card-raised card-form-horizontal no-transition mb-4" id="card">             
                    <CardTitle>
                        <div className='search_option'>
                            <Col xl='2' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        LINE
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                            className="customSelect "
                                            name="lineCodeList"
                                            value={{
                                                value:lineCode?lineCode:'',
                                                label:lineCode?lineCode:'선택'
                                            }}
                                            // onChange={(value)=>value?setSchVesselName(value.vessel_name):setSchVesselName('')}
                                            options={lineCodeList}
                                            isClearable={lineCode?true:false}
                                        />
                                    </Col>
                                </FormGroup> 
                            </Col> 
                            <Col xl='4' className='search_option--calendar'>
                                    <FormGroup row>                               
                                    <Label className='search_option--calendar--text'>전송일</Label>                       
                                    <Col>
                                        <CustomDatePickerInput
                                            id="sendStartDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            value={condReqStdDate}
                                            onChange={(date)=>setCondReqStdDate(date)} 
                                            {...props}/>
                                    </Col>
                                    <div className='search_option--calendar--text'>
                                        ~ 
                                    </div>
                                    <Col>
                                        <CustomDatePickerInput
                                            id="sendEndDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            value={condReqEndDate}
                                            onChange={(date)=>setCondReqEndDate(date)}
                                            {...props}/>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        INVOICE NO
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Input                                 
                                            type="text" 
                                            id="bknum"
                                            placeholder=""
                                            maxLength="50"
                                            value={condInvoiceNo}
                                            onChange={(e)=>setCondInvoiceNo(e.target.value)}/>
                                    </Col>                  
                                </FormGroup>
                            </Col>
                            <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        선사담당자
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Input                                 
                                            type="text" 
                                            id="shipperUser"
                                            placeholder=""
                                            maxLength="50"
                                            value={condAppUser}
                                            onChange={(e)=>setCondAppUser(e.target.value)}/>
                                    </Col>
                                </FormGroup> 
                            </Col> 
                            
                        </div>
                        <div className='search_option'>
                            <Col xl='2' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        M-B/L
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Input                                 
                                            type="text" 
                                            id="txtMblNo"
                                            placeholder=""
                                            maxLength="50"
                                            value={condBlNo}
                                            onChange={(e)=>setCondBlNo(e.target.value)}/>
                                    </Col>
                                </FormGroup> 
                            </Col> 
                            <Col xl='4' className='search_option--calendar'>
                                    <FormGroup row>                               
                                    <Label className='search_option--calendar--text'>발급일</Label>                       
                                    <Col>
                                        <CustomDatePickerInput
                                            id="startDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            value={condAppStdDate}
                                            onChange={(date)=>setCondAppStdDate(date)}
                                            placeholder={""}
                                            {...props}/>
                                    </Col>
                                    <div className='search_option--calendar--text'>
                                        ~ 
                                    </div>
                                    <Col>
                                        <CustomDatePickerInput
                                            id="endDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            value={condAppEndDate}
                                            onChange={(date)=>setCondAppEndStdDate(date)}
                                            placeholder={""}
                                            {...props}/>
                                    </Col>
                                </FormGroup>
                            </Col>
                            
                            <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        TYPE
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                            className="customSelect "
                                            name="sch_vessel_name"
                                            value={{
                                                value:condInvoiceReqType?condInvoiceReqType:'',
                                                label:condInvoiceReqType?reqTypeList.find(f=>f.value === condInvoiceReqType).label:'선택'
                                            }}
                                            onChange={(v)=>v?setCondInvoiceReqType(v.value):setCondInvoiceReqType('')}
                                            options={reqTypeList}
                                            isClearable={condInvoiceReqType?true:false}
                                        />
                                    </Col>
                                </FormGroup> 
                            </Col> 
                            <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        상태
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                            className="customSelect "
                                            name="sch_vessel_name"
                                            value={{
                                                value:condStatus?condStatus:'',
                                                label:condStatus?statusList.find(f=>f.value === condStatus).label:'선택'
                                            }}
                                            onChange={(value)=>value?setCondStatus(value.value):setCondStatus('')}
                                            options={statusList}
                                            isClearable={condStatus?true:false}
                                        />
                                    </Col>
                                </FormGroup> 
                            </Col> 
                        </div>  
                    </CardTitle>
                    <div className='result_option'>
                        <CardSubtitle className="result_count text-left">                     
                            <span>[ Data Count: {data.length}건 / {totCnt}건 ]</span>                          
                        </CardSubtitle>
                        <div className='search_option--new'>
                            <CardSubtitle style={{paddingTop:'20px', paddingRight:'15px', color: '#2623e7', fontWeight:'bold'}}>
                                <span>※ 인보이스가 복수로 조회될 경우, 가장 상단의 인보이스가 최신 인보이스입니다.</span>
                            </CardSubtitle>
                            <Button                          
                                color="info" 
                                className='search_option--search' 
                                onClick={()=>onSubmit()}
                                disabled = {!loading?false : true}
                                style ={{width :'90px'}}
                                >
                                {!loading?'SEARCH'
                                :<Spinner size = 'sm'>Loading ...</Spinner>
                                }
                            </Button>    
                        </div>
                    </div>
                    <CardBody className="result_table" >
                        <div style={{ height:"300px",overflow: 'scroll'}}>
                            <Row className="table_th bg-light border-top" style={{width:"3970px"}}>
                                <div className="table_th--text" style={{ width:'120px'}}>상태</div>
                                <div className="table_th--text" style={{ width:'120px'}}>TYPE</div>
                                <div className="table_th--text" style={{ width:'120px'}}>LINE</div>
                                <div className="table_th--text" style={{ width:'200px'}}>선사 INVOICE NO</div>
                                <div className="table_th--text" style={{ width:'180px'}}>선사담당자</div>
                                <div className="table_th--text" style={{ width:'200px'}}>MRN</div>
                                <div className="table_th--text" style={{ width:'250px'}}>MBL NO</div>
                                <div className="table_th--text" style={{ width:'200px'}}>상호명</div>
                                <div className="table_th--text" style={{ width:'200px'}}>사업자번호</div>
                                <div className="table_th--text" style={{ width:'180px'}}>화주담당자</div>
                                <div className="table_th--text" style={{ width:'200px'}}>화주연락처</div>
                                <div className="table_th--text" style={{ width:'250px'}}>VSL/VOY</div>
                                <div className="table_th--text" style={{ width:'200px'}}>POL</div>
                                <div className="table_th--text" style={{ width:'200px'}}>POD</div>
                                <div className="table_th--text" style={{ width:'150px'}}>전송일</div>
                                <div className="table_th--text" style={{ width:'150px'}}>발급일</div>
                                <div className="table_th--text" style={{ width:'150px'}}>상태변경일</div>
                                <div className="table_th--text" style={{ width:'150px'}}>금액(KRW)</div>
                                <div className="table_th--text" style={{ width:'150px'}}>금액(USD)</div>
                                <div className="table_th--text" style={{ width:'150px'}}>입금표</div>
                                <div className="table_th--text" style={{ width:'150px'}}>이체증</div>
                                <div className="table_th--text" style={{ width:'150px'}}>화주비고</div>
                                <div className="table_th--text" style={{ width:'150px'}}>선사비고</div>
                            </Row>
                        {loading===false?
                            (data.length > 0) && data.map((value,index)=> (
                            <TableList key={index} indexs={index} propsData={value} setTranstk={()=>setTranstk(!transtk)} setTranstkDownload={()=>setTranstkDownload(!transtkDownload)} setModalInvoiceSid={(INVOICE_SID)=>setModalInvoiceSid(INVOICE_SID)}/>
                            ))
                        :<div className="info" >
                            <div className="icon icon-youtube">
                            <i className="fa fa-spinner fa-pulse fa-fw text-secondary"></i>
                            </div>
                            <div className="description">
                            <h4 className="info-title">Searching....</h4>
                            <p>Searching. Please wait</p>
                            </div>
                        </div>}
                    </div>
                </CardBody>
                <CardFooter className="text-center">
                        {/* {data.length > 0 &&
                        <Button
                            size="lg"
                            className="btn-round"
                            // onClick={() => {onMore(num + 1);}}
                            color="primary">
                            <span>More&nbsp;{num}&nbsp;/&nbsp;{data.length!==0?data[0].tot_page:"0"}</span>
                        </Button>} */}
                    </CardFooter>
                </Card>
            </Col>
            <TranstkReg 
                isUser={props.userData}
                openTranstk={transtk}
                modalInvoiceSid={modalInvoiceSid}
                setOpenTranstk={(e)=>setTranstk(e)}
                lineCode={lineCode}
                successSummit={successSummit}
                {...props}
            />
            <TranstkDownload 
                isUser={props.userData}
                openTranstkDownload={transtkDownload}
                modalInvoiceSid={modalInvoiceSid}
                setTranstkDownload={(e)=>setTranstkDownload(e)}
                lineCode={lineCode}
                {...props}
            />
        </>
    )

}