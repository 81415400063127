/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody,FormGroup,Label,Input, Card} from "reactstrap";
import axios from 'axios';
import Select from "react-select";
import InputValid from "components/CustomInput/InputValid.js";

export default function Container(props) { 
	const {cntrData,codelist,size,bkgdata,checked,pack, userData, zindex, bookmarkoption, isDupCheck,} = props;
	const [selectVal,setSelectVal] = useState("");
	const [cntr, setCntr] = useState({});
	  
  	useEffect(() => {
    	setCntr({
			...cntrData,
			cntr_res_bkg_no:cntrData.cntr_res_bkg_no
			?cntrData.cntr_res_bkg_no
			:bkgdata||'',
			cntr_yn:cntrData.cntr_yn?cntrData.cntr_yn:checked?'Y':'N',
			cntr_verifying_type:props.cntrData.cntr_verifying_type?props.cntrData.cntr_verifying_type:'SM2'
		});
  	},[cntrData]);
	useEffect(() => {
	    if(cntr.cntr_no && (cntr.cntr_verifying_type && cntr.cntr_verifying_type === 'SM2') && props.loadData.vsl_type === '41' && cntr.cntr_weight && cntr.cntr_vgm) {
	        let total = parseInt(cntr.cntr_weight)+parseInt(cntr.cntr_vgm);
	    	var list = {...cntr,'cntr_total_weight':String(total)};
	    	setCntr(list);
	    	props.propsData(list);
		}
	},[props.loadData.vsl_type]); 

	useEffect(() => {
		if(props.loadData.trans_service_code == '89') {
			let bulkObj = {...cntr,
					cntr_no:'BULK',
					cntr_seal:'0',
					cntr_code:'BULK',
					cntr_carton_code: props.loadData.cargo_pack_type ||null,
					cntr_carton_qty: props.loadData.cargo_pack_qty||null,
					cntr_weight: props.loadData.cargo_total_weight||null,
				}
	    	setCntr(bulkObj);
	    	props.propsData(bulkObj);
		}else{
			//bulk였으면 다시 null로
			if(cntr.cntr_code =='BULK'){
				let nullObj = {...cntr,
					cntr_no: null,
					cntr_seal: null,
					cntr_code: null,
					// cntr_carton_code: null,
					// cntr_carton_qty:  null,
					// cntr_weight: null,
				}
				setCntr(nullObj);
				props.propsData(nullObj);
			}
		}
	},[props.loadData.trans_service_code,props.loadData.cargo_pack_type,props.loadData.cargo_pack_qty, props.loadData.cargo_total_weight]); 
/*  useEffect(() => {
	    //setCntr({...cntr,'cntr_res_bkg_no':props.bkgdata});
	    props.propsData({...cntrData,'cntr_res_bkg_no':props.bkgdata});
	  },[bkgdata]); */
  
/*  useEffect(() => {
	    //setCntr({...cntr,'cntr_res_bkg_no':props.bkgdata});
	    props.propsData({...cntrData,'cntr_yn':props.checked?'Y':'N'});
	  },[checked]); */
  

  

	// const onHandleReturnValHan = (event,name) => {
	// 	//if(!validation.validationHangle(event.target.value.toUpperCase())) {
	// 	let list = {...cntr, [name]:event.target.value.toUpperCase()};
	// 	setCntr(list);	
	// 	// }
	// }
 
	const onHandleReturnVal = (event,name) => {
		var list = {...cntr, [name]:(event.target.value.toUpperCase())||null};
		setCntr(list);	
	}
 
	const onHandleCheckReturnVal = (data,name) => {
		let list = {...cntr, [name]:data.toUpperCase()};
		setCntr(list);
		props.propsData(list); 
	}
 
	const onChangeCntrReturnVal = (value,name) => {
		let list = {...cntr, [name]:value
			,cntr_seq:cntrData.cntr_seq?cntrData.cntr_seq:''};
		setCntr(list);
		props.propsData(list); 
	}
 
	// const onChangeCntrReturnValVgm = async(value,name) => {
	// 	var list;
	// 	var vVal = value; 
	// 	if(cntr.cntr_no && vVal === 'SM2' && props.loadData.vsl_type === '41' && cntr.cntr_weight) { 
	// 		if(!cntr.cntr_vgm) {
	// 			const vgm = await onSelectWeidongVgm(); 
	// 			if( props.loadData.vsl_type === '41') { 
	// 				list = {...cntr,'cntr_total_weight':parseInt(cntr.cntr_weight?cntr.cntr_weight:0) + parseInt(vgm?vgm:0),'cntr_vgm':vgm, [name]:vVal}
	// 			} else {
	// 				list = {...cntr,'cntr_vgm':vgm,[name]:vVal}
	// 			}
	// 		} else {
	// 			if(props.loadData.vsl_type === '41') {
	// 				list = {...cntr,'cntr_total_weight':parseInt(cntr.cntr_weight) + parseInt(cntr.cntr_vgm),[name]:vVal};
	// 			} else {
	// 				list = {...cntr,[name]:vVal};
	// 			}
	// 		} 
	// 	} else {
	// 		list = {...cntr,[name]:vVal};
	// 	}
	// 	setCntr(list);
	// 	props.propsData(list);  
	// }
  
	const onPropsReturn = ()=> {
		props.propsData(cntr);
	}
  
    
	const onSelectWeidongVgm = async() => {
		return await axios.post("/shipper/getUserCntrWeightInfo",{user_no:props.userData?props.userData.user_no:'',line_code:'WDFC',cntr_no:cntr.cntr_no}).then(res => {
			var vgm;
			if(res.data) {  
				vgm = res.data.vgm;
			} else {	
				vgm = null;
			}
		return vgm;
		}); 
	}
  
	const onDeleteCntr =(data) => {
		props.deleteRow(data);
	}

	const onBlurCntrNo = async()=> {  
		var list;
		//계측 방법 2 경우 VGM 총 중량 자동 계산 (계측방법 2+weight+컨테이너 번호)
		if(cntr.cntr_no && (cntr.cntr_verifying_type && cntr.cntr_verifying_type === 'SM2') && cntr.cntr_weight) {
			if(!cntr.cntr_vgm) {
				const vgm = await onSelectWeidongVgm();
				if(props.loadData.vsl_type === '41') {  
					list = {...cntr,'cntr_total_weight':parseInt(cntr.cntr_weight) + parseInt(vgm),'cntr_vgm':vgm};
				} else {
					list = {...cntr,'cntr_vgm':vgm};
				}
			} else {
				if(props.loadData.vsl_type === '41') { 
					list = {...cntr,'cntr_total_weight':parseInt(cntr.cntr_weight) + parseInt(cntr.cntr_vgm)};
				} else {
					list=cntr;
				}
			}
		} else {
			list = cntr;
		}

		setCntr(list);
		props.propsData(list);  
	}
    
	const onBlurProps = ()=>{
		return props.propsData(cntr);
	}

	const onChangeCntrBookmark = (value) =>{
		setSelectVal(value);
		axios.post("/shipper/getUserCntrBookmark",{
			user_no:userData?userData.user_no:'',seq:value
		}).then(res => {  
			setCntr({...res.data[0],'cntr_yn':cntr.cntr_yn?cntr.cntr_yn:checked?'Y':'N'}); 
			props.propsData({...res.data[0],'cntr_yn':cntr.cntr_yn?cntr.cntr_yn:checked?'Y':'N'});	            	    
		});
	}
	  
  	return (
		<Card className="no-transition mb-2" style={{border:'1px solid silver',zIndex:zindex}}> 	   
			<CardBody className="pt-0 pb-3">
				<Row>
					<Col className="pb-2 pr-2">
				     	<button
			              className="close mt-1"
			              type="button"
			              onClick={(cntr) => onDeleteCntr(cntr)}
			            >×</button>
			      	</Col>
				</Row>
				<Row>
					<Col xl="0" className="col-0 pl-2 mt-auto mb-auto">
						<FormGroup check style={{height:'69px'}}>
							<Label check>
							<Input 
								type="checkbox"  
								checked={cntr.cntr_yn==="Y"?true:false} 
								onChange = {()=>onHandleCheckReturnVal(cntr.cntr_yn==="Y"?"N":"Y",'cntr_yn')}/>
								<span className="form-check-sign"/>
							</Label>
						</FormGroup>
					</Col>
					<Col>
						<Row>
							<Col xl="4" lg="4" md="6">
								<FormGroup className="mb-1">
									<Select
										className="customSelect bg-white"
										name="container_bookmark_seq"
										value={{value:selectVal?selectVal:'',
												label:selectVal?(props.bookmarkoption.findIndex(x=>x.value===selectVal)>=0)?props.bookmarkoption[props.bookmarkoption.findIndex(x=>x.value===selectVal)].label:'선택':'선택'
										}}
										onChange = {(value)=>onChangeCntrBookmark(value.value,'container_bookmark_seq')}
										options={props.bookmarkoption}
										styles={{
											control: provided => ({...provided,minHeight:!size?'40px':'28px',height:!size?'40px':'28px'}),
											indicatorsContainer: provided => ({...provided,height:!size?'40px':'28px'})
										}}/>
										{/*<Input type="select" value={selectVal}
											onChange={(event)=>onChangeCntrBookmark(event)}>
											//invalid={!cntr.cntr_code?true:false}
										<option value="">선택</option>
										{props.bookmarkoption && props.bookmarkoption.length >0?
												props.bookmarkoption.map((data,key)=>
												<option key={"bk_"+key} value={data.value}>{data.label}</option>
												):<></>}
										</Input>
											<FormFeedback>{validation.REQ_MSG}</FormFeedback>*/}
								</FormGroup>	
							</Col>
						</Row>
						<Row>
							<Col xl="2" className="col-12">
								<FormGroup className="mb-1">
									<Label className="mb-0">Container No</Label>
									<InputValid 
										type="text"
										name="cntr_no"
										id="cntr_no"
										maxLength="11"
										value={cntr.cntr_no?cntr.cntr_no:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_no')}
										onBlur={onBlurCntrNo}
										validtype="engNumber"
										required={true} 
										feedid="container"
										dupcheck = {isDupCheck?(isDupCheck).toString():"false"}										
										readOnly={props.loadData.trans_service_code =='89'? true :false}
									/>
								</FormGroup>	
							</Col>
							<Col xl="2" className="col-12">
								<FormGroup className="mb-1">
									<Label className="mb-0">Seal No</Label>
									<InputValid 
										type="text"
										name="cntr_seal"
										id="cntr_seal"
										maxLength="10"
										value={cntr.cntr_seal?cntr.cntr_seal:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_seal')}
										onBlur={onBlurProps}
										validtype="koreanban"
										required={true} 
										feedid="container"
										readOnly={props.loadData.trans_service_code =='89'? true :false}
									/>
								</FormGroup>
							</Col>
							<Col xl="2" className="col-12">
								<FormGroup className="mb-1">
									<Label className="mb-0">Size/Type</Label>
									<Select
										className="customSelect"
										name="cntr_code"
										value={cntr.cntr_code&&codelist.findIndex(x=>x.value===cntr.cntr_code)>=0
												?codelist[codelist.findIndex(x=>x.value===cntr.cntr_code)]
												:cntr.cntr_code =='BULK' ?  {value:'BULK',label:''} : {value:'',label:'선택'}
										}
										getOptionLabel = {options=>options.label+" ["+options.value+"] "}
										onChange = {(value)=>onChangeCntrReturnVal(value.value,'cntr_code')}
										options={codelist}
										styles={{
											control: provided => ({...provided,border:!cntr.cntr_code?'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
											indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
										}}
										isDisabled={props.loadData.trans_service_code =='89'? true :false}
									/>
									<InputValid 
										hidden
										name="cntr_code"
										id="cntr_code"
										maxLength="4"
										value={cntr.cntr_code?cntr.cntr_code:''}
										validtype="koreanban"
										required={true} 
										readOnly
										feedid="container"
									/>
								{/*<Input type="select" value={cntr.cntr_code?cntr.cntr_code:''}
									onChange={(event)=>onChangeCntrReturnVal(event,'cntr_code')}
									invalid={!cntr.cntr_code?true:false}>
								<option value="">선택</option>
								{codelist.length>0?codelist.map((data,key)=>
									<option key={key} value={data.cntr_code}>{data.cntr_code_name}</option>
								):<></>}
								</Input>
									<FormFeedback feedid="container">{validation.REQ_MSG}</FormFeedback>*/}
								</FormGroup>	
							</Col>
							<Col xl="2" className="col-12">
								<FormGroup className="mb-1">
									<Label className="mb-0">Weight</Label>
									<InputValid 
										type="text"
										name="cntr_weight"
										id="cntr_weight"
										maxLength="18"
										value={cntr.cntr_weight?cntr.cntr_weight:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_weight')}
										onBlur={onBlurProps}
										required={true} 
										inputgrouptext="KG"
										feedid="container"
										validtype="decimal"
										decimallength="3"
									/>
									{/* <InputGroup >
										<Input type="number" name="cntr_weight" id="cntr_weight" placeholder="" value={cntr.cntr_weight?cntr.cntr_weight:''}
											onChange = {(event)=>onHandleReturnValHan(event,'cntr_weight')}
										onBlur={onBlurCntrNo} invalid={!cntr.cntr_weight?true:false} maxLength="18"/>
										<InputGroupAddon addonType="append">
										<InputGroupText className="p-1" style={{border:!cntr.cntr_weight?'1px solid red':'',borderRadius:'0 4px 4px 0'}}>kg</InputGroupText>
										</InputGroupAddon>
										<FormFeedback feedid="container">{validation.REQ_MSG}</FormFeedback>
									</InputGroup> */}
								</FormGroup>
							</Col>
							{/*<Col xl="2"  className="col-6">
								<FormGroup>
									<Label className="mb-0">Volume</Label>
									<InputGroup >
										<Input type="number" name="cntr_total_volume" id="cntr_total_volume" placeholder="" value={cntr.cntr_total_volume?cntr.cntr_total_volume:''}
											onChange = {(event)=>onHandleReturnValHan(event,'cntr_total_volume')}
										onBlur={onPropsReturn} //invalid={!cntr.cntr_total_volume?true:false} 
											maxLength="18"/>
										<InputGroupAddon addonType="append">
										<InputGroupText className="p-1" >CBM</InputGroupText>
										</InputGroupAddon>
										{/FormFeedback feedid="container">{validation.REQ_MSG}</FormFeedback>*
										</InputGroup>
								</FormGroup>
							</Col>	*/}
							<Col xl="4" className="col-12">
								<FormGroup className="mb-1">
									<Label className="mb-0">Package</Label>
									<Row>
										<Col className="col-8 pr-1">
											<Select
												className="customSelect"
												name="cntr_carton_code"
												value={cntr.cntr_carton_code
													?pack.findIndex(x=>x.value===cntr.cntr_carton_code)>=0
														&&pack[pack.findIndex(x=>x.value===cntr.cntr_carton_code)]
													:{value:'',label:'선택'}
												}
												onChange = {(value)=>onChangeCntrReturnVal(value.value,'cntr_carton_code')}
												options={pack}
												getOptionLabel = {options=>options.label}
												styles={{
													control: provided => ({...provided,border:!cntr.cntr_carton_code?'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
													indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
												}}
											/>
											<InputValid 
												hidden
												name="cntr_carton_code"
												id="cntr_carton_code"
												maxLength="2"
												value={cntr.cntr_carton_code?cntr.cntr_carton_code:''}
												validtype="koreanban"
												required={true} 
												readOnly
												feedid="container"
											/>
											{/*<Input type="select" className="pt-0 pb-0" value={cntr.cntr_carton_code?cntr.cntr_carton_code:''} 
											onChange = {(event)=>onChangeCntrReturnVal(event,'cntr_carton_code')}
											invalid={(!cntr.cntr_carton_qty || !cntr.cntr_carton_qty)?true:false} >
												<option value="">선택</option>
												{pack.length>0?pack.map((data,key) => 
													<option key={key} value={data.cargo_pack_type}>{data.cargo_pack_type_desc+" ["+data.cargo_pack_type+"]"}</option>):<></>}
												</Input>
												<FormFeedback feedid="container">{validation.REQ_MSG}</FormFeedback>*/}
										</Col>
										<Col className="col-4 pl-1">
											<InputValid 
												type="text"
												name="cntr_carton_qty"
												id="cntr_carton_qty"
												maxLength="8"
												value={cntr.cntr_carton_qty?cntr.cntr_carton_qty:''}
												onChange={(e)=>onHandleReturnVal(e, 'cntr_carton_qty')}
												onBlur={onPropsReturn}
												validtype="number"
												required={true} 
												feedid="container"
											/>
										</Col>
									</Row>
								</FormGroup>
							</Col>
							{/* {(!props.loadData.vsl_type || props.loadData.vsl_type && props.loadData.vsl_type === '41') &&
							<Col xl="8" lg="8" md="12" className="col-12">
								<Row>
									<Col xl="1">
										<Badge className="mr-1" color="default" pill>VGM</Badge>
									</Col>
									<Col>
										<Row>
											<Col xl="3">
												<FormGroup className="mb-1">
													<Label className="mb-0">Verifying Type</Label>
													<Select
														className="customSelect"
														name="cntr_verifying_type"
														value={{value:cntr.cntr_verifying_type ?cntr.cntr_verifying_type :'',
														label:cntr.cntr_verifying_type?
															(vgmList.findIndex(x=>x.value===cntr.cntr_verifying_type)>=0)?
																	vgmList[vgmList.findIndex(x=>x.value===cntr.cntr_verifying_type)].label:
																'선택':'선택'
														}}
														onChange = {(value)=>onChangeCntrReturnValVgm(value.value,'cntr_verifying_type')}
														options={vgmList}
														styles={{
															control: provided => ({...provided,border:(props.loadData.vsl_type && props.loadData.vsl_type === '41') && !cntr.cntr_verifying_type?'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
															indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
														}}
													/>
													<InputValid 
														hidden
														name="cntr_verifying_type"
														id="cntr_verifying_type"
														maxLength="3"
														value={cntr.cntr_verifying_type?cntr.cntr_verifying_type:''}
														validtype="koreanban"
														required={(props.loadData.vsl_type && props.loadData.vsl_type === '41') && !cntr.cntr_verifying_type?true:false} 
														readOnly
														feedid="container"
													/>
												</FormGroup>
											</Col>
											<Col xl="3">
												<FormGroup className="mb-1">
													<Label className="mb-0">BkgNo</Label>
													<InputValid 
														type="text"
														name="cntr_res_bkg_no"
														id="cntr_res_bkg_no"
														placeholder=""
														maxLength="35"
														value={cntr.cntr_res_bkg_no?cntr.cntr_res_bkg_no:''}
														onChange={(e)=>onHandleReturnVal(e, 'cntr_res_bkg_no')}
														onBlur={onBlurProps}
														validtype="koreanban"
														required={(props.loadData.vsl_type && props.loadData.vsl_type === '41')?true:false} 
														feedid="container"
													/>
												</FormGroup>
											</Col>
											<Col xl="3">
											<FormGroup className="mb-1">
												<Label className="mb-0">PIC Name</Label>
													<InputValid 
														type="text"
														name="cntr_auth_user_name"
														id="cntr_auth_user_name"
														maxLength="35"
														value={cntr.cntr_auth_user_name?cntr.cntr_auth_user_name:''}
														onChange={(e)=>onHandleReturnVal(e, 'cntr_auth_user_name')}
														onBlur={onBlurProps}
														validtype="koreanban"
														required={(props.loadData.vsl_type && props.loadData.vsl_type === '41')?true:false} 
														feedid="container"
													/>
												</FormGroup>
											</Col>
											<Col xl="3" className="col-12">
												<FormGroup className="mb-1">
													<Label className="mb-0">TotalWeight</Label>
													<InputGroup >
														<Input 
															type="number" name="cntr_total_weight" id="cntr_total_weight" placeholder="" value={cntr.cntr_total_weight?cntr.cntr_total_weight:''}
															onChange = {(event)=>onHandleReturnValHan(event,'cntr_total_weight')}
															onBlur={onPropsReturn}  invalid={(props.loadData.vsl_type && props.loadData.vsl_type === '41') && !cntr.cntr_total_weight?true:false} maxLength="18"/>
															<InputGroupAddon addonType="append">
																<InputGroupText className="p-1" style={{border:(props.loadData.vsl_type && props.loadData.vsl_type === '41') &&!cntr.cntr_total_weight?'1px solid red':'',borderRadius:'0 4px 4px 0'}}>kg</InputGroupText>
															</InputGroupAddon>
															<FormFeedback feedid="container">{validation.REQ_MSG}</FormFeedback>
													</InputGroup>
												</FormGroup>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>} */}
						</Row>	
					</Col>
				</Row>
			</CardBody>
		</Card>
    );
}
