import React,{useState, useEffect, useRef} from "react";
import {
    Row,
    Col,
    CardBody,
    Container,
    Button,UncontrolledTooltip,Modal,Collapse,Card,Form,Badge
} from "reactstrap";
import ScheduleCard from './schedule/ScheduleCard.js';
import ShipperCard from './shipper/ShipperCard.js';
import NotifyCard from "./notify/NotifyCard.js";
import ConsigneeCard from "./consignee/ConsigneeCard.js";
import CCAM_ShipperCard from './ccam/shipper/ShipperCard.js';
import CCAM_NotifyCard from "./ccam/notify/NotifyCard.js";
import CCAM_ConsigneeCard from "./ccam/consignee/ConsigneeCard.js";
import ContainerCard from "./container/ContainerCard.js";
import SrTitleCard from "./title/SrTitleCard.js";
import OthersCard from "./other/OthersCard.js";
import CargoCard from "./cargo/CargoCard.js";
import DeclareCard from "./declare/DeclareCard.js";
import Switch from "react-bootstrap-switch";
import axios from 'axios';
import InputValid from "components/CustomInput/InputValid.js";
import * as validation from 'components/common/validation.js';
export default function SrRequest( props ) {

	const {userData,validation} = props;
	const [allOpen,setAllOpen] = useState(true);
	const [shpOpen,setShpOpen] = useState(true);
	const [consOpen,setConsOpen] = useState(true);
	const [notiOpen,setNotiOpen] = useState(true);
	const [shp2Open,setShp2Open] = useState(true);
	const [cons2Open,setCons2Open] = useState(true);
	const [noti2Open,setNoti2Open] = useState(true);
	const [otherOpen,setOtherOpen] = useState(true);
	const [cargoOpen,setCargoOpen] = useState(true);
	const [schOpen,setSchOpen] = useState(true);
	const [cntrOpen,setCntrOpen] = useState(true);
	const [decOpen,setDecOpen] = useState(true);
	const [userTitleBookmarkList,setUserTitleBookmarkList] = useState([]);
	const [userLineBookmarkList,setUserLineBookmarkList] = useState([]);
	const [userShpBookmarkList,setUserShpBookmarkList] = useState([]);
	const [userConsBookmarkList,setUserConsBookmarkList] = useState([]);
	const [userNotiBookmarkList,setUserNotiBookmarkList] = useState([]);
	const [userSchBookmarkList,setUserSchBookmarkList] = useState([]);
	const [userCargoBookmarkList,setUserCargoBookmarkList] = useState([]);	
	const [userMarkBookmarkList,setUserMarkBookmarkList] = useState([]);
	const [userGoodsBookmarkList,setUserGoodsBookmarkList] = useState([]);
	const [userOtherBookmarkList,setUserOtherBookmarkList] = useState([]);
	const [userCntrBookmarkList,setUserCntrBookmarkList] = useState([]);
	const [userDeclareBookmarkList,setUserDeclareBookmarkList] = useState([]);
	const [userCCAMShpBookmarkList,setUserCCAMShpBookmarkList] = useState([]);
	const [userCCAMConsBookmarkList,setUserCCAMConsBookmarkList] = useState([]);
	const [userCCAMNotiBookmarkList,setUserCCAMNotiBookmarkList] = useState([]);

	
    // FocusTarget 어느건지
    const [focusTarget, setFocusTarget] = useState("");
    const bookingFocus = useRef(null);
    const scheduleFocus = useRef(null);
    const shipperFocus = useRef(null);
    const consigneeFocus = useRef(null);
    const notifyFocus = useRef(null);
    const cargoFocus = useRef(null);
    const cntrFocus = useRef(null);
    const declareFocus = useRef(null);
    const cShipperFocus = useRef(null);
    const cConsFocus = useRef(null);
    const cNotifyFocus = useRef(null);
	//alert
	const [modalMsg, setModalMsg] = useState("");
	const [modalStats, setModalStats] = useState("");
	
	const [srData,setSrData] = useState([]);
	const [open, setOpen] = useState(false);
	const [autoSelf, setAutoSelf] = useState(true);
	const [srNoDupCheck, setSrNoDupCheck] = useState(false);
	const [newSrNo, setNewSrNo] = useState("");
	const [topView, setTopView] = useState(false);
	

	
	const [param, setParam] = useState({user_no: props.location.state && props.location.state.user_no?props.location.state.user_no|| '' :userData?userData.user_no:null, 
            sr_no: props.location.state && props.location.state.sr_no  ? props.location.state.sr_no || '' : null,  
            sr_date: props.location.state && props.location.state.sr_date ? props.location.state.sr_date || '' : null,
            doc_new: props.location.state && props.location.state.doc_new ? props.location.state.doc_new || '' : null,
            res_bkg_no: props.location.state && props.location.state.res_bkg_no?props.location.state.res_bkg_no || '':null,
            /*sch_vessel_name: props.location.state && props.location.state.sch_vessel_name?props.location.state.sch_vessel_name || '':null,
            sch_vessel_voyage: props.location.state && props.location.state.sch_vessel_voyage?props.location.state.sch_vessel_voyage || '':null,
            sch_pol: props.location.state && props.location.state.sch_pol?props.location.state.sch_pol || '':null,
            sch_pol_name: props.location.state && props.location.state.sch_pol_name?props.location.state.sch_pol_name || '':null,
            sch_pod: props.location.state && props.location.state.sch_pod?props.location.state.sch_pod || '':null,
            sch_pod_name: props.location.state && props.location.state.sch_pod_name?props.location.state.sch_pod_name || '':null,*/
            confirm_yn:props.location.state && props.location.state.confirm_yn?props.location.state.confirm_yn || '':null,
			line_code:props.location.state && props.location.state.line_code?props.location.state.line_code || '':null
	});
 
	useEffect(() => {
		const updateListData = () => {

			let scrollTop = Math.max(document.documentElement.scrollTop,document.body.scrollTop);
			//Scroll 화면 하단 Check 
			if((Math.round(scrollTop) > 199)) {
				setTopView(true);  
			} else {
				setTopView(false);
			}
		    	
		}
		window.addEventListener("scroll", updateListData);
		      
		return function cleanup() {
			window.removeEventListener("scroll", updateListData);
		};
	});

	useEffect(() => {
		if(userData) { 
			onBookmark(userData);
		}
	},[userData]);
	
	useEffect(() => {
	    window.addEventListener("keydown", keyPress);
	    return () => {
	        window.removeEventListener("keydown", keyPress);
	    }
	})

	useEffect(() => {
		let timeout;
		if (param && !param.sr_no && param.doc_new === 'Y') {
			autoSrNumberCreate(false);
		} else if (param && param.res_bkg_no && param.confirm_yn === 'Y') {
			//sr 있을 경우
			if(param.sr_no && param.sr_date) {
				getSRDataSelect(param);
			} else { 
				//confirm에서 sr문서생성
				axios.post("/shipper/setUserSrBkgInit",{user_no:userData?userData.user_no:''
				,bkg_no:param.res_bkg_no
				, klnet_id:userData?userData.klnet_id:''
				, lineCode:'WDFC'
				}).then(res => { 
					let data = {...res.data,goods_desc:trans_goods_descriptin(res.data.trans_service_code,srData.goods_desc)};
					setSrData(data);
				}).catch(
					error => {
						props.onAlert("error",validation.ERR_MSG);
						timeout =setTimeout(window.history.back(),2000);
					}
				);
			}
		} else if (param && param.sr_no && (!param.confirm_yn || param.confirm_yn !== 'N')) {
			getSRDataSelect(param);
		}
		return   () =>clearTimeout(timeout);
    },[param]);

	useEffect(()=>{
        setNewSrNo('')
		if(!autoSelf)setSrNoDupCheck(false);
    }, [autoSelf]);

	const keyPress =(key)=> {
	    if( key.key === 'F2' ) {
			if( !focusTarget ) {
				setFocusTarget("booking");
			} else if( "booking" === focusTarget ) {
				setFocusTarget("schedule");
			} else if( "schedule" === focusTarget ) {
				setFocusTarget("shipper");
			} else if( "shipper" === focusTarget ) {
				setFocusTarget("consignee");
			} else if( "consignee" === focusTarget ) {
				setFocusTarget("notify");
			} else if( "notify" === focusTarget ) {
				setFocusTarget("cargo");
			} else if( "cargo" === focusTarget ) {
				setFocusTarget("container");
			} else if( "container" === focusTarget ) {
				if('N' === srData.hbl_yn ) {
					setFocusTarget("declare");
				} else {
					setFocusTarget("c_shipper");
				}
			} else if( "declare" === focusTarget ) {
				setFocusTarget("c_shipper");
			} else if( "c_shipper" === focusTarget ) {
				setFocusTarget("c_consignee");
			} else if( "c_consignee" === focusTarget ) {
				setFocusTarget("c_notify");
			} else if( "c_notify" === focusTarget ) {
				setFocusTarget(null);
			}
	    }
	}

	useEffect(()=>{
	    if( "booking" === focusTarget ) {
	        bookingFocus.current.focus();
	    } else if ( "schedule" === focusTarget ) {
	        scheduleFocus.current.focus();
	    } else if ( "shipper" === focusTarget ) {
	        shipperFocus.current.focus();
	    } else if ( "consignee" === focusTarget ) {
	        consigneeFocus.current.focus();
	    } else if ( "notify" === focusTarget ) {
	        notifyFocus.current.focus();
	    } else if ( "cargo" === focusTarget ) {
	        cargoFocus.current.focus();
	    } else if ( "container" === focusTarget ) {
	        cntrFocus.current.focus();
	    } else if ( "declare" === focusTarget ) {
	    	declareFocus.current.focus();
	    } else if ( "c_shipper" === focusTarget ) {
	        cShipperFocus.current.focus();
	    } else if ( "c_consignee" === focusTarget ) {
	        cConsFocus.current.focus();
	    } else if ( "c_notify" === focusTarget ) {
	        cNotifyFocus.current.focus();
	    }
	}, [focusTarget]);
	
	// select Goods function
	const trans_goods_descriptin = (transCode,goodsData) => {
		let returnValue;
		
		if(("1" === transCode || "11" === transCode)) {
			if(goodsData) {
				var goodsSplit = goodsData.split('\n');
				if("SHIPPER'S LOAD. COUNT & SEAL" === goodsSplit[0]) {
					goodsSplit[0] = "SHIPPER'S LOAD. COUNT & SEAL";
					goodsSplit[1] = "SAID TO CONTAIN :";
					goodsData = goodsSplit.join('\n');
				} else if ("SAID TO CONTAIN :" === goodsSplit[0]||"SAID TO BE :" === goodsSplit[0]) {
					goodsSplit[0] = "\n";
					goodsData = goodsSplit.join('\n');
				} else {
					goodsData = "\n\n"+goodsSplit.join('\n');
				}
				var goodsDesc = goodsData.split('\n');
				goodsDesc[0] = "SHIPPER'S LOAD. COUNT & SEAL";
				goodsDesc[1] = "SAID TO CONTAIN :";
				returnValue = goodsDesc.join('\n');
			} else {
				returnValue = "SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n";
			}
		} else if(("3" === transCode || "13" === transCode)) {
			if(goodsData) {
				var goodsSplit = goodsData.split('\n');
				if("SAID TO CONTAIN :" === goodsSplit[0] ||"SAID TO BE :" === goodsSplit[0]) {
					goodsSplit[0] = "SAID TO CONTAIN :";
				} else if ("SHIPPER'S LOAD. COUNT & SEAL" === goodsSplit[0]) {
					goodsSplit[0] ="SAID TO CONTAIN :";
					goodsSplit.splice(1,1);
					goodsData = goodsSplit.join('\n');
				} else {
					goodsData = "\n"+goodsSplit.join('\n');
				}
				var goodsDesc = goodsData.split('\n');
				goodsDesc[0] = "SAID TO CONTAIN :";
				returnValue = goodsDesc.join('\n');
			} else {
				returnValue = "SAID TO CONTAIN :\n";
			} 
		} else if(("89" === transCode || "99" === transCode)) {
			if(goodsData) {
				var goodsSplit = goodsData.split('\n');
				if("SAID TO CONTAIN :" === goodsSplit[0] ||"SAID TO BE :" === goodsSplit[0]) {
					goodsSplit[0] = "SAID TO BE :";
				} else if ("SHIPPER'S LOAD. COUNT & SEAL" === goodsSplit[0]) {
					goodsSplit[0] ="SAID TO BE :";
					goodsSplit.splice(1,1);
					goodsData = goodsSplit.join('\n');
				} else {
					goodsData = "\n"+goodsSplit.join('\n');
				}
				var goodsDesc = goodsData.split('\n');
				goodsDesc[0] = "SAID TO BE :";
				returnValue = goodsDesc.join('\n');
			} else {
				returnValue = "SAID TO BE :\n";
			}  
		} else {
			returnValue =goodsData;
		}
		return returnValue;
	}
	
    const onBookmark =(data)=>{
    	axios.post("/shipper/getUserBookmark",{user_no:data?data.user_no:''}).then(res => {
  	  		setUserTitleBookmarkList(res.data.totalList);
  	  	    setUserLineBookmarkList(res.data.carrierList);
  	  	    setUserShpBookmarkList(res.data.shipperList);
  	  	    setUserConsBookmarkList(res.data.consList);
  	  	    setUserNotiBookmarkList(res.data.notiList);
  	  	    setUserCargoBookmarkList(res.data.cargoList);
  	  	    setUserOtherBookmarkList(res.data.bookingList);
  	  	    setUserSchBookmarkList(res.data.scheduleList);
  	  	    setUserCntrBookmarkList(res.data.cntrList);
  	  	    setUserDeclareBookmarkList(res.data.decList);
  	  	    setUserMarkBookmarkList(res.data.markList);
  	  	    setUserGoodsBookmarkList(res.data.goodsList);
			setUserCCAMShpBookmarkList(res.data.ccamShipperList);
			setUserCCAMConsBookmarkList(res.data.ccamConsigneeList);
			setUserCCAMNotiBookmarkList(res.data.ccamNotifyList);
  	  	});
    }

	const onLoadTitleBookmark =() => {
		axios.post("/shipper/getUserTitleBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserTitleBookmarkList(res.data)});
    }
	
	const onLoadLineBookmark =() => {
		axios.post("/shipper/getUserLineBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserLineBookmarkList(res.data)});			
	}
	
	const onLoadShpBookmark =() => {
		axios.post("/shipper/getUserShpBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserShpBookmarkList(res.data)});
	}
	
	const onLoadConsBookmark =() => {
		axios.post("/shipper/getUserConsBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserConsBookmarkList(res.data)});	
		
	}
	
	const onLoadNotiBookmark=()=>{
		axios.post("/shipper/getUserNotiBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserNotiBookmarkList(res.data)});	
		
	}
	
	const onLoadCargobookmark=()=>{
		axios.post("/shipper/getUserCargoBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserCargoBookmarkList(res.data)});	
	}
	
	const onLoadMarkbookmark=()=>{
		axios.post("/shipper/getUserMarkBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserMarkBookmarkList(res.data)});	
	}
	
	const onLoadGoodsbookmark=()=>{
		axios.post("/shipper/getUserGoodsBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserGoodsBookmarkList(res.data)});	
	}
	const onLoadOtherbookmark=()=>{
		axios.post("/shipper/getUserOtherBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserOtherBookmarkList(res.data)});	
	}
	const onLoadSchedulebookmark=()=>{
		axios.post("/shipper/getUserSchBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserSchBookmarkList(res.data)});	
	}	
	const onLoadCntrbookmark=()=>{
		axios.post("/shipper/getUserCntrBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserCntrBookmarkList(res.data)});
	}	
	const onLoadDecbookmark=()=>{
		axios.post("/shipper/getUserDeclareBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserDeclareBookmarkList(res.data)});
	}		
	const onLoadCCAMShpBookmark =() => {
		axios.post("/shipper/getUserCCAMShpBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserCCAMShpBookmarkList(res.data)});
	}
	const onLoadCCAMConsBookmark =() => {
		axios.post("/shipper/getUserCCAMConsBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserCCAMConsBookmarkList(res.data)});
	}
	const onLoadCCAMNotiBookmark =() => {
		axios.post("/shipper/getUserCCAMNotiBookmark",{user_no:userData?userData.user_no:''}).then(res => {
			setUserCCAMNotiBookmarkList(res.data)});
	}

	const getSRDataSelect = (param) => {
        axios.post("/shipper/getUserSrDataList",{user_no :param.user_no?param.user_no:userData?userData.user_no:''
													, lineCode:'WDFC'											
													,data:param
													,link:param&&param.sr_no?'Y':'N',
													list:'N'}).then(res =>{
			if(res.data) {
				if(res.data.length === 0 ){
					props.onAlert("error","조회된 값이 없습니다.");
				}else {
					if(res.data.status_cud !== 'D') {
						// let cons = {};
						// if(conSamec) {
						// 	if(!res.data.noti_name1) {
						// 		cons = {'noti_name1':'','noti_name2':'','noti_address1':'',
						// 		'noti_address2':'','noti_address3':'',
						// 		'noti_address4':'','noti_address5':''};
						// 		// cons = {'noti_name1':'SAME AS CONSIGNEE','noti_name2':'','noti_address1':'',
						// 		// 'noti_address2':'','noti_address3':'',
						// 		// 'noti_address4':'','noti_address5':''};	
						// 	}
						// }
						// let data = {...res.data,...cons,goods_desc:trans_goods_descriptin(res.data.trans_service_code,res.data.goods_desc)};
						let data = {...res.data,goods_desc:trans_goods_descriptin(res.data.trans_service_code,res.data.goods_desc)};
						setSrData(data);
					}else {
						props.onAlert("error","삭제된 문서는 조회 하실 수 없습니다. 목록 재조회 하시기 바랍니다.");
						window.location.href="/svc/srlist";
					}
				}
			}else {
				props.onAlert("error","찾을 수 없는 SR문서입니다.");
			}
		}).catch(err => {
			props.onAlert("error",validation.ERR_MSG);
			return false;
		});
    }
	
	const dataHandler = (data) => {
		setSrData({...srData,...data});
	}
	// SR 저장 
	const dataSave = () => {
	// 1. 자리수 체크 
		if( !validation.fncValidationMaxLength() ) {
            fncOpenCardInvalidMaxLength();
            props.onAlert("info","입력가능을(를) 확인 후 다시 저장 하세요.");
            return false;
        }
		if(!srData.sr_no) {
    		props.onAlert("error","sr_no 는 필수 입력항목 입니다. New 버튼을 통해 신규 번호를 입력 하세요.");
			return false;
		}
		axios.post("/shipper/setUserSRDataList",{user_no:userData?userData.user_no:'',data:srData, lineCode:'WDFC'}).then(res => {
			if(res.data.code==="S") {
				props.onAlert("success","작성한 데이터가 저장 되었습니다.");	
			}else {
				props.onAlert("error",res.data.data);
			}
		}).catch(err => {
			props.onAlert("error",validation.ERR_MSG);
			return false;
		})
	}
	
	const onLoadBookmark = (gubun) => {
		if(gubun === "sh") {
			onLoadShpBookmark();
		} else if (gubun === "ca") {
			onLoadLineBookmark();
		} else if (gubun === "cs") {
			onLoadConsBookmark();
		} else if (gubun === "nt") {
			onLoadNotiBookmark();
		} else if (gubun === "cg") {
			onLoadCargobookmark();
		} else if (gubun === "mk") {
			onLoadMarkbookmark();
		} else if (gubun === "gs") {
			onLoadGoodsbookmark();
		} else if (gubun === "ot") {
			onLoadOtherbookmark();
		} else if (gubun === "sc") {
			onLoadSchedulebookmark();
		} else if (gubun === "ct") {
			onLoadCntrbookmark();
		} else if (gubun === "dc") {
			onLoadDecbookmark();
		}else if (gubun === "tt") {
			onLoadTitleBookmark();
		}else if (gubun ==="csh") {
			onLoadCCAMShpBookmark();
		}else if (gubun ==="ccs") {
			onLoadCCAMConsBookmark();
		}else if (gubun ==="cnt") {
			onLoadCCAMNotiBookmark();
		}
	}

	/* 저장 및 문서 전송 , 삭제*/
	const fncOnDocSend = async() => {
		if(modalStats==="NORMAL") {
	        if( !userData.sr_recipient ) {
	            props.onAlert("error", validation.NO_SR_RECIPIENT);
	            return false;
	        } else {
	        	axios.post("/shipper/getUserSrDocInit",{user_no :userData?userData.user_no:''
														,klnet_id:userData?userData.klnet_id:''
														,sr_no:newSrNo, lineCode:'WDFC'}
				).then(res => {
					setSrData({...res.data,'bkglist':[]});
						setOpen(false);
						if( !autoSelf ) {setAutoSelf(!autoSelf);}
						// SELF 부킹화면 입력 되어 있으면 없애주자
						setNewSrNo(null);
						// 닫혀 있으면 열어주자
						if( !allOpen ) {setAllOpen(true);}
						// check 정보도 초기화
						if( srNoDupCheck ) {setSrNoDupCheck(!srNoDupCheck);}	
						props.onAlert("success",validation.NEW_MSG);
					}).catch(err => {
						props.onAlert("error",'신규 SR을 생성하지 못했습니다.');
				});
	        }
	    } else if(modalStats==="PART") {
			if( !validation.fncValidationMaxLength() ) {
				fncOpenCardInvalidMaxLength();
				props.onAlert("info","입력가능을(를) 확인 후 다시 저장 하세요.");
				return false;
			}
			if(!srData.sr_no) {
				props.onAlert("error","sr_no 는 필수 입력항목 입니다. New 버튼을 통해 신규 번호를 입력 하세요.");
				return false;
			} 	
			setOpen(false);
			axios.post("/shipper/setUserSRDataList",{user_no:userData?userData.user_no:'',data:srData, lineCode:'WDFC'	}).then(res => {
				if(res.data.code==="S") {
					axios.post("/shipper/setUserSrParkBl",{user_no :userData?userData.user_no:'',data:srData}).then(res => {
						if(res.data.code==="S") {
							setSrData(res.data.data);
							setAllOpen(!allOpen);
							setShpOpen(!allOpen);
							setConsOpen(!allOpen);
							setNotiOpen(!allOpen);
							setOtherOpen(!allOpen);
							setCargoOpen(!allOpen);
							setSchOpen(!allOpen);
							setCntrOpen(!allOpen);
							setDecOpen(!allOpen);
							setShp2Open(!allOpen);
							setCons2Open(!allOpen);
							setNoti2Open(!allOpen);    
							props.onAlert("success","작성한 SR의 PART B/L이 분할 되었습니다.");
						}else {
							props.onAlert("error",res.data.data);
						}	
					}).catch(err => {
						props.onAlert("error",validation.ERR_MSG);
						return false;
					})
				}else {
					props.onAlert("error",res.data.data);
				}
			}).catch(err => {
				props.onAlert("error",validation.ERR_MSG);
				return false;
			})
	    } else if(modalStats==="DELETE") { /**삭제 */
	    	setOpen(false);
	    	// 상태 조회
	    	if('FA' === srData.status_cus) {
				props.onAlert("danger","BL 확정된  SR문서는 삭제 하실수 없습니다.");
				return false;
	    	} else if ('S9' === srData.status_cus || 'RA' === srData.status_cus) {
				props.onAlert("danger","전송 또는 승인 된  SR문서는 삭제 하실수 없습니다.");
				return false;
	    	} else {
	    	    //문서 상태 확인
	    		axios.post("/shipper/getUserSrDataList",{user_no :param.user_no?param.user_no:userData?userData.user_no:'', lineCode:'WDFC'	,data:srData,link:'N',list:'N'}).then(res =>{
	    			var status = res.data.status_cus;
					var sendCount = res.data.sending_count;
    				if(srData.status_cus !== status) {
    					props.onAlert("danger","해당 SR문서는 삭제 하실수 없습니다. 다시 조회 후 상태를 확인해 주세요.");
						return false;
    				} else if (sendCount) {
						props.onAlert("danger","이미 전송 이력이 있는 SR문서이므로 삭제 하실 수 없습니다.");
						return false;
					}else {
						if(process.env.REACT_APP_DB=='ORACLE'){
							//오라클 변환
							axios.post("/shipper/deleteSrList",{user_no:userData?userData.user_no:'', data:srData,lineCode:'WDFC'}).then(res => {
								props.onAlert("success","작성한 SR ["+srData.sr_no+"] 가 삭제되었습니다. SR목록 화면으로 전환 됩니다."); 
								window.location.href="/svc/srlist";
							}).catch(err => {
								props.onAlert("danger","작성한 SR ["+srData.sr_no+"] 가 삭제되지 않았습니다. 다시 시도해주세요")
								return false;
							});
						}else{
							/**기존 pg */
							axios.post("/shipper/deleteSrList",{user_no:userData?userData.user_no:'', data:srData,lineCode:'WDFC'}
							).then(res => {
								if(res.data.rowCount>0){
								props.onAlert("success","작성한 SR ["+srData.sr_no+"] 가 삭제되었습니다. SR목록 화면으로 전환 됩니다."); 
								window.location.href="/svc/srlist";
								}else{
									props.onAlert("danger","작성한 SR ["+srData.sr_no+"] 가 삭제되지 않았습니다. 다시 시도해주세요")
								}
							}).catch(err => {
								props.onAlert("danger","작성한 SR ["+srData.sr_no+"] 가 삭제되지 않았습니다. 다시 시도해주세요")
								return false;
							});
							/******************* */
						}
    				}
				}).catch(err => {
				props.onAlert("error",validation.ERR_MSG);
				return false;
	    		});
	    	}
	    } else  {
			if(srData.sch_srd === null|| srData.sch_eta === null){//전송 시 srd, eta 체크
				setOpen(false);
				setSchOpen(true);
				props.onAlert("danger","POL/POD의 DATE가 설정되지 않았습니다. 캘린더에서 스케줄을 다시 선택하십시오.")
				return false;
			}
	    	//문서 전송 ( 저장 )
	    	setOpen(false);
			if( !validation.fncValidation() ) {
				fncOpenCardInvalid();
				props.onAlert("danger","필수값 또는 입력가능을(를) 확인 후 다시 전송 하세요.")
				return false;
			} 

			//cntr_code 체크
			if(srData.trans_service_code !=='89'){//Bulk는 예외
				let errorCntrCode='';
				await axios.post("/shipper/selectLineCodeCntrSztp",{params:{line_code:"WDFC"}}).then(res => {
					srData.cntrlist.map((data,key)=> {
						let cntrCodeExist=false;
						res.data.some(function(element){
							if( element.cntr_code === data.cntr_code ){
								cntrCodeExist= true;
							}
						} ) 
						data.cntr_seq = key+1;
						if(!cntrCodeExist)errorCntrCode+= " ["+data.cntr_code+"] ";
					});
				}).catch(err => {
					props.onAlert("error","전송 중 에러가 발생했습니다. 다시 시도해주세요");
					return false;
				});

				if(errorCntrCode){
					props.onAlert("error","컨테이너 사이즈타입"+errorCntrCode+"을 다시 선택해주세요.");
					return false;
				}
			}
			
			//전송전 정보업데이트
			await axios.post("/shipper/setUserSRDataList",{user_no:userData?userData.user_no:'',data:srData, lineCode:'WDFC', status : modalStats}).then(res => {
				if(res.data.code==="E") {
					props.onAlert("error",res.data.data);
					return false;
				}else {	
					axios.post("/shipper/setSendDocSr",{user_no:userData?userData.user_no:'',klnet_id:userData?userData.klnet_id:'',data:srData,status:modalStats,lineCode: 'WDFC',}).then(res => {
						if(res.data === 'success' ) {
							let msg = modalStats==="CANCEL"?"작성한 SR "+srData.sr_no+" 문서를 취소전송 하였습니다.":"작성한 SR "+srData.sr_no+" 문서를 전송 하였습니다."
							props.onAlert("success",msg);
							setSrData({...srData,status_cus:'S9'});
						} else {
							props.onAlert("error",res.data);
						}
					}).catch(err => {
					// if(err.response.status === 400) {
						const data = err.response.data||'';
						if(data) {
							let message = "";
							if( data.service_code ) {
								setOtherOpen(true);
								message += data.service_code;
							}
							if( data.vessel_name || data.route) {
								setSchOpen(true);
								if( data.vessel_name )
									message += "\n"+data.vessel_name;
								if( data.route )
									message += "\n"+data.route;
							}
							if( data.cargo_pack_type ) {
								setCargoOpen(true);
								message = "\n"+data.cargo_pack_type;
							}
							if( data.vgm ) {
								setDecOpen(true);
								message += "\n"+data.vgm;
							}
							if( data.declare ) {
								message += "\n"+data.declare;
							}
							if ( data.originator) {
								message += "\n"+data.originator;
							}
							if( data.container) {
								message += "\n"+data.container;
							}
							if( data.company ) {
								message = "\n"+data.company;
							}
							if( message ) {
								props.onAlert('error', message );
								return false;
							}else{
								props.onAlert('error', '[전송 실패] 관리자에게 문의바랍니다' );
								return false;
							}
						}else{
							props.onAlert("error",validation.ERR_MSG);
							return false;
						}
					});
	  	  		}
			}).catch(err => {
				props.onAlert("error",validation.ERR_MSG);
				return false;
			});
		}
	}
	//필수 체크
	const fncOpenCardInvalid =()=>{
        if( validation.fncFeedIdInvalid('booking') ) {
        	setOtherOpen(true);
        }
        if( validation.fncFeedIdInvalid('schedule') ) {
        	setSchOpen(true);
        }
        if( validation.fncFeedIdInvalid('shipper') ) {
        	setShpOpen(true);
        }
        if( validation.fncFeedIdInvalid('consignee') ) {
        	setConsOpen(true);
        }
        if( validation.fncFeedIdInvalid('notify') ) {
        	setNotiOpen(true);
        }
        if( validation.fncFeedIdInvalid('cargo') ) {
        	setCargoOpen(true);
        }
        if( validation.fncFeedIdInvalid('container') ) {
        	setCntrOpen(true);
        }
        if( srData.hbl_yn ==='N' && validation.fncFeedIdInvalid('declare') ) {
        	setDecOpen(true);
        }
    }
	// 자리수 체크 
    const fncOpenCardInvalidMaxLength =()=>{
       	if( validation.fncFeedIdInvalidMaxLength('booking') ) {
    	   setOtherOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('schedule') ) {
        	setSchOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('shipper') ) {
        	setShpOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('consignee') ) {
        	setConsOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('notify') ) {
        	setNotiOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('cargo') ) {
        	setCargoOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('container') ) {
        	setCntrOpen(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('declare') ) {
        	setDecOpen(true);
        }
    }
	const autoSrNumberCreate =(msgyn) =>{ 
		if(userData) {
			if( !userData.sr_recipient ) {
				props.onAlert("error", validation.NO_SR_RECIPIENT);
				return false;
			} else {
				axios.post("/shipper/getUserSrDocInit",{user_no : userData?userData.user_no:'',klnet_id:userData?userData.klnet_id:'', lineCode:'WDFC'}).then(res => {
					setSrData({...res.data,'bkglist':[]});
					if(msgyn) {
						props.onAlert("success","신규 SR번호를 생성하였습니다.");
					}
				}).catch(err => {
					props.onAlert("error",validation.ERR_MSG);
					return false;
				});
			}
		} else {
			return false;
		}
	}
	const resetShippingRequest=()=> {
		if(autoSelf) {
			//자동생성
			autoSrNumberCreate(true);
		} else { 
			//수동생성
			if(!newSrNo) {
				props.onAlert("error",validation.NO_NULL_MSG);
				return false;
			} else {
				if(srNoDupCheck) {
					fncDupCheckSrNo();
				} else {
					props.onAlert("error","SR 중복확인 버튼을 눌러주세요.");
				}
			}
		}
	}
	
	const fncDupCheckSrNo = () => {
	   	if( validation.fncFeedIdInvalid('sr_main') ) {
			props.onAlert("error","SR 번호를 확인해주세요.");  
	   	} else {
			axios.post("/shipper/getUserNewSrDupCheck",{sr_no:newSrNo}).then(res => {
				if(!res.data) {
			    	setSrNoDupCheck(true);
			 		setModalStats("NORMAL");
					setModalMsg("["+newSrNo+"] 신규번호로 작성하시겠습니까?");
					setOpen(true);	
				} else {
					props.onAlert("error","["+newSrNo+"] 번호는 이미 사용중입니다. 다른번호를 입력 해주세요.");
				}
			});
	   	}
	}
	
	const onDocSend = (name) => {
		let msg ="";
		
		if(!srData.sr_no) {
    		props.onAlert("error","sr_no 는 필수 입력항목 입니다. New 버튼을 통해 신규 번호를 입력 하세요.");
    	} else {
    		setAllOpen(false);
			setShpOpen(false);
			setConsOpen(false);
			setNotiOpen(false);
			setOtherOpen(false);
			setCargoOpen(false);
			setSchOpen(false);
			setCntrOpen(false);
			setDecOpen(false);
			setShp2Open(false);
			setCons2Open(false);
			setNoti2Open(false);
			
			if(name === "SEND") {
			    if(srData.part_bl && srData.part_bl ==='Y') {
			    	msg= "작성한 SR ["+srData.sr_no+"] 을 전송 하시겠습니까? (PART B/L)";
			    } else {
			    	msg= "작성한 SR ["+srData.sr_no+"] 을 전송 하시겠습니까?";
			    }
				setModalStats("SEND");
			} else if(name === "DELETE") {
				msg= "작성한 SR ["+srData.sr_no+"] 을  삭제 하시겠습니까? \n삭제한 경우 재조회가 불가합니다.";
				setModalStats("DELETE");
			} else {
				msg= "작성한 SR ["+srData.sr_no+"] 을 취소전송 하시겠습니까?";
				setModalStats("CANCEL");
			}
			setModalMsg(msg);
			setOpen(true);
    	}
		
	}
	
	const fncDupCheckBkgNo = (e) => {
		let selfSrNo = e.target.value.toUpperCase();
		setNewSrNo(selfSrNo.trim());
		if(selfSrNo.trim()){
			setSrNoDupCheck(false);
		}
	}
	
	const fncReportViewer = ()=>{
		if( !(srData.user_no && srData.sr_no && srData.sr_date ) ) { 
			props.onAlert("error","SR 먼저 조회 하세요.");
			return false;
		}

		var obj = new Object();
		obj.user_no = srData.user_no;
		obj.sr_no = srData.sr_no;
		obj.sr_date = srData.sr_date;
		var json = JSON.stringify(obj);

		let form = document.reportForm;
		form.action = '/shipper/reportViewer';
		form.target = 'popReport'; // window.open() 의 두번째 변수와 동일해야.
		form.file_id.value = 'weidong_sr';
		form.file_path.value = 'WEIDONG';
		form.name.value = 'FILENAME';
		form.connection.value = process.env.REACT_APP_DB=='ORACLE'?'logisvw' : 'pgsql';
		form.parameters.value = json;
		window.open('', 'popReport', 'width=1050px, height=850px');
		form.submit();
	}
	  
	const partBl = () => {
		if(!srData.sr_no) {
			props.onAlert("error","sr_no 는 필수 입력항목 입니다. New 버튼을 통해 신규 번호를 입력 하세요.");
			return false;
		}
		if(srData.part_bl === 'Y') {
			props.onAlert("error","해당 SR 건은 PART B/L 로 분할 할 수 없습니다.");
			return false;
		}
		if(!srData.res_bkg_no) {
			props.onAlert("error","Booking No 먼저 입력해주세요.");
			return false;
		}
		setModalMsg("작성한 SR ["+srData.sr_no+"] 을 PART B/L로 분할 하시겠습니까?");
		setModalStats("PART");
		setOpen(true);
	}
	// trans_goods_descriptin 함수와 일치하여 삭제
	// const fnc_transGoods = (trans,goods) => {
	// 	let goods_data = goods;
	// 	let returnValue;
	// 	if(("1" === trans || "11" === trans)) {
	// 		if(goods) {
	// 			var goodsSplit = goods_data.split('\n');
	// 			if("SHIPPER'S LOAD. COUNT & SEAL" === goodsSplit[0]) {
	// 				goodsSplit[0] = "SHIPPER'S LOAD. COUNT & SEAL";
	// 				goodsSplit[1] = "SAID TO CONTAIN :";
	// 				goods_data = goodsSplit.join('\n');
	// 			} else if ("SAID TO CONTAIN :" === goodsSplit[0]) {
	// 				goodsSplit[0] = "\n";
	// 				goods_data = goodsSplit.join('\n');
	// 			} else {
	// 				goods_data = "\n\n"+goodsSplit.join('\n');
	// 			}
	// 			var goodsDesc = goods_data.split('\n');
	// 			goodsDesc[0] = "SHIPPER'S LOAD. COUNT & SEAL";
	// 			goodsDesc[1] = "SAID TO CONTAIN :";
	// 			returnValue = goodsDesc.join('\n');					  
	// 		} else {
	// 			returnValue = "SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n";
	// 		}
	// 	} else if(("3" === trans|| "13" === trans)) {
	// 		if(goods) {
	// 			var goodsSplit = goods_data.split('\n');
	// 			if("SAID TO CONTAIN :" === goodsSplit[0]) {
	// 				goodsSplit[0] = "SAID TO CONTAIN :";
	// 			} else if ("SHIPPER'S LOAD. COUNT & SEAL" === goodsSplit[0]) {
	// 				goodsSplit[0] ="SAID TO CONTAIN :";
	// 				goodsSplit.splice(1,1);
	// 				goods_data = goodsSplit.join('\n');
	// 			} else {
	// 				goods_data = "\n"+goodsSplit.join('\n');
	// 			}
	// 			var goodsDesc = goods_data.split('\n');
	// 			goodsDesc[0] = "SAID TO CONTAIN :";
	// 			returnValue = goodsDesc.join('\n');					  
	// 		} else {
	// 			returnValue = "SAID TO CONTAIN :\n";
	// 		} 
	// 	}else {
	// 		returnValue = goods_data;
	// 	}
		
	// 	return returnValue;
	// }
    return (
        <>
        	<div className="section section-white">	
        		<Form>
        			<Container>
        				<CardBody className="pt-2 pb-2 bg-white">
		        			<Row>
					            <Col className="ml-auto mr-auto" xl="12" lg="12" md="12" sm="12" >
						            <h1 
										className="mt-1 text-start" 
										style={{
										background:
											"url(" + require("assets/img/bu_contitle_bar.gif") + ") no-repeat"
										}}>
										<small>Request (Shipping)</small>
									</h1>
								</Col>
							</Row>
							<Row className="mt-0" >
								<Col className="text-right">
									<label className="mt-2 mb-0 mr-2" >
										<Switch
											onColor="info" 
											offColor="success"
											onText="AUTO"
											offText="SELF" 
											// defaultValue={true} 
											value={autoSelf} 
											onChange={(e)=>setAutoSelf(!autoSelf)} 
										/>
									</label>
									{((!srData.part_bl || srData.part_bl ==='N')  && srData.sr_no && srData.res_bkg_no) &&
									<Button id="split" color="default" outline type="button" className="mr-1" onClick={partBl}>SPLIT
									<UncontrolledTooltip delay={0} target="split">B/L분할</UncontrolledTooltip></Button>}
									<Button id="srnew" color="default" outline type="button" className="mr-1" onClick={resetShippingRequest}>NEW</Button>
									<UncontrolledTooltip delay={0} target="srnew">새문서</UncontrolledTooltip>
									<Button id="report" color="default" outline type="button" className="mr-1" onClick={()=>fncReportViewer()}>PREVIEW</Button>
										<UncontrolledTooltip delay={0} target="report">PREVIEW</UncontrolledTooltip>
									<Button id="srview" color="default" outline type="button" className="mr-1" onClick={dataSave}>SAVE</Button>
									<UncontrolledTooltip delay={0} target="srview">임시저장</UncontrolledTooltip>
									<Button id="srsend" color="default" outline type="button" className="mr-1" onClick={()=>onDocSend('SEND')}>SEND</Button>
										<UncontrolledTooltip delay={0} target="srsend">SR문서 전송</UncontrolledTooltip>
									<Button id="srdel" color="default" outline type="button" className="mr-1" onClick={()=>onDocSend('DELETE')}>DELETE</Button>
									<UncontrolledTooltip delay={0} target="srdel">SR문서 삭제</UncontrolledTooltip>
										
								</Col>
								</Row>
								<Collapse isOpen={!autoSelf}>
								<Row className="mt-2">
									<Col xl="4" lg="4" md="4" className="col-12 ml-auto">
										<Row className="mt-2">
											<Col xl="8" lg="8" md="8" className="col-8 pr-0 ml-auto ">
												<InputValid 
													name="sr_c_no"
													id="sr_c_no"
													placeholder="생성할 SR번호 직접 입력"
													maxLength="15"
													value={newSrNo||''}
													onChange={(e)=>fncDupCheckBkgNo(e)}
													validtype="engNumber"
													required={!autoSelf?true:false} 
													feedid="sr_main"
													disabled = {srNoDupCheck?1:0}
												/>
											</Col>
											<Col xl="4" lg="4" md="4" className="col-4">
												{!srNoDupCheck
												?<Button
													color="danger" outline type="button" className="mr-1" 
													onClick={()=>fncDupCheckSrNo()}>중복 확인
												</Button>
												:<Button
													color="info" outline type="button" className="mr-1" 
													onClick={()=>{
														setSrNoDupCheck(false)
														setNewSrNo('')    
													}}>새로 입력
												</Button>}
											</Col>
										</Row>
									</Col>
								</Row>
							</Collapse>
							<hr className="mt-2"/>
							<Row>
								<Col xl="12" lg="12" className="pl-4 pr-4">
									<SrTitleCard 
										bookmark={userTitleBookmarkList} 
										booking={userOtherBookmarkList}
										schedule={userSchBookmarkList}
										carrier={userLineBookmarkList}
										shipper={userShpBookmarkList}
										consignee={userConsBookmarkList}
										notify={userNotiBookmarkList}
										cargo={userCargoBookmarkList}
										ccamShipper={userCCAMShpBookmarkList}
										ccamConsignee={userCCAMConsBookmarkList}
										ccamNotify={userCCAMNotiBookmarkList}
										transGoods={trans_goods_descriptin}
										loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} {...props}/>
								</Col>
							</Row>
							<Row>
								<Col xl="6" className="col-12">
									<OthersCard openWindow={otherOpen}  bookmark={userOtherBookmarkList}  loadData={srData} 
												mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} ref={bookingFocus} transGoods={trans_goods_descriptin} {...props}/>
								</Col>
								<Col xl="6" className="col-12">
									<ScheduleCard bookmark={userSchBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark}
									openWindow={schOpen}  ref={scheduleFocus} {...props}/>
								</Col>	                        	
							</Row>
							<Row>
								<Col xl="6" className="col-12">
								{/*<CarrierCard bookmark={userLineBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} openWindow={carrierOpen}  {...props}/>*/ }
									<ShipperCard bookmark={userShpBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} 
											openWindow={shpOpen} ref={shipperFocus}
											{...props}/>
								</Col>
								<Col xl="6" className="col-12">
								
									<ConsigneeCard bookmark={userConsBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} 
									openWindow={consOpen} ref={consigneeFocus} {...props}/>
								</Col>	                       	
							</Row>
							<Row>
								<Col xl="6" className="col-12">
									<NotifyCard bookmark={userNotiBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark}
												openWindow={notiOpen}  ref={notifyFocus} {...props}/>
								</Col>              	
							</Row>
							<Row>
								<Col>
									<CargoCard 
										bookmark={userCargoBookmarkList} 
										bookmark2={userMarkBookmarkList}
										bookmark3={userGoodsBookmarkList} 
										loadData={srData} 
										mergeData={(data)=>dataHandler(data)} 
										onLoadData={onLoadBookmark}
										openWindow={cargoOpen} 
										ref={cargoFocus}
										transGoods={trans_goods_descriptin}
										{...props}/>
								</Col>
							</Row>
							<Row>
								<Col>
									<ContainerCard  bookmark={userCntrBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} 
													openWindow={cntrOpen} ref={cntrFocus}  {...props} />
								</Col>
							</Row>
							<Row>
							{(srData && srData.hbl_yn ==='N') &&
							<Col>
								<DeclareCard  bookmark={userDeclareBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} 
								openWindow={decOpen}  ref={declareFocus} {...props} />
							</Col>}
						</Row>	
						<Row>
							<Col xl="12">
								<Badge className="ml-2" color="info" style={{fontSize:'17px'}} pill>CCAM</Badge>
								<hr className="mt-1" />
								<Row>
									<Col xl="6">
										<CCAM_ShipperCard bookmark={userCCAMShpBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} 
											openWindow={shp2Open} ref={cShipperFocus} {...props}/>
									</Col>
									<Col xl="6">
										<CCAM_ConsigneeCard bookmark={userCCAMConsBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark} 
											openWindow={cons2Open} ref={cConsFocus}  {...props}/>
									</Col>
									<Col xl="6">
										<CCAM_NotifyCard bookmark={userCCAMNotiBookmarkList} loadData={srData} mergeData={(data)=>dataHandler(data)} onLoadData={onLoadBookmark}
											openWindow={noti2Open} ref={cNotifyFocus} {...props}/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Card className="no-transition mb-0 rounded-0" style={{zIndex:'30',position:'fixed',right:'0.5%',top:'25%'}}>
								<CardBody className="pl-1 pr-1 pt-2 pb-0 text-center"> 
									<Button
										color="link"
										size="sm"
										type="button" 
										className="pl-1 pr-1"
										onClick={(e)=>{e.preventDefault();onDocSend('SEND');}}>SEND
									</Button>
									<hr className="m-0 " />
									<Button
										color="link"
										size="sm"
										type="button" 
										className="pl-1 pr-1"
										onClick={(e)=>{e.preventDefault();dataSave();}}>SAVE
									</Button>
									<hr className="m-0" />
									<Button
										color="link"
										size="sm"
										type="button" className="pl-1 pr-1"
										onClick={(e) => {
											e.preventDefault();
											setAllOpen(!allOpen);
											setShpOpen(!allOpen);
											setConsOpen(!allOpen);
											setNotiOpen(!allOpen);
											setOtherOpen(!allOpen);
											setCargoOpen(!allOpen);
											setSchOpen(!allOpen);
											setCntrOpen(!allOpen);
											setDecOpen(!allOpen);
											setShp2Open(!allOpen);
											setCons2Open(!allOpen);
											setNoti2Open(!allOpen);
										}}>
										{allOpen?'Close':'Open'}
									</Button>
								</CardBody>
							</Card>             
                            <nav id="cd-vertical-nav" style={{zIndex:'15'}}>
								<ul>
                            		<li>
										<a 
											data-number="1"
											//href="#projects"
											onClick={(e) => {
												e.preventDefault();
												setOtherOpen(!otherOpen);
												document.getElementById("Others").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",
												});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Booking</span>
	                            		</a>
	                        		</li>
                            		<li>
										<a 
											data-number="2"
											//href="#projects"
											onClick={(e) => {
												e.preventDefault();
												document.getElementById("Schedule").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",
												});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Schedule</span>
										</a>
									</li>
									{/* <li>
										<a 
											data-number="3"
											//href="#features"
											onClick={(e) => {
												e.preventDefault();
												setCarrierOpen(!carrierOpen);
												document.getElementById("Carrier").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",
												});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Carrier</span>
										</a>
									</li> */}
									<li>
										<a 
											data-number="4"
											//href="#features"
											onClick={(e) => {
												e.preventDefault();
												setShpOpen(!shpOpen);
												document.getElementById("Shipper").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",
												});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Shipper</span>
										</a>
                          		  	</li>
                            		<li>
										<a 
											data-number="5"
											//href="#features"
											onClick={(e) => {
												e.preventDefault();
												document.getElementById("Consignee").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",
												});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Consignee</span>
                                		</a>
									</li>
									<li>
										<a 
											data-number="6"
											//href="#teams"
											onClick={(e) => {
												e.preventDefault();
												document.getElementById("Notify").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Notify</span>
										</a>
                            		</li>
                            		<li>
										<a
											data-number="7"
											//href="#projects"
											onClick={(e) => {
												e.preventDefault();
												document.getElementById("Cargo").scrollIntoView({
												behavior: "smooth",
												block: "start",
												inline: "nearest",});
											}}>
											<span className="cd-dot bg-secondary" />
											<span className="cd-label">Cargo</span>
										</a>
                            </li>

                            <li>
                                <a 
                                data-number="8"
                                //href="#projects"
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById("Container").scrollIntoView({
                                    behavior: "smooth",
                                    block: "start",
                                    inline: "nearest",
                                    });
                                }}
                                >
                                <span className="cd-dot bg-secondary" />
                                <span className="cd-label">Container</span>
                                </a>
                            </li>
							{(srData.hbl_yn ==='N' && decOpen) &&
							<li>
								<a 
									data-number="9"
									//href="#projects"
									onClick={(e) => {
									e.preventDefault();
									document.getElementById("Declare").scrollIntoView({
									behavior: "smooth",
									block: "start",
									inline: "nearest",
									});
								}}
								>
									<span className="cd-dot bg-secondary" />
									<span className="cd-label">Declare</span>
								</a>
							</li>
							}
                            
                            
						</ul>
					</nav>
					{topView?
						<Button className="mb-1 pt-1 pb-1" style={{zIndex:'100',position:'fixed',right:'3%',top:'86%'}}
						color="neutral" size="sm"
						//outline
						type="button" 
						onClick={(e) => {
									e.preventDefault();
									document.getElementById("General").scrollIntoView({
									behavior: "smooth",
									block: "start",
									inline: "nearest",
									});
								}}
						>
						<i className="fa fa-angle-double-up fa-3x" /><br/><span style={{position:'absolute',top:'64%',right:'15%',fontSize:'1px'}}>Top</span>
						</Button> :<></>}
                    </Row>
				</CardBody>
			</Container>
		</Form>
	</div>

		{/* 모달 팝업 영역 
			xs : 한 줄
			sm : 576px 에서 다음 줄로 넘어감
			md : 768px
			lg : 992px
			xl : 1200px
			fluid : 뷰포트 전체의 너비
			*/}
		<Modal
			//size="sm"
			isOpen={open}
			//toggle={() => setOpen(false)}
			>
		<div className="modal-header no-border-header">
			<button
			className="close"
			type="button"
			onClick={() => setOpen(false)}
			>×</button>
		</div>
		<div className="modal-body text-center pl-0 pr-0">
			<h5>{modalMsg}</h5>
		</div>
		<div className="modal-footer">
			<div className="left-side">
				<Button className="btn-link" color="danger" type="button" onClick={fncOnDocSend}>Yes</Button>
			</div>
			<div className="divider" />
			<div className="right-side">
				<Button className="btn-link" color="default" type="button" onClick={() => setOpen(false)}>No</Button>
			</div>
		</div>
		</Modal>
		<form id="reportForm" name="reportForm" >
			<input type="hidden" name="system_id"   value="WEIDONG" />
			<input type="hidden" name="user_id"     value={`${(userData && userData.user_no)||'GUEST'}`} />
			<input type="hidden" name="file_type"   value="pdf" />
			<input type="hidden" name="file_id"     value="" />
			<input type="hidden" name="file_path"   value="" />
			<input type="hidden" name="name"        value="" />
			<input type="hidden" name="connection"  value={process.env.REACT_APP_DB=='ORACLE'?'logisvw' : 'pgsql'} />
			<input type="hidden" name="parameters" id="parameters"/>
		</form>
	</> 
        
    )
}
