import React, {useState, createRef, useEffect, useRef, useMemo} from "react";
import {Row, Col, Input, Button, Badge, FormGroup, Label, Tooltip} from "reactstrap";

import * as validation from 'components/common/validation.js';
import axios from "axios";
import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput.js';
import ReactQuill from 'react-quill';
// import "assets/css/quillEditer.css";
import Moment from 'moment';

export default function BoardWrite(props) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [boardId, setBoardId] = useState("");
    const [files, setFiles] = useState([]); 
    const [tooltipOpen,setTooltipOpen] = useState(false);
    const [endDate, setEndDate] = useState(Moment(new Date()).add(7,'days'));
    const [popYn, setPopyn] = useState(false);

    //TOP_FR_DATE, TOP_TO_DATE 
    const [topFlagYn, setTopFlagYn] = useState(false);
    const [topFrDate, setTopFrDate] = useState(Moment(new Date()));
    const [topToDate, setTopToDate] = useState(Moment(new Date()).add(7,'days'));
    const quillRef = useRef();
    const fileInput = createRef();

	const modules = useMemo(() => {
		return {
		toolbar: {
			container: [
			['image'],
			[{ header: [1, 2, 3, false] }],
			['bold','italic', 'underline', 'strike', 'blockquote'],
			[{color:[]},{background:[]}],
            [{'align':[]},],
			],
		},
		};
	}, []);

	const formats = [
        'bold',
		'image',
		'header',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'color',
		'background',
        'align'
        ];

    useEffect(()=> {
        if(props?.rowData&&props.rowData.board_id) {
            setContent(props.rowData.content);
            setTitle(props.rowData.title);
            setBoardId(props.rowData.board_id);
            setEndDate(props.rowData.board_to?props.rowData.board_to:Moment(new Date()).add(7,'days'));
            setPopyn(props.rowData.pop_yn==='Y'?true:false);
            setTopFlagYn(props.rowData.top_flag==='Y'?true:false)
            setTopFrDate(props.rowData.top_fr_date?Moment(props.rowData.top_fr_date):Moment(new Date()))
            setTopToDate(props.rowData.top_to_date?Moment(props.rowData.top_to_date):Moment(new Date()).add(7,'days'))
            // if(props.rowData.attach_files) {
            //     setFiles(props.rowData.attach_files.map((value,index)=> {return {name:value.real_file_name,boardId:value.board_id,seq:value.board_file_seq,rename:value.file_name,path:value.file_path}}))
            // }
            onSearchBoardAttachFile(props.rowData.board_id);
        }
        },[]);

    const onSearchBoardAttachFile=(board_id)=>{
    if( board_id) {
        axios.post("/api/getBoardAttach",{board_id:board_id}).then(
            res=>{
                const result = res.data;
                setFiles(result.map((value,index)=> {return {...value, name:value.real_file_name,boardId:value.board_id,seq:value.board_file_seq,rename:value.file_name,path:value.file_path}}))
                // setFiles(res.data);
            }
        );
    }
    }
    const handleClick = () => {
        if(files.length===2) {
            props.onAlert("error", "첨부파일은 최대 2개 까지 업로드 가능합니다.");
            return;
        }
        fileInput.current.click();
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        
        if(!e.target.files[0]) {
            return false;
        }

        if(!/\.(hwp|doc|docx|ppt|pptx|xls|xlsx|txt|csv|jpg|jpeg|gif|png|bmp|pdf)$/i.test(e.target.files[0].name)) {
            props.onAlert('error', '적절하지 않은 파일 형식입니다.' );
            return false;
        }
        
        if(e.target.files[0].size > 5000000) {
            props.onAlert('error', '파일의 크기가 너무 큽니다.' );
            return false;
        }
        props.onAlert('success', '업로드 완료' );
        setFiles([...files,e.target.files[0]]);
    };

    const saveNotice = () => {
        if(props.userData){
            if(title.length === 0) {
                props.onAlert('error', '제목을 입력해주세요.' );
                return;
            }
            if(content.length === 0) {
                props.onAlert('error', '내용을 입력해주세요.' );
                return
            }
            if((topFrDate > topToDate)
            // || Moment(new Date()) > endDate
            ){
                props.onAlert("error",validation.DATE_MSG);
                return false;
            }

            let params = {
                userno:props.userData.user_no
               ,username:props.userData.user_name
               ,title:title
               ,content:content
            //    ,content:content.toString('html')
               ,weidong:true
               ,boardId:boardId
               ,boardEnd : Moment(endDate).format('YYYYMMDD')
               ,popYn:popYn===true?'Y':'N'
            }
            if (topFlagYn){
                params = {...params
                    ,top_flag : 'Y'
                    ,top_fr_date : Moment(topFrDate).format('YYYYMMDD')
                    ,top_to_date : Moment(topToDate).format('YYYYMMDD')
                }
            }else{
                params = {...params
                    ,top_flag : 'N'
                    ,top_fr_date : null
                    ,top_to_date : null
                }
            }
            axios.post("/com/saveNotice", params).then(
                res=> {
                    if(res.statusText ==="OK") {
                        if(res.data) {
                            if(files) {
                                const formData = new FormData();
                                formData.append("boardId",res.data.board_id);
                                formData.append("userno", props.userData.user_no);
                                formData.append("file1",files[0]);
                                formData.append("file2",files[1]);
                                axios.post("/com/saveNoticeFiles",formData).then(
                                    res=>{
                                        if(res.statusText==="OK") {
                                            if(res.data && res.data.success === 1) {
                                                props.onAlert('success','저장이 완료 되었습니다.');
                                                props.goToList();
                                            }else {
                                                props.onAlert('error','파일첨부를 실패하였습니다. 다시 시도해주세요');
                                            }
                                        }
                                }).catch(error => {
                                    props.onAlert('error','파일첨부를 실패하였습니다. 다시 시도해주세요');
                                });
                            }else {
                                props.onAlert('success','저장이 완료 되었습니다.');
                                props.goToList();
                            }
                        }
                    }else {
                        props.onAlert('error','지금은 저장 할 수 없습니다.')
                    }
            }).catch(error => {
                props.onAlert("error",`저장하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); 
                return false;
            });
        }else{
            props.onAlert('error',validation.NO_USER_MSG)
        }
    }      
    const handleRemove = (num) => {
        if(files[num].rename) {
            axios.post('/com/boardFileDelete',{param:files[num]}).then(
                res => {
                    if(res.statusText==="OK") {
                        if(res.data==="success") {
                            props.onAlert('success','삭제가 완료되었습니다.');
                            // setFiles(files.filter((value,index)=> index === num))
                            setFiles(files.filter((value,index)=> index !== num))
                        }else {
                            props.onAlert('error',res.data);
                        }
                    }
                }).catch(error => {
                props.onAlert("error",`삭제하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); 
                return false;
            });
        }else {
            // setFiles(files.filter((value,index)=> index === num))
            setFiles(files.filter((value,index)=> index !== num))
        }
        fileInput.current.value = null;
    };

    return(<>
        <div style={{borderBottom:'solid 1px' ,width:'100%'}}> </div>
        <FormGroup className="mb-1 mt-4 pl-3" >
            <Row style={{height:'45px'}}>
                <Col className="  pr-0 col-2">
                    <Label style={{fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}>제목</Label>
                </Col>
                <Col>
                    <Input value={title} onChange={(e)=>setTitle(e.target.value)}></Input>
                </Col>
            </Row>
            <Row style={{height:'45px'}}>
                <Col className="pr-0 col-2 mb-0">
                    <Label style={{margin:'0' ,fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}>메인(팝업) 공지 기간</Label>
                </Col>
                <Col className="col-4" style={{display:'flex', alignItems:'center'}}>
                    <CustomDatePickerInput
                        id="endDate"
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={endDate}
                        onChange={(date)=>setEndDate(date)}
                        {...props}/>
                </Col>
                <Col className="pr-0 col-4 mb-0 ml-1">
                    <FormGroup style={{margin:'0' ,fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}>
                        <Label style={{margin:'0' ,fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}>메인 팝업 유무</Label>
                        <Input type="checkbox" checked={popYn} onChange = {()=>setPopyn(!popYn)} 
                        style={{position:'inherit', margin:'auto' }}/>
                    </FormGroup>
                </Col>
            </Row>
            <Row style={{height:'45px'}}>
                <Col className="pr-0 col-2 mb-0">
                    <FormGroup style={{display:'flex', alignItems:'center',height:'100%', justifyContent:'space-between'}}>
                        <Label style={{margin:'0' ,fontWeight:'bold' , display:'flex', alignItems:'center'}}>상단 게시</Label>
                        <Input type="checkbox" checked={topFlagYn} onChange = {()=>setTopFlagYn(!topFlagYn)} 
                            style={{position:'inherit',  }}/>
                    </FormGroup>
                </Col>
                <Col className="col-8" style={{display:'flex', alignItems:'center'}}>
                    <CustomDatePickerInput
                        id="topFrDate"
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={topFrDate}
                        onChange={(date)=>setTopFrDate(date)}
                        inputProps={{
                            disabled: !topFlagYn
                        }}
                        {...props}
                        />
                    <div style={{width:'10%', textAlign:'center'}}  >~</div>
                    <CustomDatePickerInput
                        id="topToDate"
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        value={topToDate}
                        onChange={(date)=>setTopToDate(date)}
                        inputProps={{
                            disabled: !topFlagYn
                        }}
                        {...props}
                        />
                </Col>
            </Row>
            <Row style={{height:'45px'}}>
                <Col className="  pr-0 col-2">
                    <Label style={{fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}> 첨부파일
                        <Button  id="btn1" className="btn-link btn-icon" color='primary' outline
                        style={{margin:'0 5px'}}   onClick={handleClick}> 
                            <i className='fa fa-flip-vertical fa-paperclip fa-lg' aria-hidden="true" >
                                <input style={{display:'none'}} type="file" onChange={handleImageChange} ref={fileInput} />
                            </i>
                            <Tooltip placement="top" isOpen={tooltipOpen} target="btn1" toggle={() => setTooltipOpen(!tooltipOpen)}>
                                <Col>
                                    <Row>
                                        <Col>
                                            <span style={{fontWeight:'bold', fontSize:'1rem'}}>허용 파일</span><span> : hwp, doc, docx, ppt, pptx, xls, xlsx, txt, csv, jpg, jpeg, gif, png, bmp, pdf </span>
                                            <br></br>
                                            <span style={{fontWeight:'bold', fontSize:'1rem'}}>파일 크기 제한</span><span> : 5MB</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Tooltip>
                        </Button>
                    </Label>
                </Col>
                <Col>
                {files.map((value,index) => {
                    return(<>
                        <Badge>{validation.textLengthOverCut(value.name,15,'....')}</Badge>
                        <Button
                            color="danger"
                            className="btn-round btn-link"
                            onClick={() => handleRemove(index)}>
                            <i className="fa fa-times" />
                        </Button>
                    </>)})}
                </Col>
            </Row>
        </FormGroup>
        <Row className="mt-4">
                <Col style={{height:"400px"}}>
                        <ReactQuill
                                    style={{width: "100%",height:"90%",borderBottom:"50px"}}
                                    ref={quillRef}
                                    theme='snow'
                                    placeholder='내용'
                                    value={content&&String(content)}
                                    onChange={(value)=>setContent(value)}
                                    modules={modules}
                                    formats={formats}
                                    className="bold"
                                    preserveWhitespace
                                    />
                </Col>
            </Row>
            <Row className="mt-4 mb-5 pr-0 pl-0 mr-0"  style={{justifyContent:'end'}}>
                <Button color="primary" style={{marginRight:'5px'}} onClick = {()=>  props.goToList()}>목록으로</Button>
                <Button color="info" onClick = {()=> saveNotice()}>저장</Button>
            </Row>
        </>
    )
}




