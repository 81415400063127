import React,{useEffect} from "react";

import WeidongNavbar from "components/Navbars/WeidongNavbar.js";
import WeidongHeader from "components/Headers/WeidongHeader.js";
import FooterWeidong from "components/Footers/FooterWeidong.js";
// import ConfirmList from "./list/ConfirmList.js";
import Request from "./InvoiceList";


export default function InvoiceIndex(props) { 
//   useEffect(() => {
// 	  props.fncClickMenu('NAV_SR')
  
//   },[]);
    return (
        <div id="GeneralInv">
            {/* <WeidongNavbar {...props} /> */}
            <WeidongHeader />
            <Request {...props}/>
            <FooterWeidong {...props}/>
        </div>
    );
}

