import React, { useState, useEffect, useCallback }  from "react";
// import { Link } from "react-router-dom";
// nodejs library that concatenates strings
// import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
// import Select from "react-select";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,NavItem,Badge,Card,CardBody,Tooltip,Spinner,Modal
} from "reactstrap";
import axios from "axios";

import { Link } from "react-router-dom";
import UncontrolledPopover from "reactstrap/lib/UncontrolledPopover";
import MyProfile from "components/Modals/MyProfile";
import MyMsg from "components/Modals/MyMessage";
import CompanyModal from 'components/Modals/Company.js';
import ChangeSessionModal from 'components/Modals/ChangeSessionModal.js';
import CompanyMappingAlert from 'components/Modals/MappingAlert.js'
import Confirm from 'components/Confirm/Confirm.js';
import useIsMount from 'components/common/useIsMount.js';
import {observer,useObserver,} from 'mobx-react-lite';
import { userStore, timerStore } from 'store/UserStore.js';

// function WeidongAuthNavbar(props) {
const WeidongAuthNavbar = (props) => {
  const isMount = useIsMount();
  const [company, setCompany] = useState(false);
  const [sessionModal, setSessionModal] = useState(false);
	const [msg,setMsg] = useState(0);
	const [msgData,setMsgData] = useState([]);
	const [section,setSection] = useState(false);
  const [klnetId, setKlnetId] = useState("");
  const [companyState, setCompanyState] = useState(false);// 위동 거래처 등록 알림
  const [loading, setLoading] = useState(false);
  const [onAlarm,setOnAlarm] = useState(false);
  const [viewAlert, setViewAlert] = useState(null);

  const {userData, setUserData,onAlert, isLogOut} = props;
  
	React.useEffect(() => {
    if(userData) {
      if(isMount.current&&userData.klnet_id) {
        setSection(false);
        setKlnetId(userData.klnet_id);
      }else {
        isMount.current&&setSection(true);
      }
      onHandleUserMessageCheck();
    }else {
      isMount.current&&setMsg(0);
    }

    //unmoutned
    return ()=>{
      isMount.current&&setSection(false);
    }
	},[isMount]);
  

  React.useEffect(() => {
    if(klnetId) {
      axios.post("/com/checkWDFCLineCompany",{param:klnetId, lineCode:'WDFC'}).then(
        res=> {
          if(res.statusText==="OK"){
            if(res.data.length !== 0) {
              setCompanyState(false)
            }else {
              setCompanyState(true)
            }
          }
      });
    }
  },[klnetId])

  React.useEffect(() => {
    if(section) {
      const timeout = setInterval(()=>{
        setSection(false)
      },3000);
      return () => clearInterval(timeout)
    }
  },[section]);

  useEffect(() => {
      if(onAlarm){
        // (async()  =>{
          
        //   let result = setViewAlert(await Confirm({
        //     title: '로그인 상태를 유지하시겠습니까?',
        //     message:  <span style={{fontWeight:'bold'}}><TimerKor/> 후 로그인 세션이 종료되어 로그아웃됩니다. </span>,
        //     confirmText: "세션 연장",
        //     cancelText: '취소',
        //   })
        //   )
        //   if(result ){
        //     setOnAlarm(false);  onHandlerSession();
        //   }else{
        //     setOnAlarm(false)
        //   }
        // })()
      
        setViewAlert(
            <Modal isOpen toggle={()=>setViewAlert(null)}>
            <div className="modal-header">
                <button
                    className="close"
                    type="button"
                    onClick={()=> setViewAlert(null)}
                    >
                <span>×</span>
            </button>
                <h5
                    className="modal-title text-center"
                    id="exampleModalLabel"
                    >
               로그인 상태를 유지하시겠습니까?
                </h5>
            </div>
            <div className="modal-body text-center">
              <span style={{fontWeight:'bold'}}><TimerKor/> 후 로그인 세션이 종료되어 로그아웃됩니다. </span>
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={()=> {setOnAlarm(false);  onHandlerSession();}}
                        >
                    세션 연장
                    </Button>
                </div>
            <div className="divider" />
                <div className="right-side">
                    <Button className="btn-link"
                        color="danger"
                        type="button" 
                        onClick={()=>setOnAlarm(false)}>
                     취소
                    </Button>
                </div>
            </div>
        </Modal>
        )
      }else{
        setViewAlert(null);
      }
  },[onAlarm]);

  const onHandleUserMessageCheck = ()=>{
	  axios.post("/shipper/getUserMessageCheck",{user_no:props.userData?props.userData.user_no:''})
      .then(res => {
               var data = res.data;
               if(data) {setMsg(parseInt(data[0].count));}else{setMsg(0);}
    	  });
  }

  const onReadUserMessage = () => {
	  axios.post("/shipper/getUserMessage",{user_no:props.userData?props.userData.user_no:'',mini:'Y'})
      .then(res => {
               var data = res.data;
               if(data) {setMsgData(data);}else{setMsgData([]);}
      });
  }
  const onAllReadMessage = (data) => { 
	  axios.post("/shipper/setUserReadMeassage",{user_no:props.userData?props.userData.user_no:'',data:data})
	  .then(res =>{onHandleUserMessageCheck();onReadUserMessage();});
  }

  const onLogintoggle = (e)=>{
    timerStore.setAutoRenew=!e;
  }

  // const onLogout = useCallback(() => {
  //   userStore.logout()
  //   setUserData(userStore.user)
  // }, []);
  const leftTime =useObserver( ()=>{
    return  timerStore.leftTime
  })
  
  React.useEffect(() => {
    if(userData&&(!leftTime||leftTime<1||leftTime== ' -- : -- ')){
      setViewAlert(null);
      isLogOut();
    }   
    if(userData&&timerStore.leftTime==600){///////////////////운영
    // if(leftTime==60){
        setOnAlarm(true)
    }     
  },[leftTime]);
 
  const Timer =observer(()=>{
    return <>
            {/* ////////////////////운영 */}
            {/* // {true */}
            {userData&&(timerStore.leftTime<600)
            ?<div style={{display : 'flex'}}>
                <div>[{timerStore.timer}]</div>
                <Button
                    className="pt-0 pb-0 mt-0 mb-0 text-dark"
                    size="sm"
                    onClick={onHandlerSession}
                    style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666', padding:'0'}}
                >세션 연장
                </Button>
            </div> 
            : <div style={{width:'0px'}}> </div>} 
          </>;
    // return (
    //  <> [{timerStore.timer}]</>
    // )
  });

  const TimerKor = observer(() => {
    return <>{timerStore.timerKo}</>
  });

  const onHandlerSession = async()=>{
    const result =  await userStore.sessionExtension();
    if(!result){
        onAlert('error','세션 연장이 불가한 세션입니다. 다시 로그인 해주세요.')
    }
  }

  return (
    <div id="test" className="float-right"  style={{display:'flex', alignItems:'center'}}>
    {userStore.isAuth || !userStore.user ? 
      <>
        <Button
            className="pt-0 pb-0 mt-0 mb-0 text-dark"
            size="sm"
            onClick={()=>props.openLogin(true)}
            style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666'}}
        >로그인
        </Button>
        <Button
            className="pt-0 pb-0 mt-0 mb-0 text-dark"
            size="sm"
            onClick={()=>props.openRegister(!props.openRegister)}
            style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666'}}
        >회원가입
        </Button>
      </>
      :<>
        {userStore.loading?
          <div style={{marginRight:"20px"}}><Spinner animation='border' variant='dark' size='sm'/></div>
          :
          <Button
          className="text-dark padding_custom"
          size="sm"
          onClick={isLogOut}
          style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666' ,margin:'0 5px 0 0', padding:'0px'}}
          >
            {userStore.admin 
              ?'세션아웃'
            : '로그아웃'} </Button>}
          <font style={{fontFamliy:'굴림',fontSize:'11px',color:'#666',paddingRight:'5px'}} >
            {userStore.user?userStore.user.user_name+'님':''} 
            {userStore.admin 
              ?(
                ` (관리자 :${userStore.admin.user_name})`
              ):''
            }
          </font>
          <span style={{backgroundColor:'tansparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666'}}> <Timer/> </span>
          
          {/* <div className="custom-control custom-switch" id="customSwitch22" style={{display:'inline-block'}}>
            <input type='checkbox' className="custom-control-input" id="customSwitch" checked={timerStore.getAutoRenew}  onChange={()=> onLogintoggle(timerStore.getAutoRenew)}></input>
            <label className= "custom-control-label" htmlFor="customSwitch"></label>     
          </div>
          
          <UncontrolledTooltip placement="bottom" className="customToggle" target="customSwitch22" trigger="hover" >
            {timerStore.autoRenew? '자동 로그인 갱신' : '자동 로그아웃'}
          </UncontrolledTooltip> */}
                  
          <Button id="profile" style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666'}}
          className="p-0 mt-0 mb-1">
              <i className="fa fa-user-o fa-2X" />
          </Button>
          <UncontrolledPopover className="popover" trigger="legacy" placement="bottom" target="profile">
            <MyProfile style={{zIndex:'9999'}} userData={userData} openCompany ={() => setCompany(!company)} openSessionModal ={() => setSessionModal(!sessionModal)} {...props}/>
          </UncontrolledPopover>
          {section===true &&
            <Tooltip placement="bottom" target="test" isOpen={section} toggle={() => setSection(false)}>
              <CompanyMappingAlert msg="업체등록이 필요합니다."/>
            </Tooltip>}
          {companyState=== true &&
            <Tooltip placement="bottom" target="profile" isOpen={companyState} toggle={() => setCompanyState(false)}>
              <CompanyMappingAlert msg="등록된 업체가 없습니다. 관리자에게 문의해 주세요."/>
            </Tooltip>}
          <Button id="alram" style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666'}}
            className="p-0 mt-0 mb-1" onClick={(e) => {e.preventDefault();onReadUserMessage();}}>
            <i className="fa fa-bell-o fa-2X" />{msg === 0?<Badge color="danger" pill style={{display:'unset',paddingTop:'2px',top:'-3px'}}>{msg}</Badge>:<span >{' '}</span>} 
          </Button>
          <UncontrolledPopover className="popover" trigger="legacy" placement="bottom" target="alram">
            <MyMsg msgdata={msgData} readmsg={(data)=>onAllReadMessage(data)} {...props}/>
          </UncontrolledPopover>
        </>}

        {viewAlert}
        <Link to="/svc/sitemap">
          <Button
              className="p-0 mt-0 mb-0 text-dark"
              size="sm"
              //onClick={()=>setRegister(!register)}
              style={{backgroundColor:'transparent',border:'0',fontFamliy:'굴림',fontSize:'11px',color:'#666'}}
          >사이트맵
          </Button>
        </Link>
        <ButtonDropdown>
            <DropdownToggle size="sm" className="mt-0 mb-0 mr-0 border-0 rounded-0" style={{backgroundColor:'#0098e1',paddingTop:'5px'}}
            	href="https://www.weidong.com/index.do?lang=ko_KR"
            	target="_blank">여객사이트</DropdownToggle>
        </ButtonDropdown>
        <ButtonDropdown>
        <DropdownToggle size="sm" className="mt-0 mb-0 mr-0 ml-0 border-0 rounded-0" style={{backgroundColor:'#0098e1',paddingTop:'5px'}}
        	href="https://cargo.weidong.com/index.do?lang=ko_KR"
        	target="_blank">화물사이트</DropdownToggle>
         </ButtonDropdown>
        {/* <ButtonDropdown isOpen={dropdownOpen} toggle={()=>setDropdownOpen(!dropdownOpen)} >
            <DropdownToggle caret size="sm" className="mt-0 mb-0 ml-0 border-0 rounded-0" style={{paddingTop:'5px',backgroundColor:'#333'}}>{langTitle}</DropdownToggle>
            <DropdownMenu className="mt-0" style={{minWidth:'6rem'}}>
                <DropdownItem className="p-0 pl-2 rounded-0" style={{backgroundColor:'#333',color:'#ffffff'}} onClick={()=>setLangTitle("KOREA")}>KOREA</DropdownItem>
                <DropdownItem className="p-0 pl-2 rounded-0" style={{backgroundColor:'#333',color:'#ffffff'}} onClick={()=>setLangTitle("CHINESE")}>CHINESE</DropdownItem>
                <DropdownItem className="p-0 pl-2 rounded-0" style={{backgroundColor:'#333',color:'#ffffff'}} onClick={()=>setLangTitle("ENGLISH")}>ENGLISH</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown> */}
        <CompanyModal 
          isUser={props.userData}
          openCompany={company}
          setOpenCompany={(e)=>setCompany(e)}
          {...props}
        />
        <ChangeSessionModal 
          isUser={props.userData}
          openSessionModal={sessionModal}
          setOpenSessionModal={(e)=>setSessionModal(e)}
          adminRole = {userStore.user?userStore.user.role:null}
          {...props}
        />
    </div>
  );
}
;

export default observer(WeidongAuthNavbar);
