/*eslint-disable*/
import React,{useState,useEffect, useRef, createRef} from "react";
// reactstrap components
import {
  Button,
  Modal,
  Input,Row,Col,
  Card,CardTitle,InputGroup,InputGroupText, InputGroupAddon,Form, FormGroup, Label, Badge, Tooltip
} from "reactstrap";
// import CardSubtitle from "reactstrap/lib/CardSubtitle";
import CardBody from "reactstrap/lib/CardBody";
import axios from 'axios'
// import Collapse from "reactstrap/lib/Collapse";
import * as validation from 'components/common/validation.js';
// import { userStore,timerStore } from "store/UserStore";
import {CustomDateTimePicker} from 'components/CustomInput/reactstrap/CustomInput'
// import { isMoment } from 'moment';
import Moment from 'moment';
import InputValid from "components/CustomInput/InputValid.js";

const styles = {
};



export default function TranstkReg(props) {
    const [tooltipOpen,setTooltipOpen] = useState(false);
    const [transtkDate, setTranstkDate] = useState(Moment());
    const [transtkName, setTranstkName] = useState('');
    const [transtkAmount, setTranstkAmount] = useState('0');
    const [transtkMemo, setTranstkMemo] = useState('');
    const [taxSend, setTaxSend] = useState('');
    //업체 정보
    const [invComNo, setInvComNo] = useState('');
    const [invComCEO, setInvComCEO] = useState('');
    const [invComName, setInvComName] = useState('');
    const [invComTel, setInvComTel] = useState('');
    const [invComFax, setInvComFax] = useState('');
    const [invPersonEmail, setInvPersonEmail] = useState('');
    const [invRemark, setInvRemark] = useState('');
    const [invPersonName, setInvPersonName] = useState('');
    const [invComAddr, setInvComAddr] = useState('');
    //세금계산서 업체
    const [taxComName, setTaxComName] = useState('');
    const [taxComNo, setTaxComNo] = useState('');
    const [taxComCEO, setTaxComCEO] = useState('');
    const [taxPersonName, setTaxPersonName] = useState('');
    const [taxComTel, setTaxComTel] = useState('');
    const [taxComFax, setTaxComFax] = useState('');
    const [taxPersonEmail, setTaxPersonEmail] = useState('');
    const [taxComAddr, setTaxComAddr] = useState('');
    const [taxBusiness, setTaxBusiness] = useState('');
    const [taxService, setTaxService] = useState('');
    const [taxSubComNo, setTaxSubComNo] = useState('');
    const [taxSubComName, setTaxSubComName] = useState('');
    const [taxRemark, setTaxRemark] = useState('');

    const [files, setFiles] = useState([]);
    const fileInput = createRef();

    const [popYn, setPopyn] = useState(false);
    const [popYn2, setPopyn2] = useState(false);
    const [popYn3, setPopyn3] = useState(false);

    const [comp, setComp] = useState([]);

    const {modalInvoiceSid,lineCode}= props

    // useEffect(()=>{
    //     if(modalInvoiceSid){
    //         console.log(props.userData);
    //         console.log(modalInvoiceSid);
    //         axios.post("/shipper/getInvoiceTranstkInfo", {
    //             invoice_sid:modalInvoiceSid
    //         }).then(
    //             res=> {console.log(res.data[0]);});
    //     }
    // },[modalInvoiceSid]);

    // useEffect(() => {
    //     if(!open) {
    //         document.getElementById("bn").focus();
    //     }

    //     return function cleanup() {
    //         setData([]);
    //         setBusinessNumber('');
    //     };

    // }, [open]);

    useEffect(() => {//세금계산서 발급 여부
        // console.log(lineCode);
        if(lineCode&&props.userData) {
            taxSendCheck(lineCode);
        }
    }, [lineCode]);

    useEffect(()=>{
        if(taxSend==='Y'){
            onCompSearch();
        }
    },[taxSend]);
    
    const  taxSendCheck = async(linecode)=>{
        await axios.post("/shipper/taxSendCheck",{lineCode:lineCode}).then(
            res=>{
                if(res){
                    setTaxSend(res.data[0].TAX_SEND_YN);
                    // console.log(res.data[0].TAX_SEND_YN);
                }
        }).catch(err => {
            props.onAlert("error","오류가 발생했습니다. 잠시후 다시 시도해주세요.");
            // return false;
        })
    }
    // const onKeyPress = (e) => {
    //     if(e.key==='Enter') {
    //         if(businessNumber.length===0){
    //             setOpen(true);
    //         }else {
    //             onSubmit();
    //         }
    //     }
    // }
    // const onFocusOut =(e) => {
    //     if(businessNumber.length ===0) {
    //         setOpen(true);
    //     }
    // }
    const onModalClose = () => {
        setTranstkDate(Moment());
        setTranstkName('');
        setTranstkAmount('');
        setTranstkMemo('');
        setFiles([]);
        setInvComNo('');
        setInvComCEO('');
        setInvComName('');
        setInvComTel('');
        setInvComFax('');
        setInvPersonEmail('');
        setInvRemark('');
        setInvPersonName('');
        setInvComAddr('');
        setTaxComName('');
        setTaxComNo('');
        setTaxComCEO('');
        setTaxPersonName('');
        setTaxComTel('');
        setTaxComFax('');
        setTaxPersonEmail('');
        setTaxComAddr('');
        setTaxBusiness('');
        setTaxService('');
        setTaxSubComNo('');
        setTaxSubComName('');
        setTaxRemark('');
        props.setOpenTranstk(false)
    }

    // const fncOnChangeDateInput =(date)=> {
    //    if(isMoment(date)){
    //        let value = date.format('YYYYMMDD');
    //        if( value.length === 8 ) {
    //            let dateTime = Moment(value,'YYYYMMDD');
    //        setDate(dateTime.format('YYYYMMDD'));
    //        }
    //    }
    // }

    const handleImageChange = (e) => {
        
        e.preventDefault();
        
        if(!e.target.files[0]) {
            return false;
        }

        // console.log(e.target.files[0].size);
        if(!/\.(hwp|doc|docx|ppt|pptx|xls|xlsx|txt|csv|jpg|jpeg|gif|png|bmp|pdf)$/i.test(e.target.files[0].name)) {
            props.onAlert('error', '적절하지 않은 파일 형식입니다.' );
            return false;
        }
        if(e.target.files[0].size > 20000000) {
            props.onAlert('error', '파일의 크기가 너무 큽니다.' );
            return false;
        }
        props.onAlert('success', '업로드 완료' );
        setFiles([...files,e.target.files[0]]);
    };

    const handleClick = () => {
        if(files&&files.length===3) {
            props.onAlert("error", "첨부파일은 최대 3개 까지 업로드 가능합니다.");
            return;
        }
        fileInput.current.click();
    };

    const handleRemove = (num) => {
        setFiles(files.filter((value,index)=> index !== num))
        // if(files[num-1].rename) {
        //     // axios.post('/com/boardFileDelete',{param:files[num-1]}).then(
        //     //     res => {
        //     //         if(res.statusText==="OK") {
        //     //             if(res.data==="success") {
        //     //                 props.onAlert('success','삭제가 완료되었습니다.');
        //     //                 setFiles(files.filter((value,index)=> index === num))
        //     //             }else {
        //     //                 props.onAlert('error',res.data);
        //     //             }
        //     //         }
        //     //     }
        //     // )
        // }else {
        //     setFiles(files.filter((value,index)=> index !== num))
        // }
        


        fileInput.current.value = null;
    };

    
    const saveTranstk = async() => {
        if(props.userData){
            if(!transtkName) {
                props.onAlert('error', '이체자를 입력해주세요.' );
                return;
            }
            if(!transtkMemo) {
                props.onAlert('error', '받는통장 표시 내용을 입력해주세요.' );
                return
            }
            if(taxSend==='Y'&&!invComNo) {
                props.onAlert('error', '사업자번호를 입력해주세요.' );
                return
            }

            //이체증 저장
            if(files&&files.length>0) {
                const formData = new FormData();
                formData.append("invoiceSid", modalInvoiceSid);
                formData.append("userno", props.userData.user_no);
                formData.append("file1",files[0]);
                formData.append("file2",files.length>1?files[1]:'');
                formData.append("file3",files.length>2?files[2]:'');
                await axios.post("/shipper/saveTranstkFile",formData).then(
                    async(res)=>{
                        // if(res.statusText==="OK") {
                            // console.log(res.data);
                            if(res.data && res.data.success === 1) {
                                // console.log(res.data);
                                await saveTranstkOracle(files);

                                setTranstkDate(Moment());
                                setTranstkName('');
                                setTranstkAmount('');
                                setTranstkMemo('');
                                setFiles([]);
                                setInvComNo('');
                                setInvComCEO('');
                                setInvComName('');
                                setInvComTel('');
                                setInvComFax('');
                                setInvPersonEmail('');
                                setInvRemark('');
                                setInvPersonName('');
                                setInvComAddr('');
                                setTaxComName('');
                                setTaxComNo('');
                                setTaxComCEO('');
                                setTaxPersonName('');
                                setTaxComTel('');
                                setTaxComFax('');
                                setTaxPersonEmail('');
                                setTaxComAddr('');
                                setTaxBusiness('');
                                setTaxService('');
                                setTaxSubComNo('');
                                setTaxSubComName('');
                                setTaxRemark('');
                                props.setOpenTranstk(false);
                            }else {
                                props.onAlert('error',"오류가 발생했습니다. 잠시후 다시 시도해주세요.");
                            }
                        // }
                    }).catch(err => {
                        props.onAlert("error","오류가 발생했습니다. 잠시후 다시 시도해주세요.");
                        // return false;
                    })
            }else {
                await saveTranstkOracle(files);

                setTranstkDate(Moment());
                setTranstkName('');
                setTranstkAmount('');
                setTranstkMemo('');
                setInvComNo('');
                setInvComCEO('');
                setInvComName('');
                setInvComTel('');
                setInvComFax('');
                setInvPersonEmail('');
                setInvRemark('');
                setInvPersonName('');
                setInvComAddr('');
                setTaxComName('');
                setTaxComNo('');
                setTaxComCEO('');
                setTaxPersonName('');
                setTaxComTel('');
                setTaxComFax('');
                setTaxPersonEmail('');
                setTaxComAddr('');
                setTaxBusiness('');
                setTaxService('');
                setTaxSubComNo('');
                setTaxSubComName('');
                setTaxRemark('');
                props.setOpenTranstk(false);
            }
        }
    }      

    const saveTranstkOracle = async(files) => {
        let file1 = files[0]?.name||'';
        let file2 = files.length>1?files[1]?.name:'';
        let file3 = files.length>2?files[2]?.name:'';
        var now = Moment(new Date()).format('YYYYMMDD');
        const transtk_path='/KLNET/klnet.nplism/invoice/transtk/';//실제로 저장되는 위치 

        await axios.post("/shipper/updateInvoiceTranstk",{pay_date:transtkDate.format("YYYYMMDDHH"),pay_person:transtkName,pay_charge:parseInt(transtkAmount),pay_show_bankbook:transtkMemo,
        transtk_path: files&&files.length>0?transtk_path+now:'',transtk_file:file1?file1:'',
        transtk_path2: files&&files.length>1?transtk_path+now:'',transtk_file2:file2?file2:'',
        transtk_path3: files&&files.length>2?transtk_path+now:'',transtk_file3:file3?file3:'',
        transtk_add_yn:'Y',sKlnetId:props.userData.klnet_id,sUserId:props.userData.user_no,invoice_sid:modalInvoiceSid ,
        inv_com_no:invComNo, inv_com_ceo:invComCEO, inv_com_name:invComName, inv_com_tel:invComTel, inv_com_fax:invComFax, 
        inv_person_email:invPersonEmail , inv_remark:invRemark, inv_person_name:invPersonName, inv_com_addr:invComAddr,
        tax_com_no:taxComNo, tax_com_ceo:taxComCEO,tax_person_name:taxPersonName, tax_com_name:taxComName, tax_com_tel:taxComTel, tax_com_fax:taxComFax, 
        tax_person_email:taxPersonEmail, tax_com_addr:taxComAddr, tax_business:taxBusiness, tax_service:taxService, tax_sub_com_no:taxSubComNo, 
        tax_sub_com_name:taxSubComName, tax_remark:taxRemark, 
    }).then( async(res)=> {
            if(res.data.rowsAffected) {
                //이체증 히스토리 저장
                await axios.post("/shipper/addTranstkInvoiceHistory",{sKlnetId:props.userData.klnet_id,sUserId:props.userData.user_no,invoice_sid:modalInvoiceSid })
                .then(res=>{
                        if(!res.data.rowsAffected){
                            props.onAlert('error','이체증 히스토리 저장 실패.')
                        }
                }).catch(err => {
                    props.onAlert("error","이체증 저장 실패. 관리자에 문의바랍니다.");
                })

                await axios.post("/shipper/sendTranstk",{invoice_sid:modalInvoiceSid}).
                then(res=>{
                        if(res.data==='Success'){
                            props.onAlert('success','저장이 완료 되었습니다.');
                        }
                        props.successSummit();//검색
                    }).catch(err => {
                        props.onAlert("error","이체증 저장 실패. 관리자에 문의바랍니다.");
                    })   
            }else {
                props.onAlert('error','이체증 저장 실패. 관리자에 문의바랍니다.')
            }
        }).catch(err => {
            props.onAlert("error","이체증 저장 실패. 관리자에 문의바랍니다.");
        })
}

    const onCompSearch = async() => {
    if(props.userData&&props.userData.klnet_id){
        await axios.post("/com/getCompanyInfo",{klnetId:props.userData.klnet_id}).then(
                res=> {
                // if(res.statusText==="OK") {
                    if(res.data&&res.data.length>0){
                        setComp(res.data[0]);
                        // console.log(res.data[0]);
                    }else {
                        setComp([]);
                    }
                    
                // }
            }).catch(err => {
                props.onAlert("error","오류가 발생했습니다. 잠시후 다시 시도해주세요.");
                // return false;
            })
        }   
    }
    
    
    const sameMyInfoCheck = (flag) => {
        if(flag){
            setInvComName(comp.CNAME_KR?comp.CNAME_KR:'');
            setInvComNo(comp.REG_NO?comp.REG_NO:'');
            setInvComCEO(comp.COMP_CEO?comp.COMP_CEO:'');
            setInvPersonName(props.userData.user_name?props.userData.user_name:'');
            setInvPersonEmail(props.userData.user_email?props.userData.user_email:'');
            setInvComTel(props.userData.user_tel?props.userData.user_tel:'');
            setInvComAddr(comp.COMP_ADDR?comp.COMP_ADDR:'');
        }else{
            setInvComName('');
            setInvComNo('');
            setInvComCEO('');
            setInvPersonName('');
            setInvPersonEmail('');
            setInvComTel('');
            setInvComAddr('');
        }
        setPopyn(flag);
    };

    const sameMyInfoCheck2 = (flag) => {
        if(flag){
            setTaxComName(comp.CNAME_KR?comp.CNAME_KR:'');
            setTaxComNo(comp.REG_NO?comp.REG_NO:'');
            setTaxComCEO(comp.COMP_CEO?comp.COMP_CEO:'');
            setTaxPersonName(props.userData.user_name?props.userData.user_name:'');
            setTaxPersonEmail(props.userData.user_email?props.userData.user_email:'');
            setTaxComTel(props.userData.user_tel?props.userData.user_tel:'');
            setTaxComAddr(comp.COMP_ADDR?comp.COMP_ADDR:'');
            setTaxBusiness(comp.COMP_TYPE?comp.COMP_TYPE:'');
            setTaxService(comp.COMP_CLASS?comp.COMP_CLASS:'');
            
        }else{
            setTaxComName('');
            setTaxComNo('');
            setTaxComCEO('');
            setTaxPersonName('');
            setTaxPersonEmail('');
            setTaxComTel('');
            setTaxComAddr('');
            setTaxBusiness('');
            setTaxService('');
        }
        setPopyn2(flag);
        setPopyn3(false);
    };

    const sameInvoiceInfoCheck = (flag) => {
        if(flag){
            setTaxComName(invComName);
            setTaxComNo(invComNo);
            setTaxComCEO(invComCEO);
            setTaxPersonName(invPersonName);
            setTaxPersonEmail(invPersonEmail);
            setTaxComTel(invComTel);
            setTaxComAddr(invComAddr);
            setTaxBusiness('');
            setTaxService('');
        }else{
            setTaxComName('');
            setTaxComNo('');
            setTaxComCEO('');
            setTaxPersonName('');
            setTaxPersonEmail('');
            setTaxComTel('');
            setTaxComAddr('');
            setTaxBusiness('');
            setTaxService('');
        }
        setPopyn3(flag);
        setPopyn2(false);
    };

    return (
        <>
            <Modal
                isOpen={props.openTranstk}
                toggle={() => onModalClose()}
                className="modal-lg">
                <>
                    <Card className="card-raised card-form-horizontal no-transition mb-4">
                        <CardTitle >
                            <Col>
                                <span style={{fontSize:'15px',fontWeight:'bold',marginLeft:'30px'}}>이체증 첨부</span>
                            </Col>
                        </CardTitle>
                        <CardBody>
                            <Col xl="12" lg="12" sm="12" md="12">
                                <>
                                    <Row className="bg-light pb-2 pt-2">
                                        <Col xl="6" lg="6" md="6">
                                            <FormGroup>
                                                <Label className="mb-0">이체일자</Label>
                                                <CustomDateTimePicker
                                                id="transtkDate"
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                value={transtkDate}
                                                onChange={(date)=>setTranstkDate(date)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col xl="6" lg="6" md="6">
                                            <FormGroup>
                                                <Label className="mb-0">이체자</Label>
                                                <InputValid
                                                    type="text"
                                                    id="transtk_name"
                                                    value={transtkName}
                                                    placeholder=""
                                                    maxLength="20"
                                                    onChange={(e)=>setTranstkName(e.target.value)}
                                                    required={true} 
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="bg-light pb-2 pt-2">
                                        <Col xl="6" lg="6" md="6">
                                            <FormGroup>
                                                <Label className="mb-0">이체금액</Label>
                                                <InputValid
                                                    type="text"
                                                    id="transtk_name"
                                                    value={transtkAmount}
                                                    placeholder=""
                                                    maxLength="12"
                                                    validtype="number"
                                                    onChange={(e)=>setTranstkAmount(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl="6" lg="6" md="6">
                                            <FormGroup>
                                                <Label className="mb-0">받는통장 표시 내용</Label>
                                                <InputValid
                                                    type="text"
                                                    id="transtk_name"
                                                    value={transtkMemo}
                                                    placeholder=""
                                                    maxLength="6"
                                                    onChange={(e)=>setTranstkMemo(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="bg-light pb-2 pt-2">
                                    <Col xl="4" lg="4" md="4" >
                                        <Label className="mb-0">첨부파일 (최대 3개)</Label>
                                        <input style={{display:'none'}} type="file" onChange={handleImageChange} ref={fileInput} />
                                        <Button
                                            id="btn1"
                                            className="btn-round"
                                            color="default"
                                            outline
                                            style={{margin:'10px'}}
                                            onClick={handleClick}>
                                            첨부하기
                                        </Button>
                                        <Tooltip placement="top" isOpen={tooltipOpen} target="btn1" toggle={() => setTooltipOpen(!tooltipOpen)}>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <span style={{fontWeight:'bold', fontSize:'1rem'}}>허용 파일</span><span> : hwp, doc, docx, ppt, pptx, xls, xlsx, txt, csv, jpg, jpeg, gif, png, bmp, pdf </span>
                                                        <br></br>
                                                        <span style={{fontWeight:'bold', fontSize:'1rem'}}>파일 크기 제한</span><span> : 20MB</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Tooltip>
                                    </Col>
                                    <Col xl="8" lg="8" md="8" >
                                        <Row>
                                        {
                                            files.map((value,index) => {
                                                return(
                                                        <Col xl="4" lg="4" md="12"style={{marginTop:'30px'}}>
                                                            <Badge>{validation.textLengthOverCut(value.name,15,'....')}</Badge>
                                                            <Button
                                                                color="danger"
                                                                className="btn-round btn-link"
                                                                style={{padding:'0px'}}
                                                                onClick={() => handleRemove(index)}>
                                                                <i className="fa fa-times" />
                                                            </Button>
                                                        </Col>
                                                )
                                            })
                                        }
                                        </Row>
                                    </Col>
                                    </Row>
                                    {taxSend==='Y'?
                                    <>
                                        <Row className="bg-light pb-2 pt-5">
                                            <Col>
                                                <span style={{fontSize:'15px',fontWeight:'bold'}}>인보이스 신청업체</span>
                                            </Col>
                                            <Col className="text-right">
                                                <Form>
                                                <FormGroup check inline>
                                                    <Label check>
                                                        <Input type="checkbox" checked={popYn} onChange={()=>sameMyInfoCheck(!popYn)} style={{align:'right',opacity:'100',position:'static',visibility:'visible'}} />{' '}
                                                        내정보와 동일
                                                    </Label>
                                                </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2 ">
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">상호</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invComName"
                                                        value={invComName}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setInvComName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">사업자번호</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invComNo"
                                                        value={invComNo}
                                                        placeholder=""
                                                        maxLength="12"
                                                        onChange={(e)=>setInvComNo(e.target.value)}
                                                        required={true} 
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">대표자</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invComCEO"
                                                        value={invComCEO}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setInvComCEO(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2 ">
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">담당자</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invPersonName"
                                                        value={invPersonName}
                                                        placeholder=""
                                                        maxLength="35"
                                                        onChange={(e)=>setInvPersonName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">TEL</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invComTel"
                                                        value={invComTel}
                                                        placeholder=""
                                                        maxLength="15"
                                                        onChange={(e)=>setInvComTel(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">FAX</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invComFax"
                                                        value={invComFax}
                                                        placeholder=""
                                                        maxLength="15"
                                                        onChange={(e)=>setInvComFax(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2 ">
                                            <Col xl="6" lg="6" md="6">
                                                <FormGroup>
                                                    <Label className="mb-0">E-mail</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invPersonEmail"
                                                        value={invPersonEmail}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setInvPersonEmail(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="6" lg="6" md="6">
                                                <FormGroup>
                                                    <Label className="mb-0">주소</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invComAddr"
                                                        value={invComAddr}
                                                        placeholder=""
                                                        maxLength="150"
                                                        onChange={(e)=>setInvComAddr(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2">
                                            <Col xl="12" lg="12" md="12">
                                                <FormGroup>
                                                    <Label className="mb-0">비고</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="invRemark"
                                                        value={invRemark}
                                                        placeholder=""
                                                        maxLength="200"
                                                        onChange={(e)=>setInvRemark(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2 pt-5">
                                            <Col>
                                                <span style={{fontSize:'15px',fontWeight:'bold'}}>세금계산서 업체</span>
                                            </Col>
                                            <Col className="text-right">
                                                <Form>
                                                <FormGroup check inline>
                                                    <Label check>
                                                        <Input type="checkbox" checked={popYn2} onChange={()=>sameMyInfoCheck2(!popYn2)}  style={ {align:'right',opacity:'100',position:'static',visibility:'visible'}} />{' '}
                                                        내정보와 동일
                                                    </Label>
                                                </FormGroup>
                                                </Form>
                                            </Col>
                                            <Col className="text-right">
                                                <Form>
                                                <FormGroup check inline style={{paddingLeft:'0px'}}>
                                                    <Label check>
                                                        <Input type="checkbox" checked={popYn3} onChange={()=>sameInvoiceInfoCheck(!popYn3)} style={{align:'right',opacity:'100',position:'static',visibility:'visible'}} />{' '}
                                                        인보이스 신청업체와 동일
                                                    </Label>
                                                </FormGroup>
                                                </Form>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2">
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">상호</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxComName"
                                                        value={taxComName}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setTaxComName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">사업자번호</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxComNo"
                                                        value={taxComNo}
                                                        placeholder=""
                                                        maxLength="12"
                                                        onChange={(e)=>setTaxComNo(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">대표자</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxComCEO"
                                                        value={taxComCEO}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setTaxComCEO(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2">
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">담당자</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxPersonName"
                                                        value={taxPersonName}
                                                        placeholder=""
                                                        maxLength="35"
                                                        onChange={(e)=>setTaxPersonName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">TEL</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxComTel"
                                                        value={taxComTel}
                                                        placeholder=""
                                                        maxLength="15"
                                                        onChange={(e)=>setTaxComTel(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="4" lg="4" md="4">
                                                <FormGroup>
                                                    <Label className="mb-0">FAX</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxComFax"
                                                        value={taxComFax}
                                                        placeholder=""
                                                        maxLength="15"
                                                        onChange={(e)=>setTaxComFax(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2">
                                            <Col xl="6" lg="6" md="6">
                                                <FormGroup>
                                                    <Label className="mb-0">E-mail</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxPersonEmail"
                                                        value={taxPersonEmail}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setTaxPersonEmail(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="6" lg="6" md="6">
                                                <FormGroup>
                                                    <Label className="mb-0">주소</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxComAddr"
                                                        value={taxComAddr}
                                                        placeholder=""
                                                        maxLength="150"
                                                        onChange={(e)=>setTaxComAddr(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2">
                                            <Col xl="3" lg="3" md="3">
                                                <FormGroup>
                                                    <Label className="mb-0">업태</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxBusiness"
                                                        value={taxBusiness}
                                                        placeholder=""
                                                        maxLength="35"
                                                        onChange={(e)=>setTaxBusiness(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="3" lg="3" md="3">
                                                <FormGroup>
                                                    <Label className="mb-0">업종</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxService"
                                                        value={taxService}
                                                        placeholder=""
                                                        maxLength="35"
                                                        onChange={(e)=>setTaxService(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="3" lg="3" md="3">
                                                <FormGroup>
                                                    <Label className="mb-0">종사업자번호</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxSubComNo"
                                                        value={taxSubComNo}
                                                        placeholder=""
                                                        maxLength="12"
                                                        onChange={(e)=>setTaxSubComNo(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xl="3" lg="3" md="3">
                                                <FormGroup>
                                                    <Label className="mb-0">사업자명</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxSubComName"
                                                        value={taxSubComName}
                                                        placeholder=""
                                                        maxLength="50"
                                                        onChange={(e)=>setTaxSubComName(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-light pb-2">
                                            <Col xl="12" lg="12" md="12">
                                                <FormGroup>
                                                    <Label className="mb-0">비고</Label>
                                                    <InputValid
                                                        type="text"
                                                        id="taxRemark"
                                                        value={taxRemark}
                                                        placeholder=""
                                                        maxLength="200"
                                                        onChange={(e)=>setTaxRemark(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        
                                    </>
                                    :<></>}
                                    <Row className="mt-4">
                                            <Col style={{textAlignLast:'center'}}>
                                                <Button style={{ margin:'10px'}} onClick = {()=> saveTranstk()}>저장</Button>
                                                <Button style={{ margin:'10px'}} onClick = {()=> onModalClose()}>취소</Button>
                                            </Col>
                                    </Row>
                                </>
                            </Col>
                        </CardBody>
                    </Card>
                </>
            </Modal>
        </>
    );
}
