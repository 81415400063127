/*eslint-disable*/
import React,{useState,useEffect, useRef, createRef} from "react";
// reactstrap components
import {
  Button,
  Modal,
  Input,Row,Col,Collapse,
  Card,CardTitle,InputGroup,InputGroupText, InputGroupAddon,Form, FormGroup, Label, Badge, Tooltip
} from "reactstrap";
import CardBody from "reactstrap/lib/CardBody";
import axios from 'axios';
import Filesaver from 'file-saver';


export default function TranstkDownload(props) {

    const {modalInvoiceSid,lineCode}= props
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [file3, setFile3] = useState('');

    useEffect(()=>{
        if(modalInvoiceSid){
            console.log(props.userData);
            console.log(modalInvoiceSid);
            getInvoiceTranstkInfo(modalInvoiceSid);
        }
    },[props.openTranstkDownload]);
    
    const getInvoiceTranstkInfo = async (modalInvoiceSid)=>{
        await axios.post("/shipper/getInvoiceTranstkInfo", {
            invoice_sid:modalInvoiceSid
        }).then(
            res=> {
                if(res.data&&res.data.length>0){
                    console.log(res.data[0]);
                    setFile1(res.data[0].TRANSTK_FILE);
                    setFile2(res.data[0].TRANSTK_FILE2);
                    setFile3(res.data[0].TRANSTK_FILE3);
                }
        }).catch(err => {
            props.onAlert("error","오류가 발생했습니다. 잠시후 다시 시도해주세요.");
        })
    }
    const onModalClose = () => {
        props.setTranstkDownload(false)
    }

    const handleClick = async(seq) => {
        if(!seq)return;
        await axios.post("/shipper/downloadbyURL",{type : 'transtkDownload' , file : modalInvoiceSid, seq:seq},{responseType:'blob',})
        .then(res => {
            Filesaver.saveAs(new Blob([res.data]),seq===1?file1:seq===2?file2:seq===3?file3:'');
        }).catch(err => {
            console.log(err)
            props.onAlert("danger",'다운로드에 실패하였습니다. 관리자 문의바랍니다.');
        })
    };
    return (
        <>
            <Modal
                style={{maxWidth:'400px'}}
                isOpen={props.openTranstkDownload}
                toggle={() => onModalClose()}
                className="modal-lg">
                <>
                    <Card className="card-raised card-form-horizontal no-transition mb-4">
                        <CardTitle>
                            <Col>
                                <span style={{fontSize:'15px',fontWeight:'bold',marginLeft:'30px'}}>이체증 첨부파일 다운로드</span>
                            </Col>
                        </CardTitle>
                        <CardBody>
                            <Col xl="12" lg="12" sm="12" md="12">
                                <>
                                <Collapse isOpen={file1?true:false}>
                                    <Row className="bg-light pb-2 pt-2">
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup>
                                                <Label className="mb-0">파일 (1) : </Label>
                                                <Button
                                                    id="btn1"
                                                    className="btn-round"
                                                    color="default"
                                                    outline
                                                    style={{margin:'10px'}}
                                                    onClick={() => handleClick(1)}>
                                                    {file1}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Collapse isOpen={file2?true:false}>
                                    <Row className="bg-light pb-2 pt-2">
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup>
                                                <Label className="mb-0">파일 (2) : </Label>
                                                <Button
                                                    id="btn1"
                                                    className="btn-round"
                                                    color="default"
                                                    outline
                                                    style={{margin:'10px'}}
                                                    onClick={() => handleClick(2)}>
                                                    {file2}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Collapse isOpen={file3?true:false}>
                                    <Row className="bg-light pb-2 pt-2">
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup>
                                                <Label className="mb-0">파일 (3) : </Label>
                                                <Button
                                                    id="btn1"
                                                    className="btn-round"
                                                    color="default"
                                                    outline
                                                    style={{margin:'10px'}}
                                                    onClick={() => handleClick(3)}>
                                                {file3}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Collapse>
                                </>
                            </Col>
                        </CardBody>
                    </Card>
                </>
            </Modal>
        </>
    );
}
