import React, { useState } from 'react';
import {Col, FormGroup,Card, CardTitle, CardBody} from "reactstrap";
import axios from 'axios';


function SeavantageMap(props) {

    const [linkUrl,setLinkUrl] = useState("");
    const {userData} = props;

    React.useEffect(() => {
        if( userData ) {
            getTrackingShipLine("WDFC");
        } else {
            onInit();
        }
    },[]);

    const onInit = ()=>{
        axios.post("/api/getTrackingShipNoLogin",{
            params: []
        }).then(res => {
            if(res.data.length > 0) {
                // console.log(res.data)
                setTimeout(function() {
                    setLinkUrl(res.data);
                },1000);
            }
        });
    }

    const getTrackingShipLine = (lineCode)=>{
        axios.post("/com/getTrackingShipLine",{
            lineCode: lineCode 
        }).then(res => {
            if(res.data.length > 0) {
                // console.log(res.data)
                setTimeout(function() {
                    setLinkUrl(res.data);
                },1000);
            }
        });
    }
    return (
        <Col className="ml-auto mr-auto mt-4" sm="11">               
                
        <Card className="card-raised card-form-horizontal no-transition mb-4" id="card">
            <CardTitle>
                <Col xl='3' className='search_option--number'>
                    <FormGroup row className='search_option--number--formGroup' >
                    <Col className='mt-4 search_option--number--text col-12'> 
                        <div>VESSEL LOCATION</div>
                    </Col>
                    
                    </FormGroup> 
                </Col>
            </CardTitle>
            <CardBody className="result_table" >
                <Col className='search_option--number--input col-12'>
                    <form target="maplink"> 
                        <iframe name="maplink" src={linkUrl} title={"tackingFrame"} width="100%" height="600" display='block' border='none' position="absolute" frameBorder="0" scrolling="auto" allowFullScreen></iframe>
                    </form>
                </Col>
            </CardBody>
        </Card>
        </Col>
    );
}

  
  
export default SeavantageMap;
